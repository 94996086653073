<template>
  <div>
    <!-- icon -->
    <span class="cursor-pointer"><featherIcon class="w-4 h-4" icon="InfoIcon" @click="toggle()" /></span>

    <!-- modal -->
    <base-modal :title="title" :size="size" class="text-sm" v-if="show" @close="toggle()">
      <slot></slot>
    </base-modal>
  </div>
</template>

<script>
import featherIcon from '@/components/extended/feather/featherIcon.vue'
import { ref } from 'vue'

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'm',
    },
  },
  setup() {
    const show = ref(false)

    function toggle() {
      show.value = !show.value
    }

    return {
      show,
      toggle,
    }
  },
  components: {
    featherIcon,
  },
}
</script>
