import { ref, computed, watch } from 'vue'
import timeHandler from '@/use/timeHandler'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import chartOptionshandler from '@/use/chartOptionsHandler'
import { useAuth0 } from '@auth0/auth0-vue'

let amountsData = ref({})
// let storedWeatherData = ref({})

export default () => {
  const useTime = timeHandler()
  const range = useTime.range
  const interval = useTime.interval
  const useApi = apiHandler()
  const settings = ref({})
  const summaries = ref({})
  const loadHandler = loadingHandler()
  const useChartOptions = chartOptionshandler()
  let organisationName = ref('')
  const auth = useAuth0()

  // const mainTypeSelector = {
  //   electricity: 'Electricity',
  //   gas: 'Gas',
  //   water: 'Water',
  //   heat: 'Heat',
  // }

  let categorizedNodes = ref({
    Electricity: { Usage: [], Return: [], Generation: [] },
    Gas: { Usage: [], Return: [], Generation: [] },
    Water: { Usage: [], Return: [], Generation: [] },
    Heat: { Usage: [], Return: [], Generation: [] },
  })

  async function fetchNodes(params = {}) {
    const testpayload = {
      ...params,
      fields: 'type,medium,object_id',
    }

    const queryString = useApi.toQueryString(testpayload)

    const response = await useApi.request_api('get', 'v1', 'nodes?' + queryString)
    return response.data
  }

  function fetchNodesForHomePage() {
    fetchNodes().then((nodes) => {
      categorizeNodesByMedium(nodes)
    })
  }

  function categorizeNodesByMedium(nodes) {
    categorizedNodes.value = {
      Electricity: { Usage: [], Return: [], Generation: [] },
      Gas: { Usage: [], Return: [], Generation: [] },
      Water: { Usage: [], Return: [], Generation: [] },
      Heat: { Usage: [], Return: [], Generation: [] },
    }

    nodes.forEach((node) => {
      if (node.type) {
        const count_usage = node.type.count_usage
        const count_return = node.type.count_return
        const count_generation = node.type.count_generation

        if (node.medium in categorizedNodes.value) {
          if (count_usage === true) {
            categorizedNodes.value[node.medium].Usage.push(node.object_id)
          }
          if (count_return === true) {
            categorizedNodes.value[node.medium].Return.push(node.object_id)
          }
          if (count_generation === true) {
            categorizedNodes.value[node.medium].Generation.push(node.object_id)
          }
        }
      }
    })

    Object.keys(categorizedNodes.value).forEach((medium) => {
      Object.keys(categorizedNodes.value[medium]).forEach((type) => {
        if (categorizedNodes.value[medium][type].length > 0) {
          getSummaryNew(
            {
              start_date: useTime.startDate.value.getTime(),
              end_date: useTime.endDate.value.getTime(),
              interval: interval.value,
              ids: categorizedNodes.value[medium][type],
            },
            medium,
            type,
          )
        }
      })
    })
  }

  function getSummaryNew(payload, medium, type) {
    loadHandler.setLoadingState(`get_summary_${medium.toLowerCase()}_${type.toLowerCase()}`, true)

    const queryString = useApi.toQueryString(payload)

    useApi
      .request_api('get', 'v1/nodes', 'data/summary?' + queryString)
      .then((response) => {
        const data = response.data
        if (!amountsData.value[medium]) {
          amountsData.value[medium] = {}
        }

        if (type === 'Usage') {
          amountsData.value[medium][type] = data.usage
        } else {
          amountsData.value[medium][type] = data.production
        }

        loadHandler.setLoadingState(`get_summary_${medium.toLowerCase()}_${type.toLowerCase()}`, false)
      })
      .catch(() => {
        loadHandler.setLoadingState(`get_summary_${medium.toLowerCase()}_${type.toLowerCase()}`, false)
      })
  }

  watch([() => range.value, () => useTime.startDate.value, () => useTime.endDate.value], async () => {
    reload()
  })

  function getSum(array) {
    let sum = 0
    array.forEach((innerArray) => {
      sum += innerArray[1]
    })
    return sum
  }

  const amountElectricityUsage = computed(() => {
    if (amountsData.value.Electricity && amountsData.value.Electricity.Usage) {
      return getSum(amountsData.value.Electricity.Usage)
    }
    return 0
  })

  const seriesElectricityUsage = computed(() => {
    if (amountsData.value.Electricity && amountsData.value.Electricity.Usage) {
      return parseTimestamps(amountsData.value.Electricity.Usage, interval.value)
    }
    return []
  })

  const amountElectricityGeneration = computed(() => {
    if (amountsData.value.Electricity && amountsData.value.Electricity.Generation) {
      return getSum(amountsData.value.Electricity.Generation)
    }
    return 0
  })

  const seriesElectricityGeneration = computed(() => {
    if (amountsData.value.Electricity && amountsData.value.Electricity.Generation) {
      return parseTimestamps(amountsData.value.Electricity.Generation, interval.value)
    }
    return []
  })

  const amountElectricityReturn = computed(() => {
    if (amountsData.value.Electricity && amountsData.value.Electricity.Return) {
      return getSum(amountsData.value.Electricity.Return)
    }
    return 0
  })

  const seriesElectricityReturn = computed(() => {
    if (amountsData.value.Electricity && amountsData.value.Electricity.Return) {
      return parseTimestamps(amountsData.value.Electricity.Return, interval.value)
    }
    return []
  })

  const amountGasUsage = computed(() => {
    if (amountsData.value.Gas && amountsData.value.Gas.Usage) {
      return getSum(amountsData.value.Gas.Usage)
    }
    return 0
  })

  const seriesGasUsage = computed(() => {
    if (amountsData.value.Gas && amountsData.value.Gas.Usage) {
      return parseTimestamps(amountsData.value.Gas.Usage, interval.value)
    }
    return []
  })

  const seriesHeatUsage = computed(() => {
    if (amountsData.value.Heat && amountsData.value.Heat.Usage) {
      return parseTimestamps(amountsData.value.Heat.Usage, interval.value)
    }
    return []
  })

  const amountWaterUsage = computed(() => {
    if (amountsData.value.Water && amountsData.value.Water.Usage) {
      return getSum(amountsData.value.Water.Usage)
    }
    return 0
  })

  const amountHeatUsage = computed(() => {
    if (amountsData.value.Heat && amountsData.value.Heat.Usage) {
      return getSum(amountsData.value.Heat.Usage)
    }
    return 0
  })

  const seriesWaterUsage = computed(() => {
    if (amountsData.value.Water && amountsData.value.Water.Usage) {
      return parseTimestamps(amountsData.value.Water.Usage, interval.value)
    }
    return []
  })

  function load() {
    // getting settings first
    loadHandler.setLoadingState('get_settings', true)
    useApi
      .request_api('get', 'v1/organisation', 'settings')
      .then((response) => {
        const responseData = response.data
        settings.value = responseData.settings
        organisationName.value = responseData.name
        loadHandler.setLoadingState('get_settings', false)

        // then loading summaries
        fetchNodesForHomePage()
      })
      .catch(() => {
        auth.logout({ returnTo: window.location.origin })
        loadHandler.setLoadingState('get_settings', false)
      })
  }

  function reload() {
    fetchNodesForHomePage()
  }

  const chartOptionsElectricityUsage = computed(() => {
    return useChartOptions.getOptions('Electricity', seriesElectricityUsage.value, 'Usage')
  })

  const chartOptionsElectricityGeneration = computed(() => {
    return useChartOptions.getOptions('Electricity', seriesElectricityGeneration.value, 'Generation')
  })

  const chartOptionsElectricityReturn = computed(() => {
    return useChartOptions.getOptions('Electricity', seriesElectricityReturn.value, 'Return')
  })

  const chartOptionsGasUsage = computed(() => {
    return useChartOptions.getOptions('Gas', seriesGasUsage.value, 'Usage')
  })

  const chartOptionsWaterUsage = computed(() => {
    return useChartOptions.getOptions('Water', seriesWaterUsage.value, 'Usage')
  })

  const chartOptionsHeatUsage = computed(() => {
    return useChartOptions.getOptions('Heat', seriesHeatUsage.value, 'Usage')
  })

  const showInfo = computed(() => {
    return Object.keys(settings.value).length > 0 ? true : false
  })

  const showElectricity = computed(() => {
    return settings.value.utilities.includes('Electricity') ? true : false
  })

  const showGas = computed(() => {
    return settings.value.utilities.includes('Gas') ? true : false
  })

  const showWater = computed(() => {
    return settings.value.utilities.includes('Water') ? true : false
  })

  const showHeat = computed(() => {
    return settings.value.utilities.includes('Heat') ? true : false
  })

  function parseTimestamps(data, interval) {
    const formattedUsage = data.map(([datetime, value]) => {
      const formattedDate = useTime.labelSelector(interval, new Date(datetime))
      return [formattedDate, value]
    })

    return formattedUsage
  }

  return {
    open,
    confirm,
    close,
    load,
    settings,
    summaries,
    getSum,
    amountElectricityUsage,
    amountElectricityGeneration,
    amountElectricityReturn,
    amountGasUsage,
    amountWaterUsage,
    amountHeatUsage,
    interval,
    loadHandler,
    chartOptionsElectricityUsage,
    chartOptionsElectricityGeneration,
    chartOptionsElectricityReturn,
    chartOptionsGasUsage,
    chartOptionsWaterUsage,
    chartOptionsHeatUsage,
    organisationName,
    showInfo,
    showElectricity,
    showGas,
    showWater,
    showHeat,
    amountsData,
  }
}
