<template>
  <base-modal v-if="show" @close="cancel()" :title="$translate('areYouSure')" size="xs">
    <span class="text-gray-400">
      {{ $translate(message) }}
    </span>
    <template v-slot:buttons>
      <base-button @click="cancel()">{{ $translate('No') }}</base-button>
      <base-button @click="confirm()" class="mr-2">{{ $translate('Yes') }}</base-button>
    </template>
  </base-modal>
</template>

<script>
import confirmationHandler from '@/use/confirmationHandler'

export default {
  emits: ['close'],
  setup() {
    const { show, message, confirm, cancel } = confirmationHandler()

    return {
      show,
      message,
      confirm,
      cancel,
    }
  },
}
</script>
