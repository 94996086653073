import { ref } from 'vue'
import useValidate from '@/use/validationHandler'
import formHandler from '@/use/formHandler'

export default () => {
  let data = ref({})
  let loading = ref(false)
  let showEditModal = ref(false)
  let showAddModal = ref(false)
  let editIndex = ref(null)

  // forms
  const editForm = formHandler()
  const addForm = formHandler()

  // handler
  const validator = useValidate()

  // create
  function add(key, value) {
    data.value[key] = value
  }

  function reset() {
    data.value = {}
  }

  function validate(event, type, rules, key) {
    const input = event.target.value

    // validatorguard
    if (validator.validate(input, type)) {
      data.value[key] = input
    } else {
      if (data.value[key] != undefined) {
        event.target.value = data.value[key]
      } else {
        event.target.value = null
      }
    }
  }

  function openAdd(fieldConfig) {
    addForm.create({
      type: 'add',
      fields: fieldConfig,
      data: data,
    })
    showAddModal.value = true
  }

  function openEdit(editData, index, fieldConfig) {
    editIndex.value = index
    editForm.create({
      type: 'edit',
      fields: fieldConfig,
      data: editData,
    })
    showEditModal.value = true
  }

  function closeEdit() {
    showEditModal.value = false
  }

  function closeAdd() {
    showAddModal.value = false
    reset()
  }

  return {
    data,
    add,
    validate,
    loading,
    reset,
    openAdd,
    closeAdd,
    addForm,
    editForm,
    openEdit,
    closeEdit,
    showEditModal,
    showAddModal,
    editIndex,
  }
}
