<template>
  <base-pill class="w-fit" :variant="variantlower">{{ $translate(label) }}</base-pill>
</template>

<script>
import { computed } from 'vue'

export default {
  props: {
    variant: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const variantlower = computed(() => {
      if (props.variant) {
        return props.variant.toLowerCase()
      } else {
        return 'disabled'
      }
    })

    const label = computed(() => {
      if (props.variant) {
        return props.variant
      } else {
        return 'Undefined'
      }
    })

    return {
      label,
      variantlower,
    }
  },
}
</script>
