<template>
  <div class="p-6">
    <base-page-title :title="meta.title" :icon="meta.titleIcon"></base-page-title>

    <baseTable
      :fields="useViewNodes.nodeFields.value"
      :records="useViewNodes.records.value"
      :records-per-page="25"
      :add-button="false"
      :edit-button="false"
      :delete-button="false"
      :export-button="true"
      :clickAble="true"
      :clickCallback="tableAction"
      :loading="useViewNodes.loadHandler.somethingLoading.value"
      :hover-effect="true"
      leadIcon="CircleIcon"
      :sortable="true"
      @export="onExport"
      :demo="useGlobal.settings.value.demo"
    />
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { computed, onMounted } from 'vue'
import viewHandlerNodes from '@/use/viewHandlerNodes'
import router from '@/router'
import exportExcelHandler from '@/use/exportExcelHandler'
import globalHandler from '@/use/handlerGlobal'

export default {
  setup() {
    const route = useRoute()
    const useViewNodes = viewHandlerNodes()
    const useExportExcel = exportExcelHandler()
    const useGlobal = globalHandler()

    function onExport(data) {
      useExportExcel.exportExcel('SimpleWEG aansluitingen', 'aansluitingen', data.records, data.fields)
    }

    function tableAction(payload) {
      router.push({
        name: 'node_details',
        params: { objectId: payload.object_id },
      })
    }

    onMounted(() => {
      useViewNodes.listRecords()
    })

    const meta = computed(() => route.meta)
    return {
      meta,
      useViewNodes,
      tableAction,
      onExport,
      useGlobal,
    }
  },
}
</script>
