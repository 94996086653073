<template>
  <div class="text-gray-500">
    <div class="flex flex-row justify-between items-center">
      <!-- label -->
      <div class="align-middle">
        <span v-if="label">{{ $translate(label) }}</span>
      </div>

      <!-- options -->
      <div class="flex flex-row">
        <base-button
          variant="neutral"
          :icon="'SettingsIcon'"
          class="text-gray-500 mr-2"
          v-if="scatter"
          @click="toggleChart"
        >
          <scatterPlotIcon class="w-5 h-5 text-gray-500" />
        </base-button>

        <base-button-drop-down
          :alignRight="true"
          :handler="optionsDropDown"
          variant="neutral"
          :icon="'SettingsIcon'"
          class="mr-2"
        >
          <base-button-drop-down-item @click="seriesToExcel({ title: exportTitle })">
            <div class="flex flex-row">
              <featherIcon class="mr-2 w-4 h-4 text-gray-500" icon="DownloadIcon" />
              <p class="whitespace-nowrap my-auto">Download xlsx</p>
            </div>
          </base-button-drop-down-item>
        </base-button-drop-down>

        <base-button variant="neutral" :icon="'MaximizeIcon'" class="text-gray-500" @click="openModal">
          <featherIcon class="w-4 h-4 text-gray-500" icon="MaximizeIcon" />
        </base-button>
      </div>
    </div>

    <div v-if="!isScatterPlot">
      <vue-highcharts
        class="mt-2"
        type="chart"
        :options="options"
        :redrawOnUpdate="true"
        :oneToOneUpdate="false"
        :animateOnUpdate="true"
        ref="root"
      />
    </div>

    <div v-else>
      <vue-highcharts
        class="mt-2"
        :type="type"
        :options="scatterOptions"
        :redrawOnUpdate="true"
        :oneToOneUpdate="false"
        :animateOnUpdate="true"
        ref="scatterRoot"
      />
    </div>

    <!-- Chart Modal -->
    <base-modal v-if="showModal" @close="closeModal" :title="label || 'Chart'" size="xl" height="l">
      <div class="w-full h-full">
        <vue-highcharts
          class="h-full"
          type="chart"
          :options="isScatterPlot ? scatterOptions : options"
          :redrawOnUpdate="true"
          :oneToOneUpdate="false"
          :animateOnUpdate="true"
        />
      </div>
    </base-modal>
  </div>
</template>

<script>
import { nextTick, ref, watch } from 'vue'
import VueHighcharts from '@/use/highCharts'
import baseButtonDropDownHandler from '@/use/baseButtonDropDownHandler'
import ExcelJS from 'exceljs'
import scatterPlotIcon from '@/components/svg/scatterPlotIcon.vue'

export default {
  props: {
    type: {
      type: String,
      default: 'chart',
    },
    label: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    exportUnit: {
      type: String,
      default: '',
    },
    exportTitle: {
      type: String,
      default: '',
    },
    exportParserX: {
      required: false,
      default: null,
    },
    exportParserY: {
      required: false,
      default: null,
    },
    scatter: {
      type: Boolean,
      default: false,
    },
    scatterOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const optionsDropDown = baseButtonDropDownHandler()
    const isScatterPlot = ref(false)
    const showModal = ref(false)

    // Watch the scatter prop and toggle back to normal graph if scatter is set to false
    watch(
      () => props.scatter,
      (newVal) => {
        if (!newVal && isScatterPlot.value) {
          isScatterPlot.value = false
        }
      },
    )

    function toggleChart() {
      isScatterPlot.value = !isScatterPlot.value
    }

    async function seriesToExcel({ title = 'SimpleWEG - Export', worksheetName = 'Data' } = {}) {
      await nextTick()
      const series = isScatterPlot.value ? props.scatterOptions.series : props.options.series

      if (!series) {
        console.error('No valid data found for export')
        return
      }

      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet(worksheetName)

      // Function to safely format value using parser or fallback
      const formatValue = (value) => {
        try {
          if (value === null || value === undefined) {
            return null
          }

          // For numbers, return the actual number, not a string
          if (typeof value === 'number') {
            return value
          }

          // Try to parse string to number if possible
          const numberValue = parseFloat(value)
          if (!isNaN(numberValue)) {
            return numberValue
          }

          return value
        } catch (error) {
          console.error('Error formatting value:', value, error)
          return null
        }
      }

      // Function to detect data format
      const isTimeSeriesData = (data) => {
        return Array.isArray(data[0]) && data[0].length === 2
      }

      // Define columns with types
      const columns = isTimeSeriesData(series[0].data)
        ? [
            { header: 'Serie', key: 'series', width: 15 },
            { header: 'Datum', key: 'date', width: 20 },
            { header: 'Waarde', key: 'value', width: 15, style: { numFmt: '0.00' } },
            { header: 'Eenheid', key: 'unit', width: 10 },
          ]
        : [
            { header: 'Serie', key: 'series', width: 15 },
            { header: 'Uur', key: 'hour', width: 10 },
            { header: 'Waarde', key: 'value', width: 15, style: { numFmt: '0.00' } },
            { header: 'Eenheid', key: 'unit', width: 10 },
          ]

      console.log(columns)

      worksheet.columns = columns

      if (isTimeSeriesData(series[0].data)) {
        series.forEach((serie) => {
          const serieName = serie.name || 'Onbekend'
          serie.data.forEach((row) => {
            try {
              if (!row || !Array.isArray(row)) return
              const [timestamp, value] = row

              const rowData = {
                series: serieName,
                date: props.exportParserX ? props.exportParserX(timestamp) : timestamp,
                value: formatValue(value),
                unit: props.exportUnit,
              }

              const excelRow = worksheet.addRow(rowData)
              excelRow.getCell('value').numFmt = '0.00' // Set Dutch number format
            } catch (error) {
              console.error('Error adding time series row:', row, error)
            }
          })
        })
      } else {
        series.forEach((serie) => {
          const serieName = serie.name || 'Onbekend'
          serie.data.forEach((value, index) => {
            try {
              const rowData = {
                series: serieName,
                hour: `${index.toString().padStart(2, '0')}:00`,
                value: formatValue(value),
                unit: props.exportUnit,
              }

              const excelRow = worksheet.addRow(rowData)
              excelRow.getCell('value').numFmt = '0.00' // Set Dutch number format
            } catch (error) {
              console.error('Error adding hourly row:', value, error)
            }
          })
        })
      }

      // Style header
      const headerRow = worksheet.getRow(1)
      headerRow.font = { bold: true }
      headerRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFE6E6E6' },
      }

      // Auto-fit columns
      worksheet.columns.forEach((column) => {
        if (!column || !column.header) return
        let maxLength = column.header.length
        column.eachCell({ includeEmpty: true }, (cell) => {
          const length = cell.value ? cell.value.toString().length : 10
          maxLength = Math.max(maxLength, length)
        })
        column.width = maxLength + 2
      })

      try {
        const buffer = await workbook.xlsx.writeBuffer()
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const url = window.URL.createObjectURL(blob)
        const anchor = document.createElement('a')
        anchor.href = url
        anchor.download = `${title || 'Export'}.xlsx`
        anchor.click()

        window.URL.revokeObjectURL(url)
        anchor.remove()
      } catch (error) {
        console.error('Export failed:', error)
      }
    }
    function openModal() {
      showModal.value = true
    }

    function closeModal() {
      showModal.value = false
    }

    return {
      optionsDropDown,
      seriesToExcel,
      toggleChart,
      isScatterPlot,
      openModal,
      closeModal,
      showModal,
    }
  },
  components: {
    VueHighcharts,
    scatterPlotIcon,
  },
}
</script>
