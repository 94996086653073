<template>
  <svg
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    width="97.215706mm"
    height="91.757652mm"
    viewBox="0 0 97.215706 91.757652"
    version="1.1"
    id="svg8"
    inkscape:version="0.92.3 (2405546, 2018-03-11)"
    sodipodi:docname="mainmenusw.svg"
  >
    <defs id="defs2">
      <!-- <style id="style3713">
        .cls-1 {
          fill: none;
        }
        .cls-2 {
          fill: #92cdf1;
        }
        .cls-3 {
          fill: #ffcb00;
        }
        .cls-4 {
          fill: #adcb53;
        }
        .cls-5 {
          fill: #b2b2b2;
        }
      </style> -->
    </defs>
    <g inkscape:label="Layer 1" inkscape:groupmode="layer" id="layer1" transform="translate(-49.665955,-96.150937)">
      <g id="g3783" transform="matrix(0.26458333,0,0,0.26458333,23.165298,74.786957)">
        <path
          id="path3717"
          d="m 335.74,152.13 c 3.17,-3.19 6.36,-6.19 9.51,-9 a 66.5,66.5 0 0 0 -123,-3.61 c 4.54,5.62 9.47,11.94 14.42,18.67 25.33,34.44 37.64,60.9 37.64,80.9 A 86.63,86.63 0 0 1 254,294.9 a 6.49,6.49 0 0 0 6.16,4.49 h 7.1 a 16.24,16.24 0 0 0 31.52,0 h 7.1 a 6.5,6.5 0 0 0 6,-4.16 86.48,86.48 0 0 1 -18.2,-42.34 c -4.68,-33.65 9.9,-68.5 42.06,-100.76 z"
          class="cls-1"
          inkscape:connector-curvature="0"
          style="fill: none"
        />
        <path
          id="path3719"
          d="m 349.49,166.5 c 0,-0.29 0,-0.57 0,-0.86 -21.24,21.28 -41.34,51 -36.7,84.61 a 68.73,68.73 0 0 0 2.36,10.75 c 1.86,-13.08 4.88,-27 9.76,-34.81 12.96,-20.83 24.58,-33.49 24.58,-59.69 z"
          class="cls-1"
          inkscape:connector-curvature="0"
          style="fill: none"
        />
        <path
          id="path3721"
          d="m 347.24,227.07 c -1.95,3 -4,6.08 -6,9.3 -4.74,7.62 -8.42,32 -9.48,52.31 a 68.15,68.15 0 0 0 9.63,8.08 c 0,-0.09 0,-0.18 -0.05,-0.26 -1.93,-14 4,-28.38 17.23,-41.62 a 103.34,103.34 0 0 1 19.28,-15.09 l 16.88,-9.72 -2.52,19.31 c -0.21,1.65 -0.46,8.7 2.24,13 1.15,1.82 3.43,3.67 5.85,5.64 5.14,4.16 12.17,9.87 13.6,20.2 a 35.13,35.13 0 0 1 -0.45,12.36 c 23.59,-12.14 38.12,-36.29 34.21,-64.6 -3.76,-27.19 -32.64,-35.14 -45.54,-55.54 -12.9,-20.4 -9.12,-48.87 -9.12,-48.87 a 222,222 0 0 0 -25.84,18.23 85.9,85.9 0 0 1 1.65,16.7 c -0.05,27.66 -10.5,43.65 -21.57,60.57 z"
          class="cls-1"
          inkscape:connector-curvature="0"
          style="fill: none"
        />
        <path
          id="path3723"
          d="m 216.56,163.51 c 0,1 -0.07,2 -0.07,3 0,26.2 11.76,38.86 24.72,59.69 5,8 8,22.43 9.84,35.79 a 67.86,67.86 0 0 0 4,-22.9 C 255,218.68 234.89,187.59 216.56,163.51 Z"
          class="cls-1"
          inkscape:connector-curvature="0"
          style="fill: none"
        />
        <path
          id="path3725"
          d="m 218.73,226.86 c -10.57,-16.12 -21.51,-32.8 -21.51,-60.36 a 85.48,85.48 0 0 1 3.2,-23.15 c -7.77,-9.27 -13.2,-15.15 -13.2,-15.15 0,0 -67.79,73.44 -67.79,110.88 A 67.78,67.78 0 0 0 234.17,288 c -1,-20.15 -4.63,-44.08 -9.31,-51.6 -2.06,-3.33 -4.13,-6.49 -6.13,-9.54 z"
          class="cls-1"
          inkscape:connector-curvature="0"
          style="fill: none"
        />
        <path
          id="path3727"
          d="m 388.18,283 c -3.35,-2.73 -7.15,-5.81 -10,-10.31 a 31.62,31.62 0 0 1 -3.2,-6.81 c -8.09,7.36 -16,17.63 -14.53,28 a 17.27,17.27 0 0 0 15.6,14.81 l 1.4,0.05 a 18.1,18.1 0 0 0 2.43,-0.14 c 8.49,-1.17 16.35,-7.65 14.95,-17.75 -0.35,-2.57 -2.2,-4.29 -6.65,-7.85 z"
          class="cls-1"
          inkscape:connector-curvature="0"
          style="fill: none"
        />
        <path
          id="path3729"
          d="m 236.64,158.18 c -4.95,-6.73 -9.88,-13.05 -14.42,-18.67 -5.16,-6.39 -9.8,-11.86 -13.39,-16 -4.36,-5 -7.17,-8.07 -7.45,-8.38 L 187.22,99.8 173.07,115.13 a 554.36,554.36 0 0 0 -35.27,43.05 c -25.33,34.44 -37.64,60.9 -37.64,80.9 a 87,87 0 0 0 139.53,69.43 25.65,25.65 0 0 1 -5.3,-15.22 c 0,-1.73 -0.12,-3.51 -0.22,-5.33 A 67.77,67.77 0 0 1 119.43,239.08 c 0,-37.44 67.79,-110.88 67.79,-110.88 0,0 5.43,5.88 13.2,15.15 4.74,5.65 10.35,12.55 16.14,20.16 18.33,24.08 38.44,55.17 38.44,75.57 a 67.86,67.86 0 0 1 -4,22.9 c 2.19,16.22 2.61,30.94 2.61,30.94 a 6.46,6.46 0 0 0 0.31,2 86.63,86.63 0 0 0 20.31,-55.82 C 274.28,219.08 262,192.62 236.64,158.18 Z"
          class="cls-2"
          inkscape:connector-curvature="0"
          style="fill: #92cdf1"
        />
        <path
          id="path3731"
          d="m 367.11,149.8 a 85,85 0 0 0 -6.39,-19.49 85.71,85.71 0 0 0 -151.89,-6.8 c 3.59,4.14 8.23,9.61 13.39,16 a 66.48,66.48 0 0 1 127.25,26.13 c 0,0.29 0,0.57 0,0.86 0,26.2 -11.62,38.86 -24.58,59.69 C 320,234 317,247.92 315.15,261 a 67.51,67.51 0 0 0 16.63,27.68 c 1.06,-20.29 4.74,-44.69 9.48,-52.31 2,-3.22 4,-6.31 6,-9.3 11.07,-16.92 21.52,-32.91 21.52,-60.57 a 85.9,85.9 0 0 0 -1.67,-16.7 z"
          class="cls-3"
          inkscape:connector-curvature="0"
          style="fill: #ffcb00"
        />
        <path
          id="path3733"
          d="m 311.89,295.23 a 6.5,6.5 0 0 1 -6,4.16 h -7.1 a 16.24,16.24 0 0 1 -31.52,0 h -7.1 a 6.47,6.47 0 0 1 -6.47,-6.47 c 0,0 -0.42,-14.72 -2.61,-30.94 -1.81,-13.36 -4.84,-27.75 -9.84,-35.79 -13,-20.83 -24.72,-33.49 -24.72,-59.69 0,-1 0,-2 0.07,-3 -5.79,-7.61 -11.4,-14.51 -16.14,-20.16 a 85.48,85.48 0 0 0 -3.2,23.15 c 0,27.56 10.94,44.24 21.51,60.36 2,3 4.07,6.21 6.13,9.51 4.68,7.52 8.27,31.45 9.31,51.59 0.1,1.82 0.17,3.6 0.22,5.33 a 25.85,25.85 0 0 0 21.37,25 35.54,35.54 0 0 0 54.46,0 25.68,25.68 0 0 0 15.6,-9.17 87.49,87.49 0 0 1 -13.97,-13.88 z"
          class="cls-3"
          inkscape:connector-curvature="0"
          style="fill: #ffcb00"
        />
        <path
          id="path3735"
          d="m 388.18,283 c -3.35,-2.73 -7.15,-5.81 -10,-10.31 a 31.62,31.62 0 0 1 -3.2,-6.81 c -8.09,7.36 -16,17.63 -14.53,28 a 17.27,17.27 0 0 0 15.6,14.81 l 1.4,0.05 a 18.1,18.1 0 0 0 2.43,-0.14 c 8.49,-1.17 16.35,-7.65 14.95,-17.75 -0.35,-2.57 -2.2,-4.29 -6.65,-7.85 z"
          class="cls-1"
          inkscape:connector-curvature="0"
          style="fill: none"
        />
        <path
          id="path3737"
          d="m 466.76,233.31 c -3.33,-24.09 -19.5,-37.21 -32.49,-47.76 -6.42,-5.21 -12.5,-10.13 -15.84,-15.43 -7.38,-11.67 -7.1,-30 -6.37,-36.1 l 5.4,-38.8 -34.13,19.65 a 229.27,229.27 0 0 0 -22.61,15.44 85,85 0 0 1 6.39,19.49 222,222 0 0 1 25.89,-18.23 c 0,0 -3.7,28.44 9.19,48.84 12.89,20.4 41.78,28.35 45.54,55.54 3.91,28.31 -10.62,52.46 -34.21,64.6 a 35.13,35.13 0 0 0 0.45,-12.36 c -1.43,-10.33 -8.46,-16 -13.6,-20.2 -2.42,-2 -4.7,-3.82 -5.85,-5.64 -2.7,-4.27 -2.45,-11.32 -2.24,-13 l 2.52,-19.31 -16.88,9.72 a 103.34,103.34 0 0 0 -19.28,15.09 c -13.2,13.24 -19.16,27.64 -17.23,41.62 0,0.08 0,0.17 0.05,0.26 a 67.66,67.66 0 0 1 -28.64,-46.51 c -4.64,-33.62 15.46,-63.33 36.7,-84.61 a 66.21,66.21 0 0 0 -4.22,-22.52 c -3.15,2.82 -6.34,5.82 -9.51,9 -32.16,32.26 -46.7,67.11 -42.05,100.76 a 86.48,86.48 0 0 0 18.2,42.34 87.44,87.44 0 0 0 62.63,32.65 q 1.51,0.12 3.06,0.12 a 86.93,86.93 0 0 0 14.26,-0.78 c 23,-3.17 43.33,-14.14 57.24,-30.87 14.54,-17.5 20.87,-39.89 17.63,-63 z m -86.88,75.27 a 18.1,18.1 0 0 1 -2.43,0.14 l -1.4,-0.05 a 17.27,17.27 0 0 1 -15.6,-14.81 c -1.44,-10.4 6.44,-20.67 14.53,-28 a 31.62,31.62 0 0 0 3.2,6.81 c 2.85,4.5 6.65,7.58 10,10.31 4.45,3.61 6.3,5.33 6.65,7.88 1.4,10.07 -6.46,16.55 -14.95,17.72 z"
          class="cls-4"
          inkscape:connector-curvature="0"
          style="fill: #adcb53"
        />
        <path
          id="path3739"
          d="m 133.52,399.77 c -4.57,-1.61 -8.52,-3 -8.52,-7 0,-3.4 2.68,-5.77 6.52,-5.77 a 9.58,9.58 0 0 1 5.9,1.78 5.15,5.15 0 0 0 2.84,1.09 2.9,2.9 0 0 0 2.9,-3 c 0,-2.76 -4.6,-5.71 -11.46,-5.71 a 14.12,14.12 0 0 0 -10,3.92 11.66,11.66 0 0 0 -3.63,8 c 0,8.23 6.47,10.46 12.18,12.42 4.78,1.65 8.92,3.08 8.92,7.55 a 8.18,8.18 0 0 1 -8.58,8.51 13.67,13.67 0 0 1 -7.37,-2.31 6,6 0 0 0 -2.74,-1.12 c -1.7,0 -2.85,1.34 -2.85,3.35 0,3.53 6.67,5.9 12.9,5.9 10.7,0 15.58,-7.56 15.58,-14.58 -0.01,-8.6 -6.69,-10.96 -12.59,-13.03 z"
          class="cls-5"
          inkscape:connector-curvature="0"
          style="fill: #b2b2b2"
        />
        <path
          id="path3741"
          d="m 157,381.18 a 3.17,3.17 0 0 0 -3.28,3.47 V 424 a 3.29,3.29 0 1 0 6.56,0 V 384.65 A 3.17,3.17 0 0 0 157,381.18 Z"
          class="cls-5"
          inkscape:connector-curvature="0"
          style="fill: #b2b2b2"
        />
        <path
          id="path3743"
          d="m 218.62,421.5 -6.06,-35.69 c -0.68,-3.83 -3,-4.63 -4.83,-4.63 -2.44,0 -3.83,1 -4.51,3.25 l -9,31 -9,-31 c -0.68,-2.25 -2.07,-3.25 -4.51,-3.25 -1.83,0 -4.15,0.8 -4.83,4.62 l -6.06,35.71 a 16.24,16.24 0 0 0 -0.32,2.58 c 0,2.07 1.19,3.35 3.1,3.35 2.08,0 3.21,-0.93 3.46,-2.84 l 4.8,-33.6 10,33.77 a 3.41,3.41 0 0 0 6.64,0 l 10,-33.78 4.87,33.58 c 0.25,1.9 1.38,2.83 3.46,2.83 1.91,0 3.1,-1.28 3.1,-3.35 a 16.34,16.34 0 0 0 -0.31,-2.55 z"
          class="cls-5"
          inkscape:connector-curvature="0"
          style="fill: #b2b2b2"
        />
        <path
          id="path3745"
          d="m 240.79,381.55 h -10.86 a 3.22,3.22 0 0 0 -3.47,3.53 V 424 a 3.29,3.29 0 1 0 6.57,0 V 410.46 H 241 a 14.43,14.43 0 0 0 14.77,-14.39 c -0.02,-8.55 -6.17,-14.52 -14.98,-14.52 z m 8.39,14.52 a 8.29,8.29 0 0 1 -8.58,8.58 H 233 v -17.28 h 7.33 c 6.53,0 8.85,4.49 8.85,8.7 z"
          class="cls-5"
          inkscape:connector-curvature="0"
          style="fill: #b2b2b2"
        />
        <path
          id="path3747"
          d="m 280.14,420.87 h -11.2 v -36.22 a 3.29,3.29 0 1 0 -6.56,0 v 38.88 a 3.21,3.21 0 0 0 3.47,3.53 h 14.29 a 3.1,3.1 0 1 0 0,-6.19 z"
          class="cls-5"
          inkscape:connector-curvature="0"
          style="fill: #b2b2b2"
        />
        <path
          id="path3749"
          d="M 310.16,420.87 H 297.47 V 407.4 H 309 a 3.1,3.1 0 1 0 0,-6.19 h -11.53 v -13.47 h 12.69 a 3.1,3.1 0 1 0 0,-6.19 h -15.79 a 3.22,3.22 0 0 0 -3.47,3.53 v 38.45 a 3.22,3.22 0 0 0 3.47,3.53 h 15.79 a 3.1,3.1 0 1 0 0,-6.19 z"
          class="cls-5"
          inkscape:connector-curvature="0"
          style="fill: #b2b2b2"
        />
        <path
          id="path3751"
          d="m 368.71,381.18 a 3.25,3.25 0 0 0 -3.52,3 l -5.46,33.44 -9.66,-33.87 a 3.44,3.44 0 0 0 -6.65,0 l -9.66,33.87 -5.46,-33.43 a 3.25,3.25 0 0 0 -3.53,-3 2.93,2.93 0 0 0 -3,3 15.47,15.47 0 0 0 0.32,2.91 l 6.74,36.26 c 0.37,1.89 1.21,4.06 4.46,4.06 2.33,0 3.87,-0.66 4.82,-4 l 8.66,-31 8.66,31 c 1,3.35 2.5,4 4.83,4 3.25,0 4.09,-2.17 4.45,-4.06 l 6.75,-36.26 a 15.47,15.47 0 0 0 0.32,-2.91 2.93,2.93 0 0 0 -3.07,-3.01 z"
          class="cls-2"
          inkscape:connector-curvature="0"
          style="fill: #92cdf1"
        />
        <path
          id="path3753"
          d="M 399,420.87 H 386.3 V 407.4 h 11.51 a 3.1,3.1 0 1 0 0,-6.19 H 386.3 V 387.74 H 399 a 3.1,3.1 0 1 0 0,-6.19 h -15.79 a 3.22,3.22 0 0 0 -3.47,3.53 v 38.45 a 3.22,3.22 0 0 0 3.47,3.53 H 399 a 3.1,3.1 0 1 0 0,-6.19 z"
          class="cls-3"
          inkscape:connector-curvature="0"
          style="fill: #ffcb00"
        />
        <path
          id="path3755"
          d="m 443.35,400.53 h -9.24 a 3.1,3.1 0 1 0 0,6.19 h 7.21 v 2.08 c 0,8.33 -6.32,12.82 -12.26,12.82 -10.16,0 -13.76,-9.32 -13.76,-17.31 0,-8.62 4.18,-17.32 13.51,-17.32 a 14.81,14.81 0 0 1 9.51,3.35 c 1.23,0.88 2.28,1.64 3.47,1.64 a 3.08,3.08 0 0 0 3.16,-3.15 c 0,-3 -6.77,-7.65 -15.77,-7.65 -14.38,0 -20.82,11.61 -20.82,23.13 a 26,26 0 0 0 5.05,15.95 18.27,18.27 0 0 0 15,7.18 c 12.76,0 19.51,-7.57 19.51,-21.88 -0.04,-3.56 -1.35,-5.03 -4.57,-5.03 z"
          class="cls-4"
          inkscape:connector-curvature="0"
          style="fill: #adcb53"
        />
      </g>
    </g>
  </svg>
</template>
