<template>
  <div class="z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <!-- grey background -->
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-50"></div>

    <!-- card -->
    <div class="fixed z-50 inset-0 overflow-y-auto">
      <div class="flex items-end items-center justify-center h-full p-4 text-center sm:p-0">
        <div
          :class="`flex flex-col relative bg-white rounded-lg text-left shadow-xl transform transition-all ${computedSize} ${computedHeight}`"
        >
          <div class="flex-none bg-white px-6 pt-5">
            <!-- title row -->
            <span class="flex justify-between">
              <!-- title -->
              <div class="text-lg text-gray-500 mb-4">
                {{ title }}
              </div>
              <!-- closer -->
              <div class="text-gray-400 cursor-pointer" @click="close()">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </div>
            </span>
          </div>
          <div class="grow bg-white px-6 pt-5 pb-4 h-full">
            <slot></slot>
          </div>
          <div class="flex-none bg-gray-50 px-6 py-3">
            <slot name="buttons"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'm',
    },
    height: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const sizeSelector = {
      xs: 'w-1/5',
      s: 'w-1/3',
      m: 'w-1/2',
      l: 'w-3/4',
      xl: 'w-4/5',
    }

    const heightSelector = {
      l: 'h-5/6',
      max: 'h-full',
    }

    const computedSize = computed(() => {
      return sizeSelector[props.size]
    })

    const computedHeight = computed(() => {
      return heightSelector[props.height]
    })

    function close() {
      emit('close')
    }

    return {
      sizeSelector,
      close,
      computedSize,
      computedHeight,
    }
  },
}
</script>
