import { ref } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import formHandler from '@/use/formHandler'

export default () => {
  let records = ref([])
  let showAdd = ref(false)
  const useApi = apiHandler()
  const loadHandler = loadingHandler()
  const addForm = formHandler()

  function listRecords() {
    loadHandler.setLoadingState('list_clusters', true)
    useApi.request_api('get', 'v1', 'clusters/').then((response) => {
      const data = response.data
      records.value = data
      loadHandler.setLoadingState('list_clusters', false)
      records.value.forEach((record) => {
        record.nodes_count = record.nodes.length
      })
    })
  }

  function openAdd() {
    addForm.create({
      type: 'add',
      fields: clusterFields.value,
      data: {},
    })
    showAdd.value = true
  }

  function closeAdd() {
    showAdd.value = false
    addForm.close()
  }

  function add() {
    loadHandler.setLoadingState('add_cluster', true)

    const payload = addForm.data.value

    useApi.request_api('post', 'v1', 'clusters/', payload).then(() => {
      closeAdd()
      listRecords()
      loadHandler.setLoadingState('add_cluster', false)
    })
  }

  function deleteCluster(objectId) {
    loadHandler.setLoadingState('delete_cluster', true)
    useApi.request_api('delete', 'v1', `clusters/${objectId}`).then(() => {
      listRecords()
      loadHandler.setLoadingState('delete_cluster', false)
    })
  }

  const clusterFields = ref([
    {
      label: 'Name',
      key: 'name',
      type: 'string',
      searchable: true,
    },
    {
      label: 'Creation date',
      key: 'date_created',
      type: 'date',
      filter: 'dateFromEpochDay',
      add: false,
    },
    {
      label: 'Medium',
      key: 'medium',
      type: 'string',
      component: 'pillMainType',
      add: false,
      searchable: true,
    },
    {
      label: 'Nodes',
      key: 'nodes_count',
      type: 'string',
      add: false,
    },
    {
      label: 'Comment',
      key: 'comment',
      type: 'string',
      table: false,
    },
  ])

  return {
    loadHandler,
    clusterFields,
    records,
    showAdd,
    listRecords,
    addForm,
    openAdd,
    closeAdd,
    add,
    deleteCluster,
  }
}
