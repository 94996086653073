<template>
  <!-- levering -->
  <base-collapse class="mb-2">
    <!-- header -->
    <template v-slot:header>
      <div class="flex flex-row">
        <!-- <span><featherIcon class="w-5 h-5 mr-1" icon="BarChart2Icon" /></span> -->
        <span class="mr-2">{{ $translate('Supply') }}</span>
      </div>
    </template>
    <template v-slot:header-right>
      <div class="mr-2">
        {{ useFilter.filter(sumSummary(useviewHandlerAmounts.dataWater.value.Usage), 'm3') }}
      </div>
    </template>

    <!-- body -->
    <template v-slot:body>
      <cardChart
        :options="useviewHandlerAmounts.chart_options_usage_water.value"
        :scatter="useviewHandlerAmounts.filterForm.data.value.interval === 'day'"
        :scatterOptions="useviewHandlerAmounts.chart_options_scatter_water.value"
        exportUnit="m3"
        exportTitle="Simpleweg - Water - Levering"
        :exportParserX="dutchDateParser"
      />

      <cardChart
        type="stockChart"
        v-if="useviewHandlerAmounts.filterForm.data.value.interval === 'hour'"
        :options="useviewHandlerAmounts.chart_options_usage_water_weekday.value"
        :scatter="false"
        :scatterOptions="{}"
        exportUnit="m3"
        label="Gesommeerd dagprofiel"
        exportTitle="Simpleweg - Water - Levering - Gesommeerd dagprofiel"
      />
    </template>
  </base-collapse>
</template>

<script>
import filterHandler from '@/use/filterHandler'
import cardChart from '@/views/components/cardChart.vue'
import viewHandlerAmounts from '@/use/viewHandlerAmounts'
import { dutchDateParser } from './parsers'

export default {
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  setup() {
    const useFilter = filterHandler()
    const useviewHandlerAmounts = viewHandlerAmounts()

    function sumSummary(data) {
      if (data) {
        let sum = 0
        data.forEach((element) => {
          sum += element[1]
        })
        return sum
      }
      return 0
    }

    return {
      sumSummary,
      useFilter,
      useviewHandlerAmounts,
      dutchDateParser,
    }
  },
  components: {
    cardChart,
  },
}
</script>
