import { ref, computed } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
// import chartOptionshandler from '@/use/chartOptionsHandler'
// import timeHandler from './timeHandler'

export default function createMultiScenarioHandler() {
  const useApi = apiHandler()
  const loadHandler = loadingHandler()
  // const useTime = timeHandler()
  // const useChartOptions = chartOptionshandler()

  const scenarios = ref({})
  const scenarioLoadingStatus = ref({})
  const globalWeatherData = ref({
    day: {},
    month: {},
    year: {},
  })

  function initializeScenario(scenarioId) {
    if (!scenarios.value[scenarioId]) {
      scenarios.value[scenarioId] = {
        data: {},
        amountsData: {},
        storedWeatherData: {},
        categorizedNodes: {
          Electricity: { Usage: [], Return: [], Generation: [] },
          Gas: { Usage: [], Return: [], Generation: [] },
          Water: { Usage: [], Return: [], Generation: [] },
          Heat: { Usage: [], Return: [], Generation: [] },
        },
        configuration: {},
      }
    }
  }

  function getDetails(scenarioId, overrideInterval = null) {
    initializeScenario(scenarioId)
    scenarioLoadingStatus.value[scenarioId] = 'loading'
    loadHandler.setLoadingState(`get_details_${scenarioId}`, true)

    return useApi
      .request_api('get', 'v1', 'scenarios/' + scenarioId)
      .then((response) => {
        const data = response.data
        scenarios.value[scenarioId].data = data

        const settings = { ...data.configuration } || {}
        if (overrideInterval) {
          settings.interval = overrideInterval
        }

        scenarios.value[scenarioId].originalInterval = data.configuration?.interval || settings.interval
        scenarios.value[scenarioId].configuration = settings

        return fetchNodesByTags(scenarioId, settings)
      })
      .then(() => {
        scenarioLoadingStatus.value[scenarioId] = 'loaded'
      })
      .catch(() => {
        scenarioLoadingStatus.value[scenarioId] = 'error'
      })
      .finally(() => {
        loadHandler.setLoadingState(`get_details_${scenarioId}`, false)
      })
  }

  function fetchNodesByTags(scenarioId, settings) {
    const testpayload = {
      tags: useApi.arrayToCommaSeparatedString(settings.tags?.map((tag) => tag.name) || []),
      tag_relation: settings.filter_logic || 'subset',
      fields: 'type,medium,object_id',
    }

    const queryString = useApi.toQueryString(testpayload)

    return useApi.request_api('get', 'v1', 'nodes?' + queryString).then((response) => {
      const nodes = response.data
      categorizeNodesByMedium(scenarioId, nodes, settings)
    })
  }

  function categorizeNodesByMedium(scenarioId, nodes, settings) {
    const categorizedNodes = scenarios.value[scenarioId].categorizedNodes

    nodes.forEach((node) => {
      if (node.type) {
        const { count_usage, count_return, count_generation } = node.type

        if (node.medium in categorizedNodes) {
          if (count_usage) categorizedNodes[node.medium].Usage.push(node.object_id)
          if (count_return) categorizedNodes[node.medium].Return.push(node.object_id)
          if (count_generation) categorizedNodes[node.medium].Generation.push(node.object_id)
        }
      }
    })

    Object.entries(categorizedNodes).forEach(([medium, types]) => {
      Object.entries(types).forEach(([type, ids]) => {
        if (ids.length > 0) {
          getSummaryNew(
            scenarioId,
            {
              start_date: settings.start_date,
              end_date: settings.end_date,
              interval: settings.interval,
              ids: ids,
            },
            medium,
            type,
          )
        }
      })
    })
  }

  function getSummaryNew(scenarioId, payload, medium, type) {
    loadHandler.setLoadingState(`get_summary_${scenarioId}_${medium.toLowerCase()}_${type.toLowerCase()}`, true)

    const queryString = useApi.toQueryString(payload)
    return useApi
      .request_api('get', 'v1/nodes', 'data/summary?' + queryString)
      .then((response) => {
        const data = response.data
        if (!scenarios.value[scenarioId].amountsData[medium]) {
          scenarios.value[scenarioId].amountsData[medium] = {}
        }

        scenarios.value[scenarioId].amountsData[medium][type] = type === 'Usage' ? data.usage : data.production
      })
      .finally(() => {
        loadHandler.setLoadingState(`get_summary_${scenarioId}_${medium.toLowerCase()}_${type.toLowerCase()}`, false)
      })
  }

  async function fetchWeatherDataForAllScenarios() {
    const allScenarios = Object.values(scenarios.value)
    if (allScenarios.length === 0) return

    const startTimestamps = allScenarios.map((s) => new Date(s.configuration.start_date).getTime())
    const endTimestamps = allScenarios.map((s) => new Date(s.configuration.end_date).getTime())

    const oldestStartTimestamp = Math.min(...startTimestamps)
    const newestEndTimestamp = Math.max(...endTimestamps)

    const payload = {
      start_date: Math.floor(oldestStartTimestamp / 1000), // Convert to seconds
      end_date: Math.floor(newestEndTimestamp / 1000), // Convert to seconds
      station_id: '260',
      fields: 'tg,interval_start',
    }
    const queryString = useApi.toQueryString(payload)

    try {
      const response = await useApi.request_api('get', 'v1', 'weather?' + queryString)
      const weatherData = parseKnmi(response.data)
      globalWeatherData.value = weatherData

      // Store weather data for each scenario
      Object.keys(scenarios.value).forEach((scenarioId) => {
        const scenario = scenarios.value[scenarioId]
        const scenarioStart = new Date(scenario.configuration.start_date).getTime()
        const scenarioEnd = new Date(scenario.configuration.end_date).getTime()

        scenario.storedWeatherData = Object.entries(weatherData).reduce((acc, [timestamp, data]) => {
          const currentTimestamp = parseInt(timestamp)
          if (currentTimestamp >= scenarioStart && currentTimestamp <= scenarioEnd) {
            acc[timestamp] = data
          }
          return acc
        }, {})
      })
    } catch (error) {
      console.error('Error fetching weather data:', error)
    }
  }

  function parseKnmi(data) {
    const dailyData = {}
    const monthlySum = {}
    const yearlySum = {}

    function formatDate(date, interval) {
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')

      switch (interval) {
        case 'day':
          return `${year}-${month}-${day}T00:00:00Z`
        case 'month':
          return `${year}-${month}-01T00:00:00Z`
        case 'year':
          return `${year}-01-01T00:00:00Z`
      }
    }

    data.forEach((record) => {
      const date = new Date(record.interval_start)

      // Daily data
      const dailyDateString = formatDate(date, 'day')
      dailyData[dailyDateString] = record.tg

      // Monthly data
      const monthlyDateString = formatDate(date, 'month')
      if (!monthlySum[monthlyDateString]) {
        monthlySum[monthlyDateString] = { sum: 0, count: 0 }
      }
      monthlySum[monthlyDateString].sum += record.tg
      monthlySum[monthlyDateString].count++

      // Yearly data
      const yearlyDateString = formatDate(date, 'year')
      if (!yearlySum[yearlyDateString]) {
        yearlySum[yearlyDateString] = { sum: 0, count: 0 }
      }
      yearlySum[yearlyDateString].sum += record.tg
      yearlySum[yearlyDateString].count++
    })

    // Calculate monthly averages
    const monthlyData = Object.entries(monthlySum).reduce((acc, [key, value]) => {
      acc[key] = value.sum / value.count
      return acc
    }, {})

    // Calculate yearly averages
    const yearlyData = Object.entries(yearlySum).reduce((acc, [key, value]) => {
      acc[key] = value.sum / value.count
      return acc
    }, {})

    return { day: dailyData, month: monthlyData, year: yearlyData }
  }

  function calculateAverageTemperature(startDate, endDate, interval) {
    const start = new Date(startDate)
    const end = new Date(endDate)
    const averages = {}

    function addToAverages(key, value) {
      if (!averages[key]) {
        averages[key] = { sum: 0, count: 0 }
      }
      averages[key].sum += value
      averages[key].count++
    }

    for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
      const dateString = d.toISOString().split('T')[0]
      if (globalWeatherData.value[dateString]) {
        const temperature = globalWeatherData.value[dateString].TG

        // Daily average
        addToAverages(dateString, temperature)

        // Monthly average
        const monthKey = `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}`
        addToAverages(monthKey, temperature)

        // Yearly average
        const yearKey = d.getFullYear().toString()
        addToAverages(yearKey, temperature)
      }
    }

    // Calculate final averages
    Object.keys(averages).forEach((key) => {
      averages[key] = averages[key].sum / averages[key].count
    })

    // Return averages based on the interval
    switch (interval) {
      case 'day':
        return Object.entries(averages)
          .filter(([key]) => key.includes('-'))
          .reduce((obj, [key, value]) => {
            obj[key] = value
            return obj
          }, {})
      case 'month':
        return Object.entries(averages)
          .filter(([key]) => key.includes('-') && key.length === 7)
          .reduce((obj, [key, value]) => {
            obj[key] = value
            return obj
          }, {})
      case 'year':
        return Object.entries(averages)
          .filter(([key]) => !key.includes('-'))
          .reduce((obj, [key, value]) => {
            obj[key] = value
            return obj
          }, {})
      default:
        console.error('Invalid interval specified')
        return null
    }
  }

  const scenarioData = computed(() => {
    return Object.keys(scenarios.value).reduce((acc, scenarioId) => {
      const scenario = scenarios.value[scenarioId]
      const interval = scenario.configuration.interval
      const startDate = scenario.configuration.start_date
      const endDate = scenario.configuration.end_date

      acc[scenarioId] = {
        dataGas: scenario.amountsData['Gas'],
        dataWater: scenario.amountsData['Water'],
        dataElectricity: scenario.amountsData['Electricity'],
        dataHeat: scenario.amountsData['Heat'],
        averageTemperatures: calculateAverageTemperature(startDate, endDate, interval),
      }
      return acc
    }, {})
  })

  const regressionLines = []

  function generateMultiScenarioScatterplot(medium) {
    const seriesData = []
    const colors = [
      'rgba(124, 181, 236, 1)', // #7cb5ec
      'rgba(247, 163, 92, 1)', // #f7a35c
      'rgba(144, 237, 125, 1)', // #90ed7d
      'rgba(228, 211, 84, 1)', // #e4d354
      'rgba(128, 133, 233, 1)', // #8085e9
      'rgba(241, 92, 128, 1)', // #f15c80
      'rgba(128, 133, 232, 1)', // #8085e8
      'rgba(145, 232, 225, 1)', // #91e8e1
    ]

    // eslint-disable-next-line no-unused-vars
    Object.entries(scenarios.value).forEach(([scenarioId, scenario], index) => {
      if (scenario.configuration.interval !== 'day') {
        return
      }

      const measurementData = scenario.amountsData[medium]?.Usage
      const weatherData = globalWeatherData.value.day

      if (!measurementData || !weatherData) {
        // console.warn(`Missing data for scenario ${scenarioId}, skipping`)
        return
      }

      const scenarioData = measurementData
        .map(([datetime, value]) => {
          const temperatureEntry = weatherData[datetime]
          if (temperatureEntry) {
            return [temperatureEntry, value]
          }
          return null
        })
        .filter((point) => point !== null)

      const regressionLine = calculateRegressionLine(scenarioData)
      regressionLines.push(regressionLine)

      const color = colors[index % colors.length]
      const transparentColor = color.replace('1)', '0.5)')
      console.log(transparentColor)
      seriesData.push({
        name: `${scenario.data.description}`,
        type: 'scatter',
        data: scenarioData,
        color: transparentColor,
        marker: {
          radius: 4,
          symbol: 'circle',
        },
      })

      seriesData.push({
        name: `${scenario.data.description} Regression`,
        type: 'line',
        data: regressionLine,
        color: colors[index % colors.length],
        lineWidth: 3,
        marker: { enabled: false },
        enableMouseTracking: false,
        dashStyle: 'Dash',
      })
    })

    // Calculate percentage differences
    // console.log(regressionLines.length)
    // if (regressionLines.length > 1) {
    //   for (let i = 0; i < regressionLines.length; i++) {
    //     for (let j = i + 1; j < regressionLines.length; j++) {
    //       const diff = calculateRegressionLineDifference(regressionLines[i].line, regressionLines[j].line)
    //       console.log(`Difference between ${regressionLines[i].name} and ${regressionLines[j].name}:`, diff)
    //     }
    //   }
    // }

    return {
      chart: {
        type: 'scatter',
        zoomType: 'xy',
      },
      title: {
        text: `${medium} Usage vs. Temperature (All Scenarios)`,
      },
      xAxis: {
        title: {
          text: 'Temperature (°C)',
        },
      },
      yAxis: {
        title: {
          text: `${medium} Usage`,
        },
      },
      series: seriesData,
      tooltip: {
        formatter: function () {
          if (this.series.type === 'scatter') {
            return `<b>${this.series.name}</b><br/>` + `Temperature: ${this.x}°C<br/>` + `${medium} Usage: ${this.y}`
          }
          return false
        },
      },
    }
  }

  const scatterplotOptions = computed(() => {
    // Only generate options if all scenarios have finished loading
    const allScenariosLoaded = Object.values(scenarioLoadingStatus.value).every((status) => status === 'loaded')

    if (!allScenariosLoaded) {
      return {
        electricity: null,
        gas: null,
        water: null,
        heat: null,
      }
    }

    return {
      electricity: generateMultiScenarioScatterplot('Electricity'),
      gas: generateMultiScenarioScatterplot('Gas'),
      water: generateMultiScenarioScatterplot('Water'),
      heat: generateMultiScenarioScatterplot('Heat'),
    }
  })

  function linearRegression(data) {
    const n = data.length
    let sumX = 0
    let sumY = 0
    let sumXY = 0
    let sumXX = 0

    for (let i = 0; i < n; i++) {
      sumX += data[i][0]
      sumY += data[i][1]
      sumXY += data[i][0] * data[i][1]
      sumXX += data[i][0] * data[i][0]
    }

    const slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX)
    const intercept = (sumY - slope * sumX) / n

    return { slope, intercept }
  }

  function calculateRegressionLine(data) {
    if (data.length === 0) return []

    const { slope, intercept } = linearRegression(data)

    const minTemp = Math.min(...data.map((point) => point[0]))
    const maxTemp = Math.max(...data.map((point) => point[0]))

    return [
      [minTemp, slope * minTemp + intercept],
      [maxTemp, slope * maxTemp + intercept],
    ]
  }

  // function calculateRegressionLineDifference(line1, line2) {
  //   // Extract slopes and intercepts
  //   const [m1, b1] = [
  //     (line1[1][1] - line1[0][1]) / (line1[1][0] - line1[0][0]),
  //     line1[0][1] - line1[0][0] * ((line1[1][1] - line1[0][1]) / (line1[1][0] - line1[0][0])),
  //   ]
  //   const [m2, b2] = [
  //     (line2[1][1] - line2[0][1]) / (line2[1][0] - line2[0][0]),
  //     line2[0][1] - line2[0][0] * ((line2[1][1] - line2[0][1]) / (line2[1][0] - line2[0][0])),
  //   ]

  //   // Find the temperature range where both lines are positive
  //   const x1 = Math.max(line1[0][0], line2[0][0], -b1 / m1, -b2 / m2)
  //   const x2 = Math.min(line1[1][0], line2[1][0])

  //   if (x1 >= x2) {
  //     return 'No valid comparison range'
  //   }

  //   // Calculate values at 100 points along the valid range
  //   const points = 100
  //   let sumDiff = 0
  //   let count = 0

  //   for (let i = 0; i <= points; i++) {
  //     const x = x1 + (x2 - x1) * (i / points)
  //     const y1 = m1 * x + b1
  //     const y2 = m2 * x + b2

  //     if (y1 > 0 && y2 > 0) {
  //       const percentDiff = (Math.abs(y1 - y2) / ((y1 + y2) / 2)) * 100
  //       sumDiff += percentDiff
  //       count++
  //     }
  //   }

  //   // Calculate average percentage difference
  //   const avgPercentDiff = sumDiff / count

  //   return {
  //     averagePercentageDifference: avgPercentDiff,
  //     validRange: [x1, x2],
  //   }
  // }

  // function parseTimestamps(data, interval) {
  //   return data.map(([datetime, value]) => [useTime.labelSelector(interval, new Date(datetime)), value])
  // }

  return {
    getDetails,
    scenarioData,
    scenarioLoadingStatus,
    scenarios,
    globalWeatherData,
    fetchWeatherDataForAllScenarios,
    scatterplotOptions,
    // ... other necessary methods
  }
}
