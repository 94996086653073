import lookupHandler from '@/use/lookupHandler'
import filterHandler from '@/use/filterHandler'
import translationHandler from '@/plugins/i18n/translationHandler'
import Highcharts from 'highcharts'
import Boost from 'highcharts/modules/boost'
Boost(Highcharts)

// import { format } from 'date-fns'
// import { DateTime } from 'luxon'

export default () => {
  const useLookup = lookupHandler()
  const useFilter = filterHandler()
  const useTranslate = translationHandler()

  // Add new helper function for processing hourly data
  function processHourlyData(data) {
    if (!Array.isArray(data)) {
      console.error('Data must be an array', data)
      return {
        hourlyAggregation: [],
        weekdayHourlyData: {},
      }
    }

    // Process each data point with proper timestamp parsing
    const processedData = data
      .map(([dateStr, value]) => {
        try {
          if (!dateStr || typeof dateStr !== 'string') {
            console.error('Invalid date string:', dateStr)
            return null
          }

          // Handle both formats by first removing any comma and excess whitespace
          const cleanDateStr = dateStr.replace(',', '').trim()
          const [datePart, timePart] = cleanDateStr.split(' ')

          if (!datePart || !timePart) {
            console.error('Could not split date and time:', dateStr)
            return null
          }

          const [day, month, year] = datePart.split('-').map((n) => parseInt(n, 10))
          const [hour, minute] = timePart.split(':').map((n) => parseInt(n, 10))

          // Create timestamp (months are 0-based in JavaScript Date)
          const date = new Date(year, month - 1, day, hour, minute)

          return {
            date,
            // Convert Sunday (0) to 6 for proper week display starting Monday (0)
            dayOfWeek: (date.getDay() + 6) % 7,
            hour: hour,
            value: value,
          }
        } catch (error) {
          console.error('Error parsing date:', dateStr, error)
          return null
        }
      })
      .filter(Boolean) // Remove any null values from parsing errors

    // Initialize data structures
    const days = ['Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag', 'Zondag']
    const weekdayData = {}

    // Initialize weekday data structure
    days.forEach((day) => {
      weekdayData[day] = Array(24)
        .fill(0)
        .map((_, hour) => ({
          hour,
          value: 0,
          count: 0,
        }))
    })

    // Aggregate data by actual day of week
    processedData.forEach((point) => {
      if (point) {
        const weekday = days[point.dayOfWeek]
        weekdayData[weekday][point.hour].value += point.value
        weekdayData[weekday][point.hour].count++
      }
    })

    // Calculate hourly aggregation (average by hour across all days)
    const hourlyAggregation = Array(24)
      .fill(0)
      .map((_, hour) => {
        const hourData = processedData.filter((p) => p && p.hour === hour)
        return {
          hour,
          average: hourData.length > 0 ? hourData.reduce((sum, p) => sum + p.value, 0) / hourData.length : 0,
        }
      })

    // Calculate averages for each weekday
    const weekdayHourlyData = {}
    days.forEach((day) => {
      weekdayHourlyData[day] = weekdayData[day].map((hourData) => ({
        hour: hourData.hour,
        value: hourData.count > 0 ? hourData.value / hourData.count : 0,
      }))
    })

    return {
      hourlyAggregation,
      weekdayHourlyData,
    }
  }
  // Set Highcharts to use UTC
  // Highcharts.setOptions({
  //   time: {
  //     useUTC: true,
  //   },
  // })

  function getOptions(type, data, title, interval = null) {
    const selector = {
      Electricity: {
        chart: {
          type: 'column',
          marginTop: 60,
        },
        exporting: {
          enabled: false,
        },
        colors: [useLookup.utilities.electricity.colour],
        credits: {
          enabled: false,
        },
        title: {
          text: '',
        },
        xAxis: {
          type: 'category',
          lineColor: 'rgb(156, 163, 175)', // Color for the x-axis line
          labels: {
            style: {
              color: 'rgb(156, 163, 175)', // Color for the x-axis labels
            },
          },
        },
        yAxis: {
          title: {
            text: null,
          },
          labels: {
            formatter: function () {
              return `${useFilter.filter(this.value, 'kWh')}`
            },
            style: {
              color: 'rgb(156, 163, 175)', // Color for the x-axis labels
            },
          },
          lineColor: 'rgb(156, 163, 175)', // Color for the x-axis line
        },
        legend: {
          align: 'center',
          verticalAlign: 'bottom',
          x: 0,
          y: 0,
          floating: false,
          shadow: false,
          itemStyle: {
            color: 'rgb(156, 163, 175)', // Sets the legend text color
          },
        },
        tooltip: {
          formatter: function () {
            return `${this.point.name}<br>${this.series.name}: ${useFilter.filter(this.point.y, 'kWh')}`
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            name: `${useTranslate.t(title)}`,
            data: data,
          },
        ],
      },
      Gas: {
        chart: {
          type: 'column',
        },
        exporting: {
          enabled: false,
        },
        colors: [useLookup.utilities.gas.colour],
        credits: {
          enabled: false,
        },
        title: {
          text: null,
        },
        xAxis: {
          type: 'category',
          lineColor: 'rgb(156, 163, 175)', // X-axis line color
          labels: {
            style: {
              color: 'rgb(156, 163, 175)', // X-axis labels color
            },
          },
        },
        yAxis: {
          title: {
            text: null,
          },
          lineColor: 'rgb(156, 163, 175)', // Y-axis line color
          labels: {
            formatter: function () {
              return `${useFilter.filter(this.value, 'm3')}`
            },
            style: {
              color: 'rgb(156, 163, 175)', // Y-axis labels color
            },
          },
        },
        legend: {
          align: 'center',
          verticalAlign: 'bottom',
          x: 0,
          y: 0,
          floating: false,
          shadow: false,
          itemStyle: {
            color: 'rgb(156, 163, 175)', // Legend labels color
          },
        },
        tooltip: {
          formatter: function () {
            return `${this.point.name}<br>${this.series.name}: ${useFilter.filter(this.point.y, 'm3')}`
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            name: `${useTranslate.t(title)}`,
            data: data,
          },
        ],
      },
      Water: {
        chart: {
          type: 'column',
        },
        exporting: {
          enabled: false,
        },
        colors: [useLookup.utilities.water.colour],
        credits: {
          enabled: false,
        },
        title: {
          text: null,
        },
        xAxis: {
          type: 'category',
          lineColor: 'rgb(156, 163, 175)', // X-axis line color
          labels: {
            style: {
              color: 'rgb(156, 163, 175)', // X-axis labels color
            },
          },
        },
        yAxis: {
          title: {
            text: null,
          },
          lineColor: 'rgb(156, 163, 175)', // Y-axis line color
          labels: {
            formatter: function () {
              return `${useFilter.filter(this.value, 'm3')}`
            },
            style: {
              color: 'rgb(156, 163, 175)', // Y-axis labels color
            },
          },
        },
        legend: {
          align: 'center',
          verticalAlign: 'bottom',
          x: 0,
          y: 0,
          floating: false,
          shadow: false,
          itemStyle: {
            color: 'rgb(156, 163, 175)', // Legend labels color
          },
        },
        tooltip: {
          formatter: function () {
            return `${this.point.name}<br>${this.series.name}: ${useFilter.filter(this.point.y, 'm3')}`
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            name: `${useTranslate.t(title)}`,
            data: data,
          },
        ],
      },
      Heat: {
        chart: {
          type: 'column',
        },
        exporting: {
          enabled: false,
        },
        colors: [useLookup.utilities.heat.colour],
        credits: {
          enabled: false,
        },
        title: {
          text: null,
        },
        xAxis: {
          type: 'category',
          lineColor: 'rgb(156, 163, 175)', // X-axis line color
          labels: {
            style: {
              color: 'rgb(156, 163, 175)', // X-axis labels color
            },
          },
        },
        yAxis: {
          title: {
            text: null,
          },
          lineColor: 'rgb(156, 163, 175)', // Y-axis line color
          labels: {
            formatter: function () {
              return `${useFilter.filter(this.value, 'GJ')}`
            },
            style: {
              color: 'rgb(156, 163, 175)', // Y-axis labels color
            },
          },
        },
        legend: {
          align: 'center',
          verticalAlign: 'bottom',
          x: 0,
          y: 0,
          floating: false,
          shadow: false,
          itemStyle: {
            color: 'rgb(156, 163, 175)', // Legend labels color
          },
        },
        tooltip: {
          formatter: function () {
            return `${this.point.name}<br>${this.series.name}: ${useFilter.filter(this.point.y, 'GJ')}`
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            name: `${useTranslate.t(title)}`,
            data: data,
          },
        ],
      },
      HeatCost: {
        chart: {
          type: 'column',
        },
        exporting: {
          enabled: false,
        },
        colors: [useLookup.utilities.heatcost.colour],
        credits: {
          enabled: false,
        },
        title: {
          text: null,
        },
        xAxis: {
          type: 'category',
          lineColor: 'rgb(156, 163, 175)',
          labels: {
            style: {
              color: 'rgb(156, 163, 175)',
            },
          },
        },
        yAxis: {
          title: {
            text: null,
          },
          lineColor: 'rgb(156, 163, 175)',
          labels: {
            formatter: function () {
              return `${this.value}`
            },
            style: {
              color: 'rgb(156, 163, 175)',
            },
          },
        },
        legend: {
          align: 'center',
          verticalAlign: 'bottom',
          x: 0,
          y: 0,
          floating: false,
          shadow: false,
          itemStyle: {
            color: 'rgb(156, 163, 175)',
          },
        },
        tooltip: {
          formatter: function () {
            return `${this.point.name}<br>${this.series.name}: ${this.point.y} Eenheden`
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            name: `${useTranslate.t(title)}`,
            data: data,
          },
        ],
      },
    }

    const baseConfig = selector[type]

    if (interval === 'hour') {
      const config = { ...baseConfig }

      const units = {
        Electricity: 'kWh',
        Gas: 'm3',
        Water: 'm3',
        Heat: 'GJ',
        HeatCost: 'Eenheden',
      }

      // Convert string dates to timestamps
      config.series[0].data = data
        .map(([dateStr, value]) => {
          try {
            if (!dateStr || typeof dateStr !== 'string') {
              console.error('Invalid date string:', dateStr)
              return null
            }

            // Handle both formats by first removing any comma and excess whitespace
            const cleanDateStr = dateStr.replace(',', '').trim()
            const [datePart, timePart] = cleanDateStr.split(' ')

            if (!datePart || !timePart) {
              console.error('Could not split date and time:', dateStr)
              return null
            }

            const [day, month, year] = datePart.split('-').map((n) => parseInt(n, 10))
            const [hour, minute] = timePart.split(':').map((n) => parseInt(n, 10))

            // Create timestamp (months are 0-based in JavaScript Date)
            const timestamp = Date.UTC(year, month - 1, day, hour, minute)

            return [timestamp, value]
          } catch (error) {
            console.error('Error parsing date:', dateStr, error)
            return null
          }
        })
        .filter((item) => item !== null) // Remove any failed conversions

      config.chart = {
        type: 'column',
        zoomType: 'x',
        panning: true,
        panKey: 'shift',
      }

      const dutchMonths = {
        short: ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
        long: [
          'januari',
          'februari',
          'maart',
          'april',
          'mei',
          'juni',
          'juli',
          'augustus',
          'september',
          'oktober',
          'november',
          'december',
        ],
      }
      config.xAxis = {
        type: 'datetime',
        ordinal: false,
        lineColor: 'rgb(156, 163, 175)',
        labels: {
          formatter: function () {
            const date = new Date(this.value)
            const hour = date.getUTCHours()

            // For midnight, show date and time
            if (hour === 0) {
              const day = date.getUTCDate()
              const month = dutchMonths.short[date.getUTCMonth()]
              return `${day} ${month}<br>${hour.toString().padStart(2, '0')}:00`
            }
            // For other hours, just show time
            return `${hour.toString().padStart(2, '0')}:00`
          },
          style: {
            color: 'rgb(156, 163, 175)',
          },
        },
      }

      config.tooltip = {
        formatter: function () {
          const dateStr = Highcharts.dateFormat('%e %b %Y, %H:%M', this.x)
          const unit = units[type] || ''
          const valueStr = useFilter.filter(this.y, unit)
          return `${dateStr}<br>${this.series.name}: ${valueStr}`
        },
      }

      // Add range selector
      config.rangeSelector = {
        buttons: [
          {
            type: 'day',
            count: 1,
            text: '1D',
          },
          {
            type: 'week',
            count: 1,
            text: '1W',
          },
          {
            type: 'month',
            count: 1,
            text: '1M',
          },
          {
            type: 'month',
            count: 3,
            text: '3M',
          },
          {
            type: 'all',
            text: 'All',
          },
        ],
        selected: 3,
      }

      return config
    }

    return baseConfig
  }

  // Add new function for weekday comparison chart
  function getWeekdayComparisonOptions(data, type) {
    const { weekdayHourlyData } = processHourlyData(data)

    const weekdayColors = {
      Maandag: '#ff4d4d',
      Dinsdag: '#ffcd56',
      Woensdag: '#ff99cc',
      Donderdag: '#4dff4d',
      Vrijdag: '#ffa64d',
      Zaterdag: '#4d4dff',
      Zondag: '#800080',
    }

    const units = {
      Electricity: 'kWh',
      Gas: 'm3',
      Water: 'm3',
      Heat: 'GJ',
      HeatCost: 'Eenheden',
    }

    return {
      chart: { type: 'line' },
      exporting: { enabled: false },
      credits: { enabled: false },
      title: { text: '' },
      xAxis: {
        categories: Array.from({ length: 24 }, (_, i) => `${i}:00`),
        lineColor: 'rgb(156, 163, 175)',
        labels: { style: { color: 'rgb(156, 163, 175)' } },
      },
      yAxis: {
        title: { text: null },
        labels: {
          formatter: function () {
            return useFilter.filter(this.value, units[type])
          },
          style: { color: 'rgb(156, 163, 175)' },
        },
        lineColor: 'rgb(156, 163, 175)',
      },
      tooltip: {
        formatter: function () {
          return `${this.series.name}, ${this.x}<br>${useFilter.filter(this.y, units[type])}`
        },
      },
      plotOptions: {
        line: {
          marker: { enabled: false },
        },
      },
      series: Object.entries(weekdayHourlyData).map(([day, data]) => ({
        name: day,
        color: weekdayColors[day],
        data: data.map((d) => d.value),
      })),
    }
  }

  function getScatterOptions(medium, data, regressionLines, splitDate = null) {
    const mediumConfig = {
      Electricity: {
        color: 'rgba(255, 204, 0, 0.5)',
        yAxisText: 'Verbruik (kWh)',
        tooltipFormat: '{point.date} TG: {point.x}, Verbruik: {point.y} kWh',
        unit: 'kWh',
        regressionColor: 'rgba(255, 204, 0, 0.7)',
      },
      Gas: {
        color: 'rgba(174, 204, 83, 0.5)',
        yAxisText: 'Verbruik (m3)',
        tooltipFormat: '{point.date} TG: {point.x}, Verbruik: {point.y} m3',
        unit: 'm3',
        regressionColor: 'rgba(174, 204, 83, 0.8)',
      },
      Water: {
        color: 'rgba(148, 206, 242, 0.5)',
        yAxisText: 'Verbruik (m3)',
        tooltipFormat: '{point.date} TG: {point.x}, Verbruik: {point.y} m3',
        unit: 'm3',
        regressionColor: 'rgba(148, 206, 242, 0.7)',
      },
      Heat: {
        color: 'rgba(237, 106, 90, 0.5)',
        yAxisText: 'Verbruik (GJ)',
        tooltipFormat: '{point.date} TG: {point.x}, Verbruik: {point.y} GJ',
        unit: 'GJ',
        regressionColor: 'rgba(237, 106, 90, 0.7)',
      },
      HeatCost: {
        color: 'rgba(147, 116, 138, 0.5)',
        yAxisText: 'Verbruik (Eenheden)',
        tooltipFormat: '{point.date} TG: {point.x}, Verbruik: {point.y} Eenheden',
        unit: 'Eenheden',
        regressionColor: 'rgba(147, 116, 138, 0.7)',
      },
    }

    const config = mediumConfig[medium] || mediumConfig.Electricity

    let series = []

    const comparisoncolour = 'rgba(145, 145, 145, 0.5)'

    const mediumTranslate = {
      Electricity: 'Elektriciteit',
      Gas: 'Gas',
      Water: 'Water',
      Heat: 'Warmte',
      HeatCost: 'Warmtekosten',
    }

    if (splitDate) {
      // Split plot scenario
      series = [
        {
          name: 'Verbruik voor splitsdatum',
          data: data.before.map((point) => ({
            date: point[0],
            x: point[1],
            y: point[2],
          })),
          color: comparisoncolour,
        },
        {
          name: 'Verbruik na splitsdatum',
          data: data.after.map((point) => ({
            date: point[0],
            x: point[1],
            y: point[2],
          })),
          color: config.color,
        },
        {
          name: `Regressielijn ${mediumTranslate[medium]} (voor)`,
          type: 'line',
          data: regressionLines.before,
          color: comparisoncolour,
          lineWidth: 2,
          dashStyle: 'Dash',
          marker: { enabled: false },
          states: { hover: { lineWidth: 2 } },
          enableMouseTracking: false,
        },
        {
          name: `Regressielijn ${mediumTranslate[medium]} (na)`,
          type: 'line',
          data: regressionLines.after,
          color: config.regressionColor,
          lineWidth: 2,
          dashStyle: 'Dash',
          marker: { enabled: false },
          states: { hover: { lineWidth: 2 } },
          enableMouseTracking: false,
        },
      ]
    } else {
      // Single plot scenario
      series = [
        {
          name: 'Verbruik per buitentemperatuur',
          data: data.map((point) => ({
            date: point[0],
            x: point[1],
            y: point[2],
          })),
          color: config.color,
        },
        {
          name: `Regressielijn ${mediumTranslate[medium]}`,
          type: 'line',
          data: regressionLines,
          color: config.regressionColor,
          lineWidth: 2,
          dashStyle: 'Dash',
          marker: { enabled: false },
          states: { hover: { lineWidth: 2 } },
          enableMouseTracking: false,
        },
      ]
    }

    return {
      chart: { type: 'scatter', zoomType: 'xy' },
      exporting: { enabled: false },
      credits: { enabled: false },
      title: { text: `` },
      xAxis: {
        title: { text: 'Buitentemperatuur (°C)', style: { color: 'rgb(156, 163, 175)' } },
        type: 'category',
        lineColor: 'rgb(156, 163, 175)',
        labels: { style: { color: 'rgb(156, 163, 175)' } },
      },
      yAxis: {
        title: { text: null },
        lineColor: 'rgb(156, 163, 175)',
        labels: {
          formatter: function () {
            return `${this.value} ${config.unit}`
          },
          style: { color: 'rgb(156, 163, 175)' },
        },
      },
      plotOptions: {
        scatter: {
          tooltip: {
            headerFormat: '<b>{series.name}</b><br>',
            pointFormat: config.tooltipFormat,
          },
          marker: {
            symbol: 'circle', // Set the marker symbol to 'circle' for all series
            radius: 4, // Set the marker size (adjust as needed)
          },
        },
      },
      series: series,
      legend: {
        align: 'center',
        verticalAlign: 'bottom',
        x: 0,
        y: 0,
        floating: false,
        shadow: false,
        itemStyle: { color: 'rgb(156, 163, 175)' },
      },
    }
  }

  return {
    getOptions,
    getScatterOptions,
    getWeekdayComparisonOptions,
  }
}
