import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import auth from './auth'
import i18n from './plugins/i18n/i18n'
import filters from './plugins/filters/filters'
import base from '@/plugins/base/index'
import * as Sentry from '@sentry/vue'
import './assets/tailwind.css'

const app = createApp(App)
const sentryEnvironment = process.env.VUE_APP_SENTRY_ENVIRONMENT
const sentryDSN = process.env.VUE_APP_SENTRY_DSN

// Enhanced error handling setup
if (process.env.NODE_ENV === 'development') {
  app.config.errorHandler = (error, instance, info) => {
    // Log the full error with stack trace
    console.group('Vue Error Details')
    console.error('Error:', error)
    console.log('Error Message:', error.message)
    console.log('Component Stack:', info)
    console.log('Component Instance:', instance)
    console.log('Component Data:', instance.$data)
    console.log('Component Props:', instance.$props)
    console.groupEnd()

    // Still send to Sentry in development if needed
    Sentry.captureException(error, {
      extra: {
        componentName: instance?.$.type?.name,
        props: instance?.$props,
        info: info,
      },
    })
  }

  app.config.warnHandler = (msg, instance, trace) => {
    console.group('Vue Warning Details')
    console.warn('Warning Message:', msg)
    console.log('Component:', instance)
    console.log('Trace:', trace)
    console.groupEnd()
  }

  // Performance tracking in development
  app.config.performance = true
}

// Sentry configuration with enhanced options
Sentry.init({
  app,
  dsn: sentryDSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'app.simpleweg.nl', /^\//],
    }),
  ],
  tracesSampleRate: 1.0,
  environment: sentryEnvironment,
  // Add more detailed context to Sentry errors
  beforeSend(event) {
    if (event.exception) {
      event.extra = {
        ...event.extra,
        vue_state: store.state, // Be careful with sensitive data
        vue_route: router.currentRoute.value,
      }
    }
    return event
  },
  // Attach Vue component information
  attachProps: true,
  logErrors: true,
})

// Global error boundary component
app.component('ErrorBoundary', {
  template: `
    <slot v-if="!error"></slot>
    <div v-else class="error-boundary">
      <p>Something went wrong in this component.</p>
      <button @click="resetError">Try again</button>
    </div>
  `,
  data() {
    return { error: null }
  },
  methods: {
    resetError() {
      this.error = null
    },
  },
  errorCaptured(err, vm, info) {
    this.error = err
    console.group('Error Boundary Caught:')
    console.error('Error:', err)
    console.log('Component:', vm)
    console.log('Info:', info)
    console.groupEnd()
    return false // Prevent error from propagating
  },
})

app.use(i18n, {})
app.use(filters)
app.use(base)
app.use(store).use(router).use(auth).mount('#app')

// Add global debug helper in development
if (process.env.NODE_ENV === 'development') {
  window.$vm = app._instance
  window.$debug = {
    app,
    router,
    store,
    auth,
  }
  console.log('Vue debug helpers available as window.$debug')
}
