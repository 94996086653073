<template>
  <!-- popout -->
  <base-button v-if="useContentGuard.canSee('object:write')" class="mb-2" @action="openPopOut()">
    <featherIcon class="w-4 h-4" icon="MapIcon" />
  </base-button>

  <!-- Map -->
  <div class="relative w-full h-[400px]">
    <span
      v-if="!geometryLoaded"
      class="absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white drop-shadow-lg p-4 rounded"
      >{{ $translate('No Geometry') }}</span
    >
    <div id="map" :class="geometryLoaded ? '' : 'opacity-25'" class="w-full z-0 h-[400px]"></div>
  </div>

  <!-- popout map -->
  <baseModalStatic size="xl" height="l" v-show="popped" @close="closePopOut()">
    <div class="flex flex-col h-full">
      <div class="flex flex-row mb-2">
        <featherIcon class="w-5 h-5 align-middle my-auto mr-2" icon="SearchIcon" />
        <base-geolocator :handler="geoLocatorHandler" class="w-full" @lookupresult="dingen" />
      </div>

      <!-- map -->
      <div class="w-full h-full z-0 row-span-full col-span-10 self-center" id="map_pop"></div>
    </div>
    <template v-slot:buttons>
      <div class="flex">
        <!-- edit button -->
        <baseButton @action="clickButton()" class="mr-2" :disabled="updateLoading">{{
          $translate(useNodeMap.buttonText.value)
        }}</baseButton>

        <!-- delete button -->
        <!-- <baseButton @action="clickDeleteButton()" variant="danger" :disabled="updateLoading">{{
          $translate('Delete geometry')
        }}</baseButton> -->

        <base-loader class="my-auto ml-4" :loading="updateLoading" />
      </div>
    </template>
  </baseModalStatic>
</template>

<script>
import 'leaflet/dist/leaflet.css'
import { onMounted, computed, ref } from 'vue'
import contentGuardHandler from '@/use/contentGuardHandler'
import featherIcon from '@/components/extended/feather/featherIcon.vue'
import nodeMapHandler from '@/use/nodeMapHandler'
import useGeoLocator from '@/use/useGeoLocator'

export default {
  props: {
    markerType: {
      type: String,
      default: '',
    },
    nodeId: {
      type: String,
      default: '',
    },
    geometry: {
      type: Object,
      default: () => {},
    },
    popOut: {
      type: Boolean,
      default: false,
    },
    updateLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['new_geometry', 'delete_geometry'],
  setup(props, { emit }) {
    const popped = ref(false)
    const useContentGuard = contentGuardHandler()
    const useNodeMap = nodeMapHandler()
    const geoLocatorHandler = useGeoLocator()

    const minimap = ref(null)
    const minimapMarker = ref(null)
    const popoutmap = ref(null)
    const popoutmapMarker = ref(null)

    const geometryLoaded = computed(() => {
      return props.geometry ? true : false
    })

    function openPopOut() {
      popped.value = true
      if (!popoutmap.value) {
        const { map, marker } = useNodeMap.renderMap('map_pop', 'popout_map', true, settings)
        popoutmap.value = map
        popoutmapMarker.value = marker
      }
    }

    function closePopOut() {
      geoLocatorHandler.reset()
      useNodeMap.removeSearchMarker(popoutmap.value)
      popped.value = false
    }

    const settings = computed(() => {
      if (geometryLoaded.value) {
        return {
          lat: props.geometry.lat,
          lng: props.geometry.lng,
          zoom: 19,
          markerType: props.markerType,
          nodeId: props.nodeId,
          geometry: true,
        }
      }
      return {
        lat: 52,
        lng: 6,
        zoom: 6,
        markerType: props.markerType,
        nodeId: props.nodeId,
        geometry: false,
      }
    })

    function clickButton() {
      const state = useNodeMap.editState.value
      if (state === 'neutral_no_location') {
        useNodeMap.setStateOriginal('neutral_no_location')
        useNodeMap.setState('editing')
      } else if (state === 'neutral_location') {
        useNodeMap.setStateOriginal('neutral_location')
        useNodeMap.setState('editing')
      } else {
        const newlat = useNodeMap.geometry.value.lat
        const newlng = useNodeMap.geometry.value.lng
        useNodeMap.updateMarker(minimapMarker.value, minimap.value, newlat, newlng, props.markerType)
        useNodeMap.panMap(minimap.value, newlat, newlng)
        useNodeMap.setState(useNodeMap.originalState.value)
        emit('new_geometry', useNodeMap.geometry.value)
      }
    }

    function dingen(payload) {
      useNodeMap.panMap(popoutmap.value, payload[1], payload[0], 19)
      useNodeMap.addSearchMarker(popoutmap.value, payload[1], payload[0])
    }

    function clickDeleteButton() {
      useNodeMap.removeMarker(popoutmap.value, popoutmapMarker.value)
      popoutmapMarker.value = null
      emit('delete_geometry', null)
    }

    onMounted(() => {
      const { map, marker } = useNodeMap.renderMap('map', 'minimap', false, settings)
      minimap.value = map
      minimapMarker.value = marker

      if (geometryLoaded.value) {
        useNodeMap.setState('neutral_location')
      }
    })

    return {
      geometryLoaded,
      useContentGuard,
      popped,
      openPopOut,
      closePopOut,
      useNodeMap,
      clickButton,
      dingen,
      geoLocatorHandler,
      clickDeleteButton,
      popoutmapMarker,
    }
  },
  components: {
    featherIcon,
  },
}
</script>
