<template>
  <div>
    <!-- Clusters -->
    <base-collapse v-for="(summary, clusterId) in data.clusters" :key="clusterId" class="mt-2 text-sm">
      <template v-slot:header>
        <div class="flex flex-row">
          <span><featherIcon class="w-5 h-5 mr-1" icon="Share2Icon" /></span>
          <span class="mr-2">{{ summary.cluster_name }}</span>
          <span>
            <base-pill :variant="summary.medium.toLowerCase()">{{ $translate(summary.medium) }}</base-pill>
          </span>
        </div>
      </template>
      <template v-slot:header-right>
        <div class="mr-2">{{ useFilter.toCurrencyWhole(selectClosedData(summary)) }}</div>
      </template>

      <template v-slot:body>
        <!-- Header electricity-->
        <div class="mt-4" v-if="summary.medium === 'Electricity'">
          <taxHeaderElectricity />
        </div>

        <!-- Header gas-->
        <div class="mt-4" v-if="summary.medium === 'Gas'">
          <taxHeaderGas />
        </div>

        <!-- Header water-->
        <div class="mt-4" v-if="summary.medium === 'Water'">
          <taxHeaderWater />
        </div>

        <!-- data electricity -->
        <div v-if="summary.medium === 'Electricity'">
          <taxRowElectricity :summary="summary" type="cluster" />
        </div>

        <!-- data gas -->
        <div v-if="summary.medium === 'Gas'">
          <taxRowGas :summary="summary" type="cluster" />
        </div>
        <!-- data water -->
        <div v-if="summary.medium === 'Water'">
          <taxRowWater :summary="summary" type="cluster" />
        </div>
      </template>
    </base-collapse>

    <!-- nodes -->
    <base-collapse v-for="(summary, nodeId) in data.nodes" :key="nodeId" class="mt-2 text-sm">
      <template v-slot:header>
        <div class="flex flex-row">
          <span><featherIcon class="w-5 h-5 mr-1" icon="CircleIcon" /></span>
          <span class="mr-2" v-if="summary.description">{{ summary.description }}</span>
          <span class="mr-2" v-else>{{ summary.provider_identifier }}</span>
          <span>
            <base-pill :variant="summary.medium.toLowerCase()">{{ $translate(summary.medium) }}</base-pill>
          </span>
        </div>
      </template>
      <template v-slot:header-right>
        <div class="mr-2">{{ useFilter.toCurrencyWhole(selectClosedData(summary)) }}</div>
      </template>

      <template v-slot:body>
        <!-- Header electricity-->
        <div class="mt-4" v-if="summary.medium === 'Electricity'">
          <taxHeaderElectricity />
        </div>

        <!-- Header gas-->
        <div class="mt-4" v-if="summary.medium === 'Gas'">
          <taxHeaderGas />
        </div>

        <!-- Header water-->
        <div class="mt-4" v-if="summary.medium === 'Water'">
          <taxHeaderWater />
        </div>

        <!-- data electricity -->
        <div v-if="summary.medium === 'Electricity'">
          <taxRowElectricity :summary="summary" type="node" />
        </div>

        <!-- data gas -->
        <div v-if="summary.medium === 'Gas'">
          <taxRowGas :summary="summary" type="node" />
        </div>
        <!-- data water -->
        <div v-if="summary.medium === 'Water'">
          <taxRowWater :summary="summary" type="node" />
        </div>
      </template>
    </base-collapse>
  </div>
</template>

<script>
import featherIcon from '@/components/extended/feather/featherIcon.vue'
import filterHandler from '@/use/filterHandler'
import taxHeaderElectricity from './taxHeaderElectricity.vue'
import taxHeaderGas from './taxHeaderGas.vue'
import taxHeaderWater from './taxHeaderWater.vue'
import taxRowElectricity from './taxRowElectricity.vue'
import taxRowGas from './taxRowGas.vue'
import taxRowWater from './taxRowWater.vue'

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const useFilter = filterHandler()

    function contractTitle(contractinfo) {
      const startDate = useFilter.dateFromEpochDay(contractinfo.start_date)
      const endtDate = useFilter.dateFromEpochDay(contractinfo.end_date)
      return `${contractinfo.provider} (${contractinfo.code}), ${startDate} / ${endtDate}`
    }

    function selectClosedData(data) {
      if (data.medium === 'Electricity') {
        return data.tax_totals.tax_total_electricity
      }
      if (data.medium === 'Gas') {
        return data.tax_totals.tax_total_gas
      }
      if (data.medium === 'Water') {
        return data.tax_totals.tax_total_water
      }
      return null
    }

    return {
      contractTitle,
      useFilter,
      selectClosedData,
    }
  },
  components: {
    featherIcon,
    taxHeaderElectricity,
    taxHeaderGas,
    taxHeaderWater,
    taxRowElectricity,
    taxRowGas,
    taxRowWater,
  },
}
</script>
