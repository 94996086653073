import { ref } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'

export default () => {
  let records = ref([])
  let showAdd = ref(false)
  let showEdit = ref(false)
  const useApi = apiHandler()
  const loadHandler = loadingHandler()

  function listRecords() {
    loadHandler.setLoadingState('list_tags', true)
    useApi.request_api('get', 'v1', 'tags/').then((response) => {
      records.value = response.data
      records.value.forEach((record) => {
        record.clusters_count = record.clusters.length
        record.nodes_count = record.nodes.length
      })
      loadHandler.setLoadingState('list_tags', false)
    })
  }

  const tagsFields = ref([
    {
      label: 'Name',
      key: 'name',
      type: 'string',
      searchable: true,
    },
    {
      label: 'Clusters',
      key: 'clusters_count',
      type: 'number',
    },
    {
      label: 'Nodes',
      key: 'nodes_count',
      type: 'number',
    },
  ])

  return {
    loadHandler,
    tagsFields,
    records,
    showEdit,
    showAdd,
    listRecords,
  }
}
