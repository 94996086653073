<template>
  <div
    :class="`py-1 px-3 rounded-full ${backgroundColour} text-white font-semibold text-xs ${clickableStyle} w-fit`"
    @click="pillClick()"
  >
    <!-- loading spinner -->
    <span class="flex">
      <slot></slot>
      <featherIcon @click="close()" v-if="closeButton" class="w-4 h-4 cursor-pointer" icon="XIcon" />
    </span>
  </div>
</template>

<script>
import themeHandler from '@/use/themeHandler'
import { computed } from 'vue'
import featherIcon from '@/components/extended/feather/featherIcon.vue'

export default {
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: 'normal',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    closeButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'action'],
  setup(props, { emit }) {
    const useTheme = themeHandler()

    const backgroundColour = computed(() => {
      return useTheme.getBackgroundColor(props.variant)
    })

    const clickableStyle = computed(() => {
      if (props.clickable) {
        return 'cursor-pointer'
      }
      return ''
    })

    function close() {
      emit('close')
    }

    function pillClick() {
      if (props.clickable) {
        emit('action')
      }
    }

    return {
      useTheme,
      backgroundColour,
      clickableStyle,
      close,
      pillClick,
    }
  },
  components: {
    featherIcon,
  },
}
</script>
