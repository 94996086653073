<template>
  <div tabindex="1" class="z-10 relative" @focusout="focusOut()">
    <!-- input -->
    <div class="relative">
      <input
        @focus="focusIn()"
        tabindex="2"
        ref="input"
        placeholder="Zoek adres"
        :value="handler.searchInput.value"
        @input="handler.setInput"
        class="bg-white appearance-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
      />
      <ul
        tabindex="3"
        v-if="show"
        class="absolute bg-white mt-1 w-full border border-gray-200 py-1 text-sm text-gray-700 z-50"
      >
        <li
          class="cursor-pointer"
          @mousedown="onSelect(result)"
          v-for="(result, index) in handler.searchResult.value"
          :key="index"
        >
          <span class="bg-white flex justify-between block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600">
            <span>
              <b>{{ result.type }}</b>
            </span>
            <span>{{ result.weergavenaam }}</span>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
export default {
  props: {
    handler: {
      type: Object,
      default: () => {},
    },
  },
  emit: ['lookupresult'],
  setup(props, { emit }) {
    const input = ref('')
    let isFocussed = ref(false)

    const show = computed(() => {
      if (
        props.handler.searchResult.value.length !== 0 &&
        props.handler.searchInput.value.length !== 0 &&
        isFocussed.value
      ) {
        return true
      }
      return false
    })

    function focusIn() {
      isFocussed.value = true
    }

    function focusOut() {
      isFocussed.value = false
    }

    function dingensdfsdf() {
      onSelect()
      isFocussed.value = false
    }

    async function onSelect(result) {
      props.handler.setSearchInput(result.weergavenaam)
      props.handler.setSearchResult([])
      props.handler.lookup(result.id).then((response) => {
        const centroide_ll = response.response.docs[0].centroide_ll
        const coords = centroide_ll.split('(')[1].split(')')[0].split(' ')
        const lat = Number(coords[0])
        const lng = Number(coords[1])
        emit('lookupresult', [lat, lng])
      })
    }

    return {
      show,
      input,
      focusIn,
      focusOut,
      isFocussed,
      onSelect,
      dingensdfsdf,
    }
  },
}
</script>
