<template>
  <div class="relative">
    <!-- popout -->
    <base-button v-if="useContentGuard.canSee('object:write')" class="mb-2" @action="openPopOut()">
      <featherIcon class="w-4 h-4" icon="MapIcon" />
    </base-button>

    <!-- Map -->
    <div class="w-full h-[400px]">
      <span
        v-if="!geometryLoaded"
        class="absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white drop-shadow-lg p-4 rounded"
        >{{ $translate('No Geometry') }}</span
      >
      <div id="map" :class="geometryLoaded ? '' : 'opacity-25'" class="w-full z-0 h-[400px]"></div>
    </div>

    <baseModalStatic size="xl" height="l" v-show="popped" @close="closePopOut()">
      <div class="flex flex-col h-full">
        <div class="flex flex-row mb-2">
          <featherIcon class="w-5 h-5 align-middle my-auto mr-2" icon="SearchIcon" />
          <base-geolocator :handler="geoLocatorHandler" class="w-full" @lookupresult="dingen" />
        </div>

        <!-- map -->
        <div
          class="w-full h-full z-0 row-span-full col-span-10 self-center"
          id="map_pop"
          :class="geometryLoaded ? '' : 'opacity-25'"
        ></div>
      </div>
    </baseModalStatic>
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css'
import { onMounted, computed, ref } from 'vue'
import contentGuardHandler from '@/use/contentGuardHandler'
import featherIcon from '@/components/extended/feather/featherIcon.vue'
import nodeMapHandlerMultiple from '@/use/nodeMapHandlerMultiple'
import useGeoLocator from '@/use/useGeoLocator'

export default {
  props: {
    markerType: {
      type: String,
      default: '',
    },
    nodeId: {
      type: String,
      default: '',
    },
    nodes: {
      type: Array,
      default: () => [],
    },
    popOut: {
      type: Boolean,
      default: false,
    },
    updateLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['new_geometry'],
  setup(props, { emit }) {
    const popped = ref(false)
    const useContentGuard = contentGuardHandler()
    const useNodeMapMultiple = nodeMapHandlerMultiple()
    const geoLocatorHandler = useGeoLocator()

    const minimap = ref(null)
    const minimapMarkers = ref(null)
    const popoutmap = ref(null)
    const popoutmapMarkers = ref(null)

    const geometryLoaded = computed(() => {
      // Check if any node has a non-null geometry property
      return props.nodes.some((node) => node.geometry !== null)
    })

    function openPopOut() {
      popped.value = true
      if (!popoutmap.value) {
        const { map, markers } = useNodeMapMultiple.renderMap('map_pop', 'popout_map', true, settings)
        popoutmap.value = map
        popoutmapMarkers.value = markers
      }
    }

    function closePopOut() {
      geoLocatorHandler.reset()
      useNodeMapMultiple.removeSearchMarker(popoutmap.value)
      popped.value = false
    }

    const settings = computed(() => {
      return {
        markerType: props.markerType,
        nodes: props.nodes,
      }
    })

    function clickButton() {
      const state = useNodeMapMultiple.editState.value
      if (state === 'neutral_no_location') {
        useNodeMapMultiple.setStateOriginal('neutral_no_location')
        useNodeMapMultiple.setState('editing')
      } else if (state === 'neutral_location') {
        useNodeMapMultiple.setStateOriginal('neutral_location')
        useNodeMapMultiple.setState('editing')
      } else {
        const newlat = useNodeMapMultiple.geometry.value.lat
        const newlng = useNodeMapMultiple.geometry.value.lng
        useNodeMapMultiple.updateMarker(minimapMarkers.value, minimap.value, newlat, newlng)
        useNodeMapMultiple.panMap(minimap.value, newlat, newlng)
        emit('new_geometry', useNodeMapMultiple.geometry.value)
      }
    }

    onMounted(() => {
      const { map, marker } = useNodeMapMultiple.renderMap('map', 'minimap', false, settings)
      minimap.value = map
      minimapMarkers.value = marker

      if (geometryLoaded.value) {
        useNodeMapMultiple.setState('neutral_location')
      }
    })

    function dingen(payload) {
      useNodeMapMultiple.panMap(popoutmap.value, payload[1], payload[0], 19)
      useNodeMapMultiple.addSearchMarker(popoutmap.value, payload[1], payload[0])
    }

    return {
      geometryLoaded,
      useContentGuard,
      popped,
      openPopOut,
      closePopOut,
      useNodeMapMultiple,
      clickButton,
      dingen,
      geoLocatorHandler,
    }
  },
  components: {
    featherIcon,
  },
}
</script>
