import { useRouter } from 'vue-router'
import { ref } from 'vue'
import apiHandler from './apiHandler'
import formHandler from './formHandler'
import loadingHandler from '@/use/loadingHandler'

export default () => {
  const showSelector = ref(false)
  const useApi = apiHandler()
  const switchForm = formHandler()
  const loadHandler = loadingHandler()
  const router = useRouter()

  async function openSelector() {
    // currnet org
    const userResponse = await useApi.request_api('get', 'v1/users', 'me')
    const orgId = userResponse.data.active_organisation.object_id

    // available orgs
    const response = await useApi.request_api('get', 'v1/users/me', 'organisations')
    const data = response.data
    const organisationSelectValues = data.map((org) => ({ label: org.name, value: org.object_id }))

    const formData = {
      organisation: orgId,
    }

    const fields = ref([
      {
        label: 'Organisation',
        key: 'organisation',
        type: 'select',
        options: organisationSelectValues,
      },
    ])

    switchForm.create({
      type: 'edit',
      fields: fields.value,
      data: formData,
    })

    showSelector.value = true
  }

  function closeSelector() {
    showSelector.value = false
  }

  async function switchOrganisation() {
    loadHandler.setLoadingState('switch', true)
    const selectedOrgId = switchForm.data.value.organisation
    await useApi.request_api('patch', 'v1/users', 'me', { active_org_id: selectedOrgId })
    loadHandler.setLoadingState('switch', false)

    if (router.currentRoute.value.name === 'home') {
      window.location.reload()
    } else {
      await router.push({ name: 'home' })
    }

    closeSelector()
  }

  return {
    openSelector,
    closeSelector,
    showSelector,
    switchOrganisation,
    switchForm,
    loadHandler,
  }
}
