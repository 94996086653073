<template>
  <div class="flex flex-col py-2 text-sm">
    <div class="flex" v-for="(item, index) in field.options" :key="index">
      <input
        :checked="data.includes(item.value)"
        @input="dinges"
        type="checkbox"
        :value="item.value"
        class="w-4 h-4 mr-2 bg-gray-100 border-gray-200 rounded accent-sky-700 hover:accent-sky-700 self-center"
      />
      <span class="pr-2">{{ $translate(item.label) }}</span>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import themeHandler from '@/use/themeHandler'

export default {
  props: {
    mode: {
      type: String,
      default: 'Add',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
    field: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['update'],
  setup(props, { emit }) {
    const useTheme = themeHandler()

    function dinges(event) {
      const inputChecked = event.target.checked
      const inputValue = event.target.value

      const dataCopy = JSON.parse(JSON.stringify(props.data))

      if (inputChecked) {
        dataCopy.push(inputValue)
      } else {
        const index = dataCopy.indexOf(inputValue)
        if (index > -1) {
          dataCopy.splice(index, 1)
        }
      }
      emit('update', dataCopy)
    }

    const checkColour = computed(() => {
      if (!props.disabled) {
        return useTheme.getBackgroundColor('primary')
      }
      return useTheme.getBackgroundColor('disabled')
    })

    return {
      checkColour,
      dinges,
    }
  },
}
</script>
