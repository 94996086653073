/* eslint-disable func-names */
/* eslint-disable prefer-rest-params */
import { defineComponent, h, ref, onMounted, onUnmounted, watch, toRefs } from 'vue'
import Highcharts from 'highcharts'
import HighchartsStock from 'highcharts/modules/stock'
import Exporting from 'highcharts/modules/exporting'
import ExportData from 'highcharts/modules/export-data'
import OfflineExporting from 'highcharts/modules/offline-exporting'
import Boost from 'highcharts/modules/boost' // Import the boost module

// Initialize Highcharts modules
HighchartsStock(Highcharts)
Exporting(Highcharts)
ExportData(Highcharts)
OfflineExporting(Highcharts)
Boost(Highcharts) // Initialize the boost module

// Add custom event handler for boost mode with more visible indicator
// Highcharts.wrap(Highcharts.Chart.prototype, 'setBoostEnabled', function (proceed) {
//   proceed.apply(this, Array.prototype.slice.call(arguments, 1))

//   // Get or create boost indicator element
//   let boostIndicator = this.container.querySelector('.boost-indicator')
//   if (!boostIndicator) {
//     boostIndicator = this.renderer
//       .text('', 10, 40) // Moved down a bit for better visibility
//       .addClass('boost-indicator')
//       .css({
//         color: '#FF0000', // Changed to red for visibility
//         fontSize: '14px', // Increased font size
//         fontWeight: 'bold',
//       })
//       .add()
//   }

//   // Update indicator text based on boost state with more detailed info
//   const isBoostActive = this.isChartSeriesBoosting()
//   boostIndicator
//     .attr({
//       text: isBoostActive ? '⚡ BOOST ACTIVE' : 'BOOST INACTIVE',
//     })
//     .css({
//       color: isBoostActive ? '#FF0000' : '#666666',
//     })

//   // Log boost state to console for debugging
//   console.log('Boost state:', isBoostActive)
// })

const vueHighcharts = defineComponent({
  name: 'VueHighchart',
  props: {
    type: {
      type: String,
      default: 'chart',
      validator: (value) => ['chart', 'stockChart'].includes(value),
    },
    options: {
      type: Object,
      required: true,
    },
    redrawOnUpdate: {
      type: Boolean,
      default: true,
    },
    oneToOneUpdate: {
      type: Boolean,
      default: false,
    },
    animateOnUpdate: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const chartRef = ref(null)
    const chart = ref(null)
    const { options } = toRefs(props)

    Highcharts.setOptions({
      lang: {
        decimalPoint: ',',
        thousandsSep: '.',
      },
    })

    if (options.value && Highcharts[props.type]) {
      watch(
        options,
        (newValue) => {
          if (chart.value) {
            chart.value.update(newValue, props.redrawOnUpdate, props.oneToOneOnUpdate, props.animateOnUpdate)
            emit('updated')
          }
        },
        { deep: true },
      )

      onMounted(() => {
        chart.value = Highcharts[props.type](chartRef.value, options.value, () => {
          emit('rendered')
        })
      })

      onUnmounted(() => {
        if (chart.value) chart.value.destroy()
        emit('destroyed')
      })
    } else if (!props.options) {
      console.warn('The "options" parameter is required.')
    } else {
      console.warn(`${props.type} is not a valid highcharts type or has not been imported`)
    }

    return {
      chartRef,
      chart,
    }
  },
  render() {
    return h('div', {
      class: 'vue-highcharts',
      ref: 'chartRef',
    })
  },
})

export default vueHighcharts
