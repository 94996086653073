import { useAuth0 } from '@auth0/auth0-vue'
import { ref } from 'vue'
import globalHandler from '@/use/handlerGlobal'

let permissions = ref([])

export default () => {
  const auth = useAuth0()
  const useGlobal = globalHandler()

  function parseJwt(token) {
    var base64Url = token.split('.')[1]
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join(''),
    )

    return JSON.parse(jsonPayload)
  }

  async function refreshPermissions() {
    const accessToken = await auth.getAccessTokenSilently()
    await useGlobal.getSettings()

    const tokenPermissions = parseJwt(accessToken).permissions
    permissions.value = tokenPermissions
  }

  function canSee(permission) {
    return permissions.value.includes(permission) ? true : false
  }

  return {
    canSee,
    refreshPermissions,
    permissions,
  }
}
