<template>
  <pre>{{ data }}</pre>
</template>

<script>
// import { computed } from 'vue'

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    function prettify(data) {
      const dingen = JSON.stringify(data, null, 2)
      return dingen
    }

    return {
      prettify,
    }
  },
}
</script>
