<template>
  <div>
    <div class="flex flex-row text-xl text-slate-500">
      <featherIcon v-if="icon" class="w-5 h-5 mr-2 my-auto" :icon="icon" />
      <p>{{ $translate(title) }}{{ suffix ? `: ${suffix}` : '' }}</p>
    </div>
    <p class="text-slate-500 mb-4">{{ subtitle }}</p>
  </div>
</template>

<script>
import featherIcon from '@/components/extended/feather/featherIcon.vue'

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    suffix: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: undefined,
    },
  },
  setup() {},
  components: {
    featherIcon,
  },
}
</script>
