<template>
  <div v-if="!loading" class="font-montserrat">
    <component :is="layout">
      <router-view />
    </component>

    <!-- toasts -->
    <base-toast></base-toast>
  </div>

  <!-- loader -->
  <div v-else class="flex items-center justify-center h-screen">
    <baseLoader loading />
  </div>
</template>

<script>
import navigationBar from '@/components/navigationBar.vue'
import LayoutFull from '@/layouts/LayoutFull.vue'
import LayoutHorizontal from '@/layouts/LayoutHorizontal.vue'
import LayoutHorizontalSideBar from '@/layouts/LayoutHorizontalSideBar.vue'
import contentGuardHandler from '@/use/contentGuardHandler'
import { computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import router from '@/router'
import { ref } from 'vue'

export default {
  setup() {
    const route = useRoute()
    const useContentGuard = contentGuardHandler()
    const loading = ref(false)

    const layoutSelector = {
      full: 'layout-full',
      horizontal: 'layout-horizontal',
      horizontal_sidebar: 'layout-horizontal-side-bar',
    }

    const layout = computed(() => {
      return layoutSelector[route.meta.layout]
    })

    onMounted(() => {
      loading.value = true
      useContentGuard.refreshPermissions().then(() => {
        if (useContentGuard.permissions.value.includes('page:admin')) {
          router
            .push({
              name: 'organisations',
            })
            .then(() => {
              loading.value = false
            })
        } else {
          loading.value = false
        }
      })
    })

    return {
      layout,
      loading,
    }
  },
  components: {
    LayoutHorizontal,
    LayoutHorizontalSideBar,
    LayoutFull,
    navigationBar,
  },
}
</script>
