<template>
  <base-card>
    <simplewegIcon class="w-16 h-16 my-auto mx-auto" :icon="useLookup.utilities[type].icon" />
    <!-- loader -->
    <div v-if="loading" class="flex my-2">
      <baseLoader class="mx-auto" :loading="true" />
    </div>

    <!-- value -->
    <div v-else class="mx-auto text-center mt-2 text-l w-full">
      {{ useFilter.filter(value, 'roundDynamic') }} {{ useLookup.utilities[type].unit }}
    </div>

    <!-- label -->
    <div class="mx-auto text-center mt-2 text-l text-gray-500">{{ $translate(label) }}</div>
  </base-card>
</template>

<script>
import simplewegIcon from '@/components/extended/simpleweg/simplewegIcon.vue'
import lookupHandler from '@/use/lookupHandler'
import filterHandler from '@/use/filterHandler'

export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: 'electricity',
    },
    label: {
      type: String,
      default: 'Levering',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const useLookup = lookupHandler()
    const useFilter = filterHandler()

    return {
      useLookup,
      useFilter,
    }
  },
  components: {
    simplewegIcon,
  },
}
</script>
