<template>
  <div class="grid grid-cols-8 font-bold text-xs">
    <div class="col-span-1">Omschrijving</div>
    <div class="col-span-3 text-center">Piek</div>
    <div class="col-span-3 text-center">Dal</div>
    <div class="col-span-1 text-center">Totaal</div>
  </div>
  <div class="grid grid-cols-8 font-bold text-xs">
    <div class="col-span-1"></div>
    <div class="col-span-3 text-center">
      <div class="grid grid-cols-3">
        <div class="text-right">Inkoopsaldo</div>
        <div class="text-center">Tarief</div>
        <div class="text-left">Totaal</div>
      </div>
    </div>
    <div class="col-span-3 text-center">
      <div class="grid grid-cols-3">
        <div class="text-right">Inkoopsaldo</div>
        <div class="text-center">Tarief</div>
        <div class="text-left">Totaal</div>
      </div>
    </div>
    <div class="col-span-1 text-center"></div>
  </div>
</template>
