import { ref } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import { useAuth0 } from '@auth0/auth0-vue'

const settings = ref({})

export default () => {
  const loadHandler = loadingHandler()
  const useApi = apiHandler()
  const auth = useAuth0()

  function getSettings() {
    // getting settings first
    loadHandler.setLoadingState('get_settings', true)
    useApi
      .request_api('get', 'v1/organisation', 'settings')
      .then((response) => {
        const responseData = response.data
        settings.value = responseData.settings
        loadHandler.setLoadingState('get_settings', false)
      })
      .catch(() => {
        auth.logout({ returnTo: window.location.origin })
        loadHandler.setLoadingState('get_settings', false)
      })
  }

  return {
    getSettings,
    settings,
  }
}
