<template>
  <div class="grid grid-cols-5 text-xs">
    <div class="col-span-1">
      <div class="flex flex-row">
        <span class="mr-2 font-bold">{{ useFilter.roundDynamic(summary.usage_production_balance_total) }} kWh</span>
        <tooltip-saldo v-if="showSalderingButton" :data="summary" :type="type" />
      </div>
    </div>
    <div class="col-span-1 text-center">
      <div class="flex flex-row justify-center">
        <span class="mr-2 font-bold">{{ useFilter.roundDynamicCurrency(summary.tax_totals.eb_commercial) }}</span>
        <tooltip-EB :data="summary" type="Electricity" />
      </div>
    </div>
    <div class="col-span-1 text-center">
      <div class="flex flex-row justify-center">
        <span class="mr-2 font-bold">{{ useFilter.roundDynamicCurrency(summary.tax_totals.ode_commercial) }}</span>
        <tooltip-EB :data="summary" type="Electricity" />
      </div>
    </div>
    <div class="col-span-1 text-center">
      <div class="flex flex-row justify-center" v-if="summary.residential">
        <span class="mr-2 font-bold">{{ useFilter.roundDynamicCurrency(summary.tax_totals.tax_credit_total) }}</span>
        <tooltipHeffingskorting :data="summary" />
      </div>
      <div v-else>{{ $translate('N/A') }}</div>
    </div>
    <div class="col-span-1 text-center font-bold">
      {{ useFilter.roundDynamicCurrency(summary.tax_totals.tax_total_electricity) }}
    </div>
  </div>
</template>

<script>
import filterHandler from '@/use/filterHandler'
import tooltipSaldo from '@/views/costs/tax/tooltipSaldo.vue'
import tooltipEB from '@/views/costs/tax/tooltipEB.vue'
import tooltipHeffingskorting from '@/views/costs/tax/tooltipHeffingskorting.vue'
import { computed } from 'vue'

export default {
  props: {
    summary: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const useFilter = filterHandler()

    const showSalderingButton = computed(() => {
      if (props.type === 'cluster') {
        return true
      }

      if (props.summary.size === 'Small') {
        return true
      }

      return false
    })

    return {
      useFilter,
      showSalderingButton,
    }
  },
  components: {
    tooltipSaldo,
    tooltipEB,
    tooltipHeffingskorting,
  },
}
</script>
