<template>
  <!-- Buy in -->
  <p class="my-2 font-semibold">{{ $translate('Buy-in') }}</p>
  <div class="flex flex-row justify-between text-sm" v-for="(record, index) in usageData" :key="index">
    <span class="ml-2 mt-1">{{ $translate(record.label) }}</span>
    <span>{{ useFilter.filter(record.value, 'roundDynamicCurrency') }}</span>
  </div>

  <!-- Tax -->
  <p class="my-2 font-semibold">{{ $translate('Taxes') }}</p>
  <div class="flex flex-row justify-between text-sm" v-for="(record, index) in taxData" :key="index">
    <span class="ml-2 mt-1">{{ $translate(record.label) }}</span>
    <span>{{ useFilter.filter(record.value, 'roundDynamicCurrency') }}</span>
  </div>

  <!-- VAT -->
  <p class="my-2 font-semibold">{{ $translate('VAT') }}</p>
  <div class="flex flex-row justify-between text-sm" v-for="(record, index) in vatData" :key="index">
    <span v-if="record.label !== 'Subtotal'" class="ml-2 mt-1">{{
      `${$translate(record.label)} ${$translate(record.utility)} (${record.vat}%)`
    }}</span>
    <span v-else class="ml-2 mt-1">{{ $translate(record.label) }}</span>
    <span>{{ useFilter.filter(record.value, 'roundDynamicCurrency') }}</span>
  </div>

  <!-- Total -->
  <p class="my-2 font-semibold">{{ $translate('Total') }}</p>
  <div class="flex flex-row justify-between text-sm" v-for="(record, index) in totalData" :key="index">
    <span class="ml-2 mt-1">{{ $translate(record.label) }}</span>
    <span>{{ useFilter.filter(record.value, 'roundDynamicCurrency') }}</span>
  </div>
</template>

<script>
import filterHandler from '@/use/filterHandler'
import { computed } from 'vue'

export default {
  props: {
    handler: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const utilities = ['Electricity', 'Gas', 'Water', 'Heat']
    const useFilter = filterHandler()

    const usageData = computed(() => {
      if (props.handler.costData.value.contracts_total) {
        const usageDataHandler = props.handler.costData.value.contracts_total
        let payload = []

        utilities.forEach((utility) => {
          if (utility in usageDataHandler) {
            payload.push({
              value: usageDataHandler[utility],
              label: utility,
            })
          }
        })

        payload.push({
          value: usageDataHandler.total,
          label: 'Subtotal',
        })

        return payload
      }
      return [
        {
          value: 0,
          label: 'Subtotal',
        },
      ]
    })

    const taxData = computed(() => {
      if (props.handler.taxData.value.totals) {
        const taxDataHandler = props.handler.taxData.value.totals.total
        let payload = []

        // check if tax credit
        if ('tax_credit_total' in taxDataHandler) {
          payload.push({ label: 'Electricity', value: taxDataHandler.tax_total_electricity_pre_tax_credit })
          payload.push({ label: 'Tax credit', value: taxDataHandler.tax_credit_total })
        } else if ('tax_total_electricity' in taxDataHandler) {
          payload.push({ label: 'Electricity', value: taxDataHandler.tax_total_electricity })
        }

        if ('tax_total_gas' in taxDataHandler) {
          payload.push({ label: 'Gas', value: taxDataHandler.tax_total_gas })
        }

        if ('bol' in taxDataHandler) {
          payload.push({ label: 'Water', value: taxDataHandler.bol })
        }

        payload.push({ label: 'Subtotal', value: taxDataHandler.tax_total_total })

        return payload
      }
      return [{ label: 'Subtotal', value: 0 }]
    })

    const vatData = computed(() => {
      let payload = []
      let subtotal = 0

      if (props.handler.costData.value.contracts_total) {
        const usageData = props.handler.costData.value.contracts_total

        if ('Electricity' in usageData) {
          payload.push({
            value: usageData.Electricity_vat,
            label: 'Buy-in',
            vat: usageData.Electricity_vat_rate * 100,
            utility: 'Electricity',
          })
        }

        if ('Gas' in usageData) {
          payload.push({
            value: usageData.Gas_vat,
            label: 'Buy-in',
            vat: usageData.Gas_vat_rate * 100,
            utility: 'Gas',
          })
        }

        if ('Water' in usageData) {
          payload.push({
            value: usageData.Water_vat,
            label: 'Buy-in',
            vat: usageData.Water_vat_rate * 100,
            utility: 'Water',
          })
        }

        if ('Heat' in usageData) {
          payload.push({
            value: usageData.Heat_vat,
            label: 'Buy-in',
            vat: usageData.Heat_vat_rate * 100,
            utility: 'Heat',
          })
        }

        subtotal += usageData.total_vat
      }

      if (props.handler.taxData.value.totals) {
        const taxData = props.handler.taxData.value.totals.total

        if ('tax_total_vat_electricity' in taxData) {
          payload.push({
            value: taxData.tax_total_vat_electricity,
            label: 'Tax',
            vat: taxData.tax_total_vat_electricity_rate * 100,
            utility: 'Electricity',
          })
        }

        if ('tax_total_vat_gas' in taxData) {
          payload.push({
            value: taxData.tax_total_vat_gas,
            label: 'Tax',
            vat: taxData.tax_total_vat_gas_rate * 100,
            utility: 'Gas',
          })
        }

        if ('tax_total_vat_water' in taxData) {
          payload.push({
            value: taxData.tax_total_vat_water,
            label: 'Tax',
            vat: taxData.tax_total_vat_water_rate * 100,
            utility: 'Water',
          })
        }

        subtotal += taxData.tax_total_total_vat
      }

      payload.push({ label: 'Subtotal', value: subtotal })

      if (payload.length === 0) {
        payload.push({ label: 'Subtotal', value: 0 })
      }

      return payload
    })

    const totalData = computed(() => {
      let payload = []
      let subtotalCalc = 0
      let subtotalVATCalc = 0

      if (props.handler.costData.value.contracts_total) {
        const usageData = props.handler.costData.value.contracts_total
        subtotalCalc += usageData.total
        subtotalVATCalc += usageData.total_vat
      }

      if (props.handler.taxData.value.totals) {
        const taxData = props.handler.taxData.value.totals.total
        subtotalCalc += taxData.tax_total_total
        subtotalVATCalc += taxData.tax_total_total_vat
      }

      payload.push({ label: 'VAT excluded', value: subtotalCalc })
      payload.push({ label: 'VAT included', value: subtotalCalc + subtotalVATCalc })
      return payload
    })

    return {
      usageData,
      useFilter,
      taxData,
      vatData,
      totalData,
    }
  },
}
</script>
