<template>
  <form class="w-full" @submit.prevent @keydown.enter.prevent>
    <div v-for="(field, index) in filteredFieldsDependent" :key="index" class="flex flex-row my-1">
      <div class="basis-2/5">
        <label
          class="block text-gray-500 md:text-right mb-1 md:mb-0 pr-4 py-2 px-4 text-sm"
          :for="field.key"
          v-if="field.type !== 'subtitle'"
        >
          {{ $translate(field.label) }}
        </label>
      </div>
      <div class="basis-3/5">
        <baseField :disabled="disabled" :handler="handler" :field="field" />
      </div>
    </div>
  </form>
</template>

<script>
import { computed } from 'vue'

export default {
  props: {
    handler: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const filteredFieldsDependent = computed(() => {
      return props.handler.filteredFields.value.filter((field) => {
        if (dependentCheck(field)) {
          return true
        }
        return false
      })
    })

    function dependentCheck(field) {
      const checkValues = field.dependent_show_values
      const dependentShowFieldKey = field.dependent_show_field_key
      const dependentShowFieldType = field.dependent_show_field_type

      if (checkValues) {
        if (dependentShowFieldType === 'select') {
          if (
            props.handler.data.value[dependentShowFieldKey] &&
            checkValues.includes(props.handler.data.value[dependentShowFieldKey].value)
          ) {
            return true
          }
        }
        if (
          props.handler.data.value[dependentShowFieldKey] &&
          checkValues.includes(props.handler.data.value[dependentShowFieldKey])
        ) {
          return true
        }
        return false
      }

      if (dependentShowFieldKey) {
        if (dependentShowFieldType === 'labels') {
          const dependentData = props.handler.data.value[dependentShowFieldKey]
          if (dependentData.length > 0) {
            return true
          }
        }
        return false
      }
      return true
    }

    return {
      filteredFieldsDependent,
    }
  },
}
</script>
