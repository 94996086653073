<template>
  <div class="p-6">
    <base-page-title :title="meta.title" :icon="meta.titleIcon"></base-page-title>

    <baseTable
      :fields="useViewTags.tagsFields.value"
      :records="useViewTags.records.value"
      :records-per-page="25"
      :add-button="false"
      :edit-button="false"
      :delete-button="false"
      :clickAble="false"
      :clickCallback="tableAction"
      :loading="useViewTags.loadHandler.somethingLoading.value"
      :hover-effect="true"
      leadIcon="TagIcon"
      :sortable="true"
    />
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { computed, onMounted } from 'vue'
import viewHandlerTags from '@/use/viewHandlerTags'
import router from '@/router'
import contentGuardHandler from '@/use/contentGuardHandler'

export default {
  setup() {
    const route = useRoute()
    const useViewTags = viewHandlerTags()
    const useContentGuard = contentGuardHandler()

    function tableAction(payload) {
      router.push({
        name: 'cluster_details',
        params: { objectId: payload.object_id },
      })
    }

    onMounted(() => {
      useViewTags.listRecords()
    })

    const meta = computed(() => route.meta)
    return {
      meta,
      useViewTags,
      tableAction,
      useContentGuard,
    }
  },
}
</script>
