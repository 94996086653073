import { ref, computed, watch } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import timeHandler from '@/use/timeHandler'
import chartOptionshandler from '@/use/chartOptionsHandler'
import formHandler from '@/use/formHandler'

export default () => {
  const useChartOptions = chartOptionshandler()
  const useTime = timeHandler()
  const useApi = apiHandler()
  const loadHandler = loadingHandler()
  const interval = useTime.interval
  const range = useTime.range
  const editForm = formHandler()

  let cluster = ref({})
  let chart_data_usage = ref({})
  let chart_data_production_generation = ref({})
  let chart_data_production_return = ref({})
  let unassigned_nodes = ref([])
  let unassigned_nodes_selected = ref([])
  let showAdd = ref(false)
  let showAddNodes = ref(false)
  let showEdit = ref(false)
  let originalTags = []

  const chart_options_usage = computed(() => {
    if (cluster.value) {
      return useChartOptions.getOptions(cluster.value.medium, chart_data_usage.value, 'Usage')
    }
    return useChartOptions.getOptions(cluster.value.medium, {})
  })

  const chart_options_generation = computed(() => {
    if (cluster.value) {
      return useChartOptions.getOptions(cluster.value.medium, chart_data_production_generation.value, 'Generation')
    }
    return useChartOptions.getOptions(cluster.value.medium, {})
  })

  const chart_options_return = computed(() => {
    if (cluster.value) {
      return useChartOptions.getOptions(cluster.value.medium, chart_data_production_return.value, 'Return')
    }
    return useChartOptions.getOptions(cluster.value.medium, {})
  })

  function getDetails(payload) {
    loadHandler.setLoadingState('get_details', true)
    useApi.request_api('get', 'v1', 'clusters/' + payload.object_id).then((response) => {
      const data = response.data
      cluster.value = data
      if (data.nodes.length === 0) {
        getUnassignedCluster()
        loadHandler.setLoadingState('get_details', false)
        return
      }
      let node_ids_parameters = response.data.nodes.map((node) => 'ids=' + node.object_id).join('&')
      useApi
        .request_api(
          'get',
          'v1',
          'nodes/?fields=description,discount,type,place,residential,medium,provider_identifier,object_id,geometry&' +
            node_ids_parameters,
          payload.object_id,
        )
        .then((response) => {
          data.nodes = response.data
          cluster.value.medium = data.nodes[0].medium

          cluster.value.nodes = data.nodes
          cluster.value.nodes.forEach((node) => {
            node.type_name = node.type ? node.type.name : 'Onbekend'
          })
          cluster.value.nodes_count = data.nodes.length
          cluster.value.residential = data.nodes[0].residential
          cluster.value.discount = data.nodes[0].discount

          // check if cluster has nodes, else a summary is not needed
          if (data.nodes.length > 0) {
            const ids = data.nodes.map((node) => node.object_id)
            const payload = {
              ids: ids,
              interval: interval.value,
              start_date: useTime.startDate.value.getTime(),
              end_date: useTime.endDate.value.getTime(),
            }

            getSummaryNew(payload)
          }

          getUnassignedCluster()

          loadHandler.setLoadingState('get_details', false)
        })
    })
  }

  function getSummaryNew(payload) {
    loadHandler.setLoadingState('get_summary', true)

    // parse payload to query string
    const queryString = useApi.toQueryString(payload)

    useApi.request_api('get', 'v1', 'nodes/data/summary?' + queryString).then((response) => {
      const data = response.data
      const dataNode = data

      const { usage, production } = dataNode

      // parase timestamps
      const formattedUsage = usage.map(([datetime, value]) => {
        const formattedDate = useTime.labelSelector(payload.interval, new Date(datetime))
        return [formattedDate, value]
      })

      const formattedProduction = production.map(([datetime, value]) => {
        const formattedDate = useTime.labelSelector(payload.interval, new Date(datetime))
        return [formattedDate, value]
      })

      chart_data_usage.value = formattedUsage
      chart_data_production_return.value = formattedProduction

      loadHandler.setLoadingState('get_summary', false)
    })
  }

  function getUnassignedCluster() {
    loadHandler.setLoadingState('get_unassigned_cluster', true)
    let request_parameters = ''

    if (cluster.value.medium != null) {
      request_parameters += '?mediums=' + cluster.value.medium
    } else {
      request_parameters += '?mediums=Electricity,Gas' // Default to Electricity and Gas
    }

    if (cluster.value.residential != null) {
      request_parameters += '&residential=' + cluster.value.residential
    }
    if (cluster.value.discount != null) {
      request_parameters += '&discount=' + cluster.value.discount
    }

    useApi.request_api('get', 'v1', 'nodes/unassigned/clusters' + request_parameters).then((response) => {
      const data = response.data
      if (data.length === 0) {
        unassigned_nodes.value = []
        loadHandler.setLoadingState('get_unassigned_cluster', false)
        return
      }
      let node_ids_parameters = response.data.map((node) => 'ids=' + node).join('&')
      useApi
        .request_api(
          'get',
          'v1',
          'nodes/?fields=description,discount,type,place,residential,medium,provider_identifier,object_id,geometry&' +
            node_ids_parameters,
        )
        .then((response) => {
          unassigned_nodes.value = response.data
          unassigned_nodes.value.forEach((node) => {
            node.type_name = node.type?.name
          })
        })

      loadHandler.setLoadingState('get_unassigned_cluster', false)
    })
  }

  const clusterFields = ref([
    {
      label: 'Name',
      key: 'name',
      type: 'string',
    },
    {
      label: 'Creation date',
      key: 'date_created',
      type: 'string',
      filter: 'dateFromEpochDay',
      edit: false,
    },
    {
      label: 'Main type',
      key: 'medium',
      type: 'string',
      component: 'pillMainType',
      edit: false,
    },
    {
      label: 'Nodes',
      key: 'nodes_count',
      type: 'string',
      edit: false,
    },
    {
      label: 'Comment',
      key: 'comment',
      type: 'string',
      table: false,
    },
    {
      label: 'Tags',
      key: 'tags',
      type: 'tags',
      table: false,
      component: 'pillLabels',
    },
  ])

  const nodeFields = ref([
    {
      label: 'Description',
      key: 'description',
      type: 'string',
      searchable: true,
    },
    {
      label: 'Identification',
      key: 'provider_identifier',
      type: 'string',
      add: false,
      edit: false,
      searchable: true,
    },
    {
      label: 'Main type',
      key: 'medium',
      type: 'string',
      component: 'pillMainType',
      searchable: true,
    },
    {
      label: 'Type',
      key: 'type_name',
      type: 'string',
      searchable: true,
    },
    {
      label: 'Size',
      key: 'size',
      type: 'string',
      table: false,
    },
    {
      label: 'Zip',
      key: 'zip',
      type: 'string',
      table: false,
      demodata: '1234AB',
    },
    {
      label: 'Place',
      key: 'place',
      type: 'string',
      searchable: true,
      demodata: 'Demoplaats',
    },
    {
      label: 'Residential',
      key: 'residential',
      type: 'string',
      component: 'pillTrueFalse',
    },
    {
      label: 'Discount',
      key: 'discount',
      type: 'string',
      component: 'pillTrueFalse',
    },
  ])

  watch([() => range.value, () => useTime.startDate.value, () => useTime.endDate.value], async () => {
    const ids = cluster.value.nodes.map((node) => node.object_id)
    const payload = {
      ids: ids,
      interval: interval.value,
      start_date: useTime.startDate.value.getTime(),
      end_date: useTime.endDate.value.getTime(),
    }

    getSummaryNew(payload)
  })

  function openEdit() {
    originalTags = JSON.parse(JSON.stringify(cluster.value.tags))

    editForm.create({
      type: 'edit',
      fields: clusterFields.value,
      data: cluster.value,
    })
    showEdit.value = true
  }

  function closeEdit() {
    showEdit.value = false
  }

  async function edit() {
    loadHandler.setLoadingState('edit_cluster', true)
    const payload = editForm.getData()

    if ('tags' in payload) {
      const newTags = payload.tags.map((tag) => tag.name)

      // Tags to add
      const tagsToAdd = newTags.filter((tag) => !originalTags.map((tag) => tag.name).includes(tag))

      // Tags to remove
      const tagsToRemove = originalTags.map((tag) => tag.name).filter((tag) => !newTags.includes(tag))

      // Create new tags if they don't exist
      if (tagsToAdd.length > 0) {
        loadHandler.setLoadingState('add_tags', true)
        await useApi.request_api('post', 'v1', 'tags', { names: tagsToAdd }).then(() => {
          loadHandler.setLoadingState('add_tags', false)
        })

        // Assign new tags to the node
        const tagAssignmentPayload = {
          names: tagsToAdd,
        }

        loadHandler.setLoadingState('assign_tags', true)
        await useApi.request_api('post', 'v1', `clusters/${payload.object_id}/tags`, tagAssignmentPayload).then(() => {
          loadHandler.setLoadingState('assign_tags', false)
        })
      }

      // Unassign tags from the node
      if (tagsToRemove.length > 0) {
        const tagUnassignmentPayload = {
          names: tagsToRemove,
        }

        const queryString = useApi.toQueryString(tagUnassignmentPayload)
        const url = `clusters/${payload.object_id}/tags?${queryString}`

        loadHandler.setLoadingState('unassign_tags', true)
        await useApi.request_api('delete', 'v1', url).then(() => {
          loadHandler.setLoadingState('unassign_tags', false)
        })
      }

      delete payload['tags']
    }

    useApi
      .request_api('patch', 'v1', `clusters/${payload.object_id}`, payload)
      .then(() => {
        const getDetailsPayload = {
          object_id: payload.object_id,
        }

        getDetails(getDetailsPayload)
        closeEdit()
        loadHandler.setLoadingState('edit_cluster', false)
      })
      .catch(() => {
        loadHandler.setLoadingState('edit_cluster', false)
      })
  }

  function openAddNodes() {
    showAddNodes.value = true
  }

  function closeAddNodes() {
    showAddNodes.value = false
  }

  function assignNodes() {
    const payload = {
      ids: Object.keys(unassigned_nodes_selected.value),
    }

    loadHandler.setLoadingState('assign_to_cluster', true)
    useApi
      .request_api('patch', 'v1', 'clusters/' + cluster.value.object_id + '/nodes/add', payload)
      .then(() => {
        closeAddNodes()
        loadHandler.setLoadingState('assign_to_cluster', false)

        const getDetailsPayload = {
          object_id: cluster.value.object_id,
        }

        getDetails(getDetailsPayload)
      })
      .catch(() => {
        loadHandler.setLoadingState('assign_to_cluster', false)
      })
  }

  function unAssignNode(nodeId) {
    const payload = {
      ids: [nodeId],
    }

    loadHandler.setLoadingState('unassign_node', true)
    useApi
      .request_api('patch', 'v1', 'clusters/' + cluster.value.object_id + '/nodes/remove', payload)
      .then((response) => {
        loadHandler.setLoadingState('unassign_node', false)
        const getDetailsPayload = {
          object_id: response.data,
        }
        getDetails(getDetailsPayload)
      })
  }

  function setSelectedUnassignedNodes(selection) {
    unassigned_nodes_selected.value = selection.value
  }

  const mixedState = computed(() => {
    const mediums = []
    const discounts = []
    const residentials = []
    const node_keys = Object.keys(unassigned_nodes_selected.value)

    node_keys.forEach((node_key) => {
      const node = unassigned_nodes_selected.value[node_key]
      const { medium, discount, residential } = node
      mediums.push(medium)
      discounts.push(discount)

      if (medium === 'Electricity') {
        residentials.push(residential)
      }
    })

    const mainTypeCheck = mediums.every((val, i, arr) => val === arr[0])
    const discountCheck = discounts.every((val, i, arr) => val === arr[0])
    const residentialCheck = residentials.every((val, i, arr) => val === arr[0])
    const conclusionArray = [mainTypeCheck, discountCheck, residentialCheck]

    const conclusion = {
      issues: {
        unit: {
          status: mainTypeCheck,
          message: 'oneTypeCluster',
        },
        discount: {
          status: discountCheck,
          message: 'oneTypeDiscount',
        },
        residential: {
          status: residentialCheck,
          message: 'oneTypeResidential',
        },
      },
      conclusion: !conclusionArray.includes(false),
    }

    return conclusion
  })

  return {
    cluster,
    loadHandler,
    clusterFields,
    nodeFields,
    showEdit,
    showAdd,
    getDetails,
    chart_data_usage,
    chart_data_production_generation,
    chart_data_production_return,
    chart_options_usage,
    chart_options_generation,
    chart_options_return,
    openEdit,
    closeEdit,
    editForm,
    edit,
    unassigned_nodes,
    unassigned_nodes_selected,
    showAddNodes,
    openAddNodes,
    closeAddNodes,
    assignNodes,
    setSelectedUnassignedNodes,
    unAssignNode,
    mixedState,
  }
}
