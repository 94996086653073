<template>
  <div>
    <base-button @click="add" class="mb-4" :size="'small'">+</base-button>
    <table v-if="formattedData.length" class="bg-white shadow-md rounded-lg overflow-hidden h-full">
      <thead class="bg-gray-50">
        <tr>
          <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
          <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Temperature
            <span v-if="!temperatureDataLoaded" class="text-xs text-gray-400">(Loading...)</span>
          </th>
          <th
            v-for="scenario in scenarios"
            :key="scenario.title"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            @click="columnClick(scenario)"
          >
            {{ scenario.title }}
          </th>
        </tr>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200 overflow-auto">
        <tr v-for="(row, index) in formattedData" :key="index" :class="{ 'bg-gray-50': index % 2 }">
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ formatDate(row.date) }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {{ formatValue(row.temperature) }}
          </td>
          <td
            v-for="scenario in scenarios"
            :key="`${scenario.title}-${index}`"
            class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
          >
            {{ formatValue(row[scenario.title]) }}
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else class="text-center text-gray-500 p-4">No data available to compare.</div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, watch } from 'vue'

export default defineComponent({
  name: 'CompareMain',
  props: {
    scenarios: {
      type: Array,
      default: () => [],
    },
    temperatureData: {
      type: Object,
      default: () => ({}),
    },
    interval: {
      type: String,
      default: 'year',
      validator: (value) => ['day', 'month', 'year'].includes(value),
    },
  },
  emits: ['removeScenario', 'openAdd'],
  setup(props, { emit }) {
    const temperatureDataLoaded = ref(false)
    const localTemperatureData = ref({})

    const formatDate = (dateString) => {
      if (!dateString) return 'N/A'
      const date = new Date(dateString)
      switch (props.interval) {
        case 'day':
          return date.toLocaleDateString('nl-NL', { year: 'numeric', month: 'short', day: 'numeric' })
        case 'month':
          return date.toLocaleDateString('nl-NL', { year: 'numeric', month: 'long' })
        case 'year':
          return date.getFullYear().toString()
      }
    }

    const formatValue = (value) => {
      if (value === undefined || value === null) return '-'
      return new Intl.NumberFormat('nl-NL', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(value)
    }

    const getTemperatureValue = (data, date) => {
      switch (props.interval) {
        case 'day':
          return data[date]
        case 'month':
        case 'year':
          return data[date]
      }
    }

    const formattedData = computed(() => {
      const dataByInterval = new Map()

      // Add scenario data to the map first
      props.scenarios.forEach((scenario) => {
        scenario.data.forEach(([date, value]) => {
          const existingData = dataByInterval.get(date) || { date }
          existingData[scenario.title] = value
          dataByInterval.set(date, existingData)
        })
      })

      // Add temperature data to the map if available
      if (temperatureDataLoaded.value) {
        Object.keys(localTemperatureData.value).forEach((date) => {
          const temp = getTemperatureValue(localTemperatureData.value, date)

          if (temp !== undefined) {
            const existingData = dataByInterval.get(date) || { date }
            existingData.temperature = temp
            dataByInterval.set(date, existingData)
          }
        })
      }

      // Convert map to array and sort by date
      return Array.from(dataByInterval.values()).sort((a, b) => new Date(a.date) - new Date(b.date))
    })

    function columnClick(scenario) {
      emit('removeScenario', scenario.id)
    }

    function add() {
      emit('openAdd')
    }

    watch(
      () => props.temperatureData,
      (newValue) => {
        if (Object.keys(newValue).length > 0) {
          localTemperatureData.value = newValue
          temperatureDataLoaded.value = true
        }
      },
      { immediate: true, deep: true },
    )

    return {
      formatDate,
      formatValue,
      formattedData,
      columnClick,
      temperatureDataLoaded,
      add,
    }
  },
})
</script>
