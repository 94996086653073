<template>
  <div class="p-6">
    <div class="flex flex-row justify-between">
      <!-- <base-page-title :title="pageTitle" :suffix="pageSuffix" :icon="'PercentIcon'"></base-page-title> -->
    </div>
    <div v-if="isLoading">Loading scenarios...</div>
    <div v-else>
      <div class="flex flex-row">
        <div class="flex flex-col" v-if="allScenariosLoaded">
          <base-tabs :handler="tabsInterval" @changed="onIntervalChange"> </base-tabs>
          <base-tabs :handler="tabs" @changed="onTabChange">
            <template v-slot:panel-electricity>
              <compare-main
                :scenarios="structuredScenarioData"
                @removeScenario="removeScenario"
                :temperatureData="temperatureData"
                :interval="interval"
              />
            </template>
            <template v-slot:panel-gas>
              <div>
                <compare-main
                  :scenarios="structuredScenarioDataGas"
                  @removeScenario="removeScenario"
                  @openAdd="openAdd"
                  :temperatureData="temperatureData"
                  :interval="interval"
                /></div
            ></template>

            <template v-slot:panel-heat>Heat content</template>
            <template v-slot:panel-water>Water content</template>
          </base-tabs>
          <!-- {{ scenarioHandler.globalWeatherData.value[interval] }}
        {{ interval }} -->
        </div>

        <div class="w-full" v-if="interval === 'day'">
          <vue-highcharts
            class="h-[700px] w-full p-4"
            type="chart"
            :options="scenarioHandler.scatterplotOptions.value.gas"
            :redrawOnUpdate="true"
            :oneToOneUpdate="false"
            :animateOnUpdate="true"
          />

          <div>
            <comparisonTable
              :scenariosData="structuredScenarioDataGas"
              :temperatureData="temperatureData"
              :energyType="'gas'"
            />
            {{ structuredScenarioDataGas }}
            <p />
            <p />
            henk
            <p />
            <p />
            {{ temperatureData }}
          </div>
          <!-- {{ scenarioHandler.scatterplotOptions.value.gas }} -->
        </div>
      </div>
    </div>

    <!-- add modal -->
    <base-modal v-if="showAddModal" @close="closeAddModal" :title="`${$translate('Add')}`">
      <baseTable
        :fields="scenarioFields"
        :records="scenarios"
        :records-per-page="15"
        :add-button="false"
        :edit-button="false"
        :delete-button="false"
        :selectable="true"
        :loading="false"
        :hover-effect="true"
        leadIcon="CircleIcon"
        :search="true"
        @selection="scenarioSelection"
        :sortable="true"
      />
      <template v-slot:buttons>
        <baseButton @action="add()" class="mr-2" :loading="false">{{ $translate('Save') }}</baseButton>
      </template>
    </base-modal>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue'
import viewHandlerAmounts from '@/use/viewHandlerComparison_aangepast'
import useTabs from '@/use/tabHandler'
import compareMain from './compareMain.vue'
import VueHighcharts from '@/use/highCharts'
import apiHandler from '@/use/apiHandler'
import comparisonTable from './comparisonTable'

export default {
  name: 'CompareComponent',
  components: {
    compareMain,
    VueHighcharts,
    comparisonTable,
  },
  setup() {
    const tabs = useTabs()
    const tabsInterval = useTabs()
    const scenarioIds = ref(['8c454958-d60f-4dd6-b671-eac30edf2f13', '641bcb5e-15c0-4ca4-96bd-2c3b87f18230'])

    const isLoading = ref(true)
    const interval = ref('year')
    const scenarioHandler = viewHandlerAmounts()
    const useApi = apiHandler()

    const showAddModal = ref(false)

    const scenarios = ref([])
    const scenarioidsSelected = ref([])

    const scenarioFields = ref([
      {
        label: 'Description',
        key: 'description',
        type: 'string',
        searchable: true,
      },
    ])
    const tabOptions = computed(() => ({
      tabs: [
        { id: 'electricity', label: 'Electricity', active: true },
        { id: 'gas', label: 'Gas', active: true },
        { id: 'heat', label: 'Heat', active: true },
        { id: 'water', label: 'Water', active: true },
      ],
    }))

    const tabOptionsInterval = computed(() => ({
      tabs: [
        { id: 'day', label: 'day', active: true },
        { id: 'month', label: 'Month', active: true },
        { id: 'year', label: 'Year', active: true },
      ],
    }))

    function onTabChange() {
      console.log('Tab changed')
    }

    async function loadScenarios() {
      isLoading.value = true
      try {
        await Promise.all(scenarioIds.value.map((id) => scenarioHandler.getDetails(id, interval.value)))
        scenarioHandler.fetchWeatherDataForAllScenarios()
      } catch (error) {
        console.error('Error loading scenarios:', error)
      } finally {
        isLoading.value = false
      }
    }

    const allScenariosLoaded = computed(() => {
      return scenarioIds.value.every((id) => scenarioHandler.scenarioLoadingStatus.value[id] === 'loaded')
    })

    const structuredScenarioData = computed(() => {
      return scenarioIds.value.map((id) => {
        const scenarioData = scenarioHandler.scenarioData.value[id]
        const electricityData = scenarioData?.dataElectricity?.Usage || []
        return {
          id,
          title: scenarioHandler.scenarios.value[id]?.data?.description || `Scenario ${id}`,
          data: electricityData,
        }
      })
    })

    const structuredScenarioDataGas = computed(() => {
      return scenarioIds.value.map((id) => {
        const scenarioData = scenarioHandler.scenarioData.value[id]
        const gasData = scenarioData?.dataGas?.Usage || []
        return {
          id,
          title: scenarioHandler.scenarios.value[id]?.data?.description || `Scenario ${id}`,
          data: gasData,
        }
      })
    })

    function onIntervalChange(value) {
      interval.value = value.target
      loadScenarios()
    }

    function removeScenario(id) {
      scenarioIds.value = scenarioIds.value.filter((scenarioId) => scenarioId !== id)
    }

    const temperatureData = computed(() => {
      return scenarioHandler.globalWeatherData.value[interval.value]
      // return Object.entries(data).map(([year, temp]) => [`${year}-01-01`, temp])
    })

    // watch(structuredScenarioData, (newValue) => {
    //   console.log('structuredScenarioData updated:', newValue)
    // })

    function openAdd() {
      showAddModal.value = true
      console.log('Open add')

      // scenarioidsSelected.value = scenarioIds.value

      useApi.request_api('get', 'v1', 'scenarios/amounts').then((response) => {
        scenarios.value = response.data
        console.log(scenarios.value)
      })
    }

    function closeAddModal() {
      showAddModal.value = false
      console.log('Close add')
    }

    function add() {
      console.log('Add')
      closeAddModal()
      scenarioIds.value = scenarioidsSelected.value
    }

    function scenarioSelection(selected) {
      scenarioidsSelected.value = Object.keys(selected.value)
    }

    watch(scenarioIds, () => {
      loadScenarios()
    })

    onMounted(async () => {
      tabs.create(tabOptions.value, 'gas')
      tabsInterval.create(tabOptionsInterval.value, 'day')
      await loadScenarios()
    })

    return {
      tabs,
      onTabChange,
      structuredScenarioData,
      isLoading,
      allScenariosLoaded,
      onIntervalChange,
      tabsInterval,
      removeScenario,
      structuredScenarioDataGas,
      scenarioHandler,
      interval,
      temperatureData,
      openAdd,
      showAddModal,
      add,
      closeAddModal,
      scenarioFields,
      scenarioSelection,
      scenarios,
    }
  },
}
</script>
