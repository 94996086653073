<template>
  <div class="p-6">
    <base-page-title :title="meta.title" :icon="meta.titleIcon"></base-page-title>

    <baseTable
      :fields="useViewScenarios.tagsFields.value"
      :records="useViewScenarios.records.value"
      :records-per-page="25"
      :add-button="useContentGuard.canSee('object:write')"
      :edit-button="false"
      :delete-button="useContentGuard.canSee('object:write')"
      :clickAble="true"
      :clickCallback="tableAction"
      :loading="useViewScenarios.loadHandler.somethingLoading.value"
      :hover-effect="true"
      leadIcon="DollarSignIcon"
      :sortable="true"
      @add="useViewScenarios.openAdd()"
      @delete="useViewScenarios.deleteScenario"
    />

    <!-- add modal -->
    <base-modal
      v-if="useViewScenarios.showAdd.value"
      @close="useViewScenarios.closeAdd()"
      :title="`${$translate('Add')}`"
    >
      <baseForm :handler="useViewScenarios.addForm" />
      <template v-slot:buttons>
        <baseButton
          @action="useViewScenarios.add()"
          class="mr-2"
          :loading="useViewScenarios.loadHandler.states.add_scenario"
          >{{ $translate('Save') }}</baseButton
        >
      </template>
    </base-modal>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { computed, onMounted } from 'vue'
import viewHandlerScenarios from '@/use/viewHandlerScenarios'
import router from '@/router'
import contentGuardHandler from '@/use/contentGuardHandler'

export default {
  setup() {
    const route = useRoute()
    const useViewScenarios = viewHandlerScenarios()
    const useContentGuard = contentGuardHandler()

    function tableAction(payload) {
      const type = payload.type

      if (type === 'costs') {
        router.push({
          name: 'costs_details',
          params: { objectId: payload.object_id },
        })
      } else {
        router.push({
          name: 'amounts',
          params: { objectId: payload.object_id },
        })
      }
    }

    onMounted(() => {
      useViewScenarios.listRecords()
    })

    const meta = computed(() => route.meta)
    return {
      meta,
      useViewScenarios,
      tableAction,
      useContentGuard,
    }
  },
}
</script>
