<template>
  <div class="overflow-x-auto">
    <h2 class="text-xl font-bold mb-4">
      {{ energyType.charAt(0).toUpperCase() + energyType.slice(1) }} Usage Comparison
    </h2>
    <table class="min-w-full bg-white border border-gray-300">
      <thead>
        <tr>
          <th rowspan="2" class="border border-gray-300 px-4 py-2">Temperature Range</th>
          <th
            v-for="comparison in comparisonKeys"
            :key="comparison"
            colspan="2"
            class="border border-gray-300 px-4 py-2"
          >
            {{ getComparisonLabel(comparison) }}
          </th>
          <th v-for="scenario in scenarios" :key="scenario.id" colspan="2" class="border border-gray-300 px-4 py-2">
            {{ scenario.title }}
          </th>
        </tr>
        <tr>
          <template v-for="comparison in comparisonKeys" :key="comparison">
            <th class="border border-gray-300 px-4 py-2">% Diff</th>
            <th class="border border-gray-300 px-4 py-2">Abs Diff</th>
          </template>
          <template v-for="scenario in scenarios" :key="scenario.id">
            <th class="border border-gray-300 px-4 py-2">Usage</th>
            <th class="border border-gray-300 px-4 py-2">Obs</th>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(bucket, index) in temperatureBuckets" :key="index">
          <td class="border border-gray-300 px-4 py-2">{{ bucket.label }}</td>
          <template v-for="comparison in comparisonKeys" :key="comparison">
            <td
              :class="[
                'border border-gray-300 px-4 py-2',
                getDifferenceClass(comparisons[comparison][index].percentage),
              ]"
            >
              {{ formatNumber(comparisons[comparison][index].percentage, 2) }}%
            </td>
            <td
              :class="[
                'border border-gray-300 px-4 py-2',
                getDifferenceClass(comparisons[comparison][index].absoluteDiff),
              ]"
            >
              {{ formatNumberWithUnit(comparisons[comparison][index].absoluteDiff, 2) }}
            </td>
          </template>
          <template v-for="scenario in scenarios" :key="scenario.id">
            <td class="border border-gray-300 px-4 py-2">
              {{ formatNumberWithUnit(scenarioUsages[scenario.id][index], 2) }}
            </td>
            <td
              :class="[
                'border border-gray-300 px-4 py-2',
                { 'bg-red-100': observationCounts[scenario.id][index] < 30 },
              ]"
            >
              {{ formatNumber(observationCounts[scenario.id][index], 0) }}
            </td>
          </template>
        </tr>
        <tr class="bg-gray-100">
          <td class="border border-gray-300 px-4 py-2 font-bold">Total</td>
          <template v-for="comparison in comparisonKeys" :key="comparison">
            <td
              :class="[
                'border border-gray-300 px-4 py-2 font-bold',
                getDifferenceClass(getTotalPercentageDiff(comparison)),
              ]"
            >
              {{ formatNumber(getTotalPercentageDiff(comparison), 2) }}%
            </td>
            <td
              :class="[
                'border border-gray-300 px-4 py-2 font-bold',
                getDifferenceClass(getTotalAbsoluteDiff(comparison)),
              ]"
            >
              {{ formatNumberWithUnit(getTotalAbsoluteDiff(comparison), 2) }}
            </td>
          </template>
          <template v-for="scenario in scenarios" :key="scenario.id">
            <td class="border border-gray-300 px-4 py-2 font-bold">
              {{ formatNumberWithUnit(getTotalUsage(scenario.id), 2) }}
            </td>
            <td class="border border-gray-300 px-4 py-2 font-bold">
              {{ formatNumber(getTotalObservations(scenario.id), 0) }}
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { ref, computed } from 'vue'

export default {
  name: 'ScenarioComparisonTable',
  props: {
    scenariosData: {
      type: Array,
      required: true,
    },
    temperatureData: {
      type: Object,
      required: true,
    },
    energyType: {
      type: String,
      required: true,
      validator: (value) => ['gas', 'electricity', 'heat'].includes(value),
    },
  },
  setup(props) {
    const temperatureBuckets = ref([
      { min: -5, max: 0, label: '-5°C to 0°C' },
      { min: 0, max: 5, label: '0°C to 5°C' },
      { min: 5, max: 10, label: '5°C to 10°C' },
      { min: 10, max: 15, label: '10°C to 15°C' },
      { min: 15, max: 18, label: '15°C to 18°C' },
      // { min: 20, max: 25, label: '20°C to 25°C' },
    ])

    const scenarios = computed(() => {
      return props.scenariosData.map((scenario) => ({
        id: scenario.id,
        title: scenario.title,
        data: scenario.data
          .map(([date, usage]) => ({
            date: new Date(date),
            usage: usage,
            temperature: props.temperatureData[date],
          }))
          .filter((item) => item.temperature !== undefined),
      }))
    })

    const calculateUsageAndCount = (data, minTemp, maxTemp) => {
      const filteredData = data.filter((item) => item.temperature >= minTemp && item.temperature < maxTemp)
      const sum = filteredData.reduce((acc, item) => acc + item.usage, 0)
      return { total: sum, count: filteredData.length, mean: filteredData.length > 0 ? sum / filteredData.length : 0 }
    }

    const comparisonKeys = computed(() => {
      const keys = []
      for (let i = 0; i < scenarios.value.length; i++) {
        for (let j = i + 1; j < scenarios.value.length; j++) {
          keys.push(`${i}-${j}`)
        }
      }
      return keys
    })

    const comparisons = computed(() => {
      const result = {}
      comparisonKeys.value.forEach((key) => {
        const [i, j] = key.split('-').map(Number)
        result[key] = temperatureBuckets.value.map((bucket) => {
          const usage1 = calculateUsageAndCount(scenarios.value[i].data, bucket.min, bucket.max)
          const usage2 = calculateUsageAndCount(scenarios.value[j].data, bucket.min, bucket.max)
          const absoluteDiff = usage2.mean - usage1.mean
          return {
            percentage: usage1.mean && usage2.mean ? (absoluteDiff / usage1.mean) * 100 : 0,
            absoluteDiff: absoluteDiff,
            count1: usage1.count,
            count2: usage2.count,
          }
        })
      })
      return result
    })

    const scenarioUsages = computed(() => {
      const result = {}
      scenarios.value.forEach((scenario) => {
        result[scenario.id] = temperatureBuckets.value.map(
          (bucket) => calculateUsageAndCount(scenario.data, bucket.min, bucket.max).total,
        )
      })
      return result
    })

    const observationCounts = computed(() => {
      const result = {}
      scenarios.value.forEach((scenario) => {
        result[scenario.id] = temperatureBuckets.value.map(
          (bucket) =>
            scenario.data.filter((item) => item.temperature >= bucket.min && item.temperature < bucket.max).length,
        )
      })
      return result
    })

    const getComparisonLabel = (comparison) => {
      const [i, j] = comparison.split('-').map(Number)
      return `${scenarios.value[i].title} vs ${scenarios.value[j].title}`
    }

    const getTotalObservations = (scenarioId) => {
      return observationCounts.value[scenarioId].reduce((sum, count) => sum + count, 0)
    }

    const getTotalUsage = (scenarioId) => {
      return scenarioUsages.value[scenarioId].reduce((sum, usage) => sum + usage, 0)
    }

    const getTotalPercentageDiff = (comparison) => {
      const [i, j] = comparison.split('-').map(Number)
      const totalUsage1 = getTotalUsage(scenarios.value[i].id)
      const totalUsage2 = getTotalUsage(scenarios.value[j].id)
      return ((totalUsage2 - totalUsage1) / totalUsage1) * 100
    }

    const getTotalAbsoluteDiff = (comparison) => {
      const [i, j] = comparison.split('-').map(Number)
      const totalUsage1 = getTotalUsage(scenarios.value[i].id)
      const totalUsage2 = getTotalUsage(scenarios.value[j].id)
      return totalUsage2 - totalUsage1
    }

    const getUnit = () => {
      switch (props.energyType) {
        case 'gas':
          return 'm³'
        case 'electricity':
          return 'kWh'
        case 'heat':
          return 'GJ'
        default:
          return ''
      }
    }

    const formatNumber = (value, decimals) => {
      return new Intl.NumberFormat('nl-NL', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      }).format(value)
    }

    const formatNumberWithUnit = (value, decimals) => {
      return `${formatNumber(value, decimals)} ${getUnit()}`
    }

    const getDifferenceClass = (value) => {
      if (value > 0) return 'bg-red-100'
      if (value < 0) return 'bg-green-100'
      return ''
    }

    return {
      temperatureBuckets,
      comparisonKeys,
      comparisons,
      getComparisonLabel,
      scenarios,
      observationCounts,
      scenarioUsages,
      getTotalObservations,
      getTotalUsage,
      getTotalPercentageDiff,
      getTotalAbsoluteDiff,
      formatNumber,
      formatNumberWithUnit,
      getDifferenceClass,
    }
  },
}
</script>

<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
th {
  background-color: #f2f2f2;
}
.total-row {
  background-color: #e6e6e6;
}
</style>
