<template>
  <div class="grid grid-cols-5 text-xs">
    <div class="col-span-1 font-bold">{{ useFilter.roundDynamic(summary.usage_total) }} m3</div>
    <div class="col-span-1 text-center">
      <!-- if discount -->
      <div v-if="summary.discount" class="flex flex-row justify-center">
        <span class="mr-2 font-bold">{{ useFilter.roundDynamicCurrency(summary.tax_totals.eb_reduced) }}</span>
        <tooltip-EB :data="summary" type="GasReduced" />
      </div>

      <!-- else -->
      <div v-else class="flex flex-row justify-center">
        <span class="mr-2 font-bold">{{ useFilter.roundDynamicCurrency(summary.tax_totals.eb) }}</span>
        <tooltip-EB :data="summary" type="Gas" />
      </div>
    </div>
    <div class="col-span-1 text-center">
      <!-- if discount -->
      <div v-if="summary.discount" class="flex flex-row justify-center">
        <span class="mr-2 font-bold">{{ useFilter.roundDynamicCurrency(summary.tax_totals.ode_reduced) }}</span>
        <tooltip-EB :data="summary" type="GasReduced" />
      </div>

      <!-- else -->
      <div v-else class="flex flex-row justify-center">
        <span class="mr-2 font-bold">{{ useFilter.roundDynamicCurrency(summary.tax_totals.ode) }}</span>
        <tooltip-EB :data="summary" type="Gas" />
      </div>
    </div>
    <div class="col-span-1 text-center font-bold"><!-- spacer, gas has no tax credit --></div>
    <div class="col-span-1 text-center font-bold">
      {{ useFilter.roundDynamicCurrency(summary.tax_totals.tax_total_gas) }}
    </div>
  </div>
</template>

<script>
import filterHandler from '@/use/filterHandler'
import tooltipEB from '@/views/costs/tax/tooltipEB.vue'

export default {
  props: {
    summary: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const useFilter = filterHandler()

    return {
      useFilter,
    }
  },
  components: {
    tooltipEB,
  },
}
</script>
