import { ref } from 'vue'

let show = ref(false)
let message = ref('')
let resolvePromise = ref(undefined)

export default () => {
  function open(options) {
    // set data
    message.value = options.message

    // open modal
    show.value = true

    // set promise
    return new Promise((resolve) => {
      resolvePromise.value = resolve
    })
  }

  // confirm
  function confirm() {
    show.value = false
    resolvePromise.value(true) // Corrected line
    reset()
  }

  // cancel
  function cancel() {
    show.value = false
    resolvePromise.value(false) // Corrected line
    reset()
  }

  // reset
  function reset() {
    message.value = ''
  }

  return {
    show,
    open,
    message,
    confirm,
    cancel,
  }
}
