<template>
  <span :class="pillClass">
    {{ value }}
  </span>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'pillTrueFalse',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const pillClass = computed(() => {
      const baseClasses = 'px-2 py-1 rounded-full text-xs font-medium'
      switch (props.value) {
        case true:
          return `${baseClasses} bg-green-700/75 text-white`
        case false:
          return `${baseClasses} bg-gray-200/75 text-black`
        default:
          return `${baseClasses} bg-gray-200/75 text-black`
      }
    })

    return { pillClass }
  },
}
</script>
