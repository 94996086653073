import { ref, computed, watch } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import timeHandler from '@/use/timeHandler'
import chartOptionshandler from '@/use/chartOptionsHandler'
import formHandler from '@/use/formHandler'
import selectValues from '@/use/selects/values'

export default () => {
  const useChartOptions = chartOptionshandler()
  const useTime = timeHandler()
  const useApi = apiHandler()
  const loadHandler = loadingHandler()
  const interval = useTime.interval
  const range = useTime.range
  const editForm = formHandler()

  let node = ref({})
  let chart_data_usage = ref([])
  let chart_data_production_generation = ref({})
  let chart_data_production_return = ref({})
  let showAdd = ref(false)
  let showEdit = ref(false)
  let node_types = ref([])
  let showContracts = ref(false)
  let originalTags = []

  const chart_options_usage = computed(() => {
    if (node.value) {
      return useChartOptions.getOptions(node.value.medium, chart_data_usage.value, 'Usage')
    }
    return useChartOptions.getOptions(node.value.medium, {})
  })

  const chart_options_generation = computed(() => {
    if (node.value) {
      return useChartOptions.getOptions(node.value.medium, chart_data_production_generation.value, 'Generation')
    }
    return useChartOptions.getOptions(node.value.medium, {})
  })

  const chart_options_return = computed(() => {
    if (node.value) {
      return useChartOptions.getOptions(
        node.value.medium,
        chart_data_production_return.value,
        node.value.type_name === 'Brutoproductiemeter' ? 'Opwekking' : 'Teruglevering',
      )
    }
    return useChartOptions.getOptions(node.value.medium, {})
  })

  function getDetails(payload) {
    loadHandler.setLoadingState('get_details', true)
    useApi.request_api('get', 'v1', `nodes/${payload.object_id}`).then((response) => {
      node.value = response.data
      node.value.type_name = node.value.type?.name
      node.value.type_id = node.value.type?.object_id

      const payload = {
        ids: [node.value.object_id],
        interval: interval.value,
        start_date: useTime.startDate.value.getTime(),
        end_date: useTime.endDate.value.getTime(),
      }

      getSummaryNew(payload)

      if (node.value.contracts.length > 0) {
        useApi.request_api('get', 'v1', 'contracts/' + node.value.contracts[0].object_id).then((response) => {
          node.value.contracts[0].code = response.data.code
          node.value.contracts[0].provider = response.data.provider
          node.value.contracts[0].start_date = response.data.start_date
          node.value.contracts[0].end_date = response.data.end_date
        })
      }
      if (node.value.cluster !== null) {
        useApi.request_api('get', 'v1', `clusters/${node.value.cluster}`).then((response) => {
          node.value.cluster_name = response.data.name
        })
      }
      loadHandler.setLoadingState('get_details', false)
    })
  }

  function getSummaryNew(payload) {
    loadHandler.setLoadingState('get_summary', true)

    // parse payload to query string
    const queryString = useApi.toQueryString(payload)

    useApi.request_api('get', 'v1', 'nodes/data/summary?' + queryString).then((response) => {
      const data = response.data

      const { usage, production } = data

      // parase timestamps
      const formattedUsage = usage.map(([datetime, value]) => {
        const formattedDate = useTime.labelSelector(payload.interval, new Date(datetime))
        return [formattedDate, value]
      })

      const formattedProduction = production.map(([datetime, value]) => {
        const formattedDate = useTime.labelSelector(payload.interval, new Date(datetime))
        return [formattedDate, value]
      })

      chart_data_usage.value = formattedUsage
      chart_data_production_return.value = formattedProduction

      loadHandler.setLoadingState('get_summary', false)
    })
  }

  const selectType = computed(() => {
    let payload = []
    if (node_types.value.length > 0) {
      node_types.value.forEach((type) => {
        payload.push({
          label: type.name,
          value: type.object_id,
        })
      })
    }
    return payload
  })

  const nodeFields = computed(() => {
    return [
      {
        label: 'Identification',
        key: 'provider_identifier',
        type: 'string',
        add: false,
        edit: false,
      },
      {
        label: 'Description',
        key: 'description',
        type: 'string',
        table: false,
      },
      {
        label: 'Medium',
        key: 'medium',
        type: 'select',
        component: 'pillMainType',
        edit: true,
        disabled: true,
        options: selectValues.medium,
        translate_value: true,
      },
      {
        label: 'Type',
        key: 'type_name',
        type: 'select',
        options: selectType.value,
        edit: false,
      },
      {
        label: 'Type',
        key: 'type_id',
        type: 'select',
        options: selectType.value,
        details_list: false,
      },
      {
        label: 'type_id',
        key: 'type_id',
        type: 'string',
        table: false,
        edit: false,
        details_list: false,
      },
      {
        label: 'Residential',
        key: 'residential',
        type: 'toggle',
        table: false,
        options: selectValues.jaNee,
        dependent_show_values: ['Electricity'],
        dependent_show_field_key: 'medium',
        translate_value: true,
      },
      {
        label: 'Discount',
        key: 'discount',
        type: 'toggle',
        table: false,
        options: selectValues.jaNee,
        dependent_show_values: ['Gas'],
        dependent_show_field_key: 'medium',
        translate_value: true,
      },
      {
        label: 'Size',
        key: 'size',
        type: 'select',
        table: false,
        edit: true,
        options: selectValues.size,
        translate_value: true,
      },
      {
        label: 'Address',
        key: 'address',
        type: 'string',
        edit: false,
        demodata: 'Demostraat 10',
      },
      {
        label: 'Zip',
        key: 'zip',
        type: 'string',
        table: false,
        edit: false,
        demodata: '1234AB',
      },
      {
        label: 'Place',
        key: 'place',
        type: 'string',
        edit: false,
        demodata: 'Demoplaats',
      },
      {
        label: 'Data',
        key: 'data_available',
        type: 'string',
        table: false,
        details_list: false,
        edit: false,
      },
      // {
      //   label: 'Data production',
      //   key: 'data_available_production',
      //   type: 'string',
      //   table: false,
      //   details_list: false,
      //   edit: false,
      // },
      {
        label: 'geometry',
        key: 'geometry',
        type: 'string',
        table: false,
        details_list: false,
        edit: false,
      },
      {
        label: 'Contracted power',
        key: 'contracted_power',
        type: 'number',
        table: false,
        filter: 'power',
        dependent_show_values: ['Electricity', 'Gas'],
        dependent_show_field_key: 'medium',
      },
      {
        label: 'Peak power',
        key: 'usage_highest',
        type: 'number',
        table: false,
        edit: false,
        filter: 'power',
        dependent_show_values: ['Electricity', 'Gas'],
        dependent_show_field_key: 'medium',
      },
      {
        label: 'Peak Power date',
        key: 'usage_highest_timestamp',
        type: 'date',
        table: false,
        edit: false,
        filter: 'dateFromTimestamp',
        dependent_show_values: ['Electricity', 'Gas'],
        dependent_show_field_key: 'medium',
      },
      {
        label: 'Tags',
        key: 'tags',
        type: 'tags',
        table: false,
        component: 'pillLabels',
      },
    ]
  })

  watch([() => range.value, () => useTime.startDate.value, () => useTime.endDate.value], async () => {
    const payload = {
      ids: [node.value.object_id],
      interval: interval.value,
      start_date: useTime.startDate.value.getTime(),
      end_date: useTime.endDate.value.getTime(),
    }

    getSummaryNew(payload)
  })

  async function edit() {
    loadHandler.setLoadingState('edit_node', true)
    const payload = editForm.getData()

    if ('tags' in payload) {
      // const originalTags = node.value.tags.map((tag) => tag.name)
      const newTags = payload.tags.map((tag) => tag.name)

      // Tags to add
      const tagsToAdd = newTags.filter((tag) => !originalTags.map((tag) => tag.name).includes(tag))

      // Tags to remove
      const tagsToRemove = originalTags.map((tag) => tag.name).filter((tag) => !newTags.includes(tag))

      // Create new tags if they don't exist
      if (tagsToAdd.length > 0) {
        loadHandler.setLoadingState('add_tags', true)
        await useApi.request_api('post', 'v1', 'tags', { names: tagsToAdd }).then(() => {
          loadHandler.setLoadingState('add_tags', false)
        })

        // Assign new tags to the node
        const tagAssignmentPayload = {
          names: tagsToAdd,
        }

        loadHandler.setLoadingState('assign_tags', true)
        await useApi.request_api('post', 'v1', `nodes/${payload.object_id}/tags`, tagAssignmentPayload).then(() => {
          loadHandler.setLoadingState('assign_tags', false)
        })
      }

      // Unassign tags from the node
      if (tagsToRemove.length > 0) {
        const tagUnassignmentPayload = {
          names: tagsToRemove,
        }

        const queryString = useApi.toQueryString(tagUnassignmentPayload)
        const url = `nodes/${payload.object_id}/tags?${queryString}`

        loadHandler.setLoadingState('unassign_tags', true)
        await useApi.request_api('delete', 'v1', url).then(() => {
          loadHandler.setLoadingState('unassign_tags', false)
        })
      }

      delete payload['tags']
    }

    // Use PATCH request to update node details
    useApi
      .request_api('patch', 'v1', `nodes/${payload.object_id}`, payload)
      .then(() => {
        const getDetailsPayload = {
          object_id: payload.object_id,
        }

        getDetails(getDetailsPayload)
        closeEdit()
        loadHandler.setLoadingState('edit_node', false)
      })
      .catch(() => {
        loadHandler.setLoadingState('edit_node', false)
      })
  }

  function updateGeometry(geometry) {
    const payload = {
      object_id: node.value.object_id,
      geometry: geometry,
    }

    loadHandler.setLoadingState('update_geometry', true)
    useApi.request_api('patch', 'v1', `nodes/${payload.object_id}`, payload).then(() => {
      loadHandler.setLoadingState('update_geometry', false)
    })
  }

  function openEdit() {
    originalTags = JSON.parse(JSON.stringify(node.value.tags))

    editForm.create({
      type: 'edit',
      fields: nodeFields.value,
      data: node.value,
    })
    showEdit.value = true
  }

  function closeEdit() {
    showEdit.value = false
  }

  const haveContracts = computed(() => {
    if (Object.keys(node.value).length > 0) {
      const contracts = node.value.contracts
      return contracts.length > 0 ? true : false
    }
    return false
  })

  const sortedContracts = computed(() => {
    if (haveContracts.value) {
      return node.value.contracts.sort((a, b) => b.start_date - a.start_date)
    }
    return {}
  })

  function openContractsModal() {
    showContracts.value = true
  }

  function closeContractsModal() {
    showContracts.value = false
  }

  const contractFields = ref([
    {
      label: 'Code',
      key: 'code',
      type: 'string',
      searchable: true,
    },
    {
      label: 'Provider',
      key: 'provider',
      type: 'string',
      searchable: true,
    },
    {
      label: 'Start date',
      key: 'start_date',
      type: 'date',
      filter: 'dateFromEpochDay',
    },
    {
      label: 'End date',
      key: 'end_date',
      type: 'date',
      filter: 'dateFromEpochDay',
    },
  ])

  function getNodeTypes() {
    loadHandler.setLoadingState('get_node_types', true)
    useApi.request_api('get', 'v1', 'node_types').then((response) => {
      const result = response.data
      node_types.value = result
      loadHandler.setLoadingState('get_node_types', false)
    })
  }

  return {
    node,
    loadHandler,
    nodeFields,
    showEdit,
    showAdd,
    getDetails,
    chart_data_usage,
    chart_data_production_generation,
    chart_data_production_return,
    chart_options_usage,
    chart_options_generation,
    chart_options_return,
    editForm,
    openEdit,
    closeEdit,
    edit,
    updateGeometry,
    getNodeTypes,
    sortedContracts,
    haveContracts,
    openContractsModal,
    closeContractsModal,
    showContracts,
    contractFields,
  }
}
