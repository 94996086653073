<template>
  <base-tooltip :title="$translate('Balance calculation')" size="m">
    <!-- year -->
    <div v-for="(usage, year) in data.usage" :key="year" class="mb-6">
      <!-- year label -->
      <span class="text-lg font-semibold">{{ year }}</span>

      <!-- body summary -->
      <div>
        <!-- titles -->
        <span class="flex flex-row">
          <span class="basis-1/4"></span>
          <span class="basis-1/4 text-center">{{ $translate('Buy-in') }}</span>
          <span class="basis-1/4 text-center">{{ $translate('Balanceable production') }}</span>
          <span class="basis-1/4 text-center">{{ $translate('Total') }}</span>
        </span>

        <!-- summary -->
        <span class="flex flex-row text-center">
          <span class="basis-1/4 text-left">{{ $translate('Buy-in balance') }}</span>
          <span class="basis-1/4 text-center">{{ useFilter.roundDynamic(usage) }} {{ data.unit }}</span>
          <span class="basis-1/4 text-center" v-if="type === 'cluster'"
            >{{ useFilter.roundDynamic(data.balanced_production[year]) }} {{ data.unit }}</span
          >
          <span class="basis-1/4 text-center" v-else
            >{{ useFilter.roundDynamic(data.production[year]) }} {{ data.unit }}</span
          >
          <span class="basis-1/4 text-center"
            >{{ useFilter.roundDynamic(data.usage_production_balance[year]) }} {{ data.unit }}</span
          >
        </span>
      </div>

      <!-- body nodes -->
      <div v-if="type === 'cluster'" class="mt-6">
        <span class="italic">Specificatie saldeerbare productie</span>
        <!-- titles -->
        <span class="flex flex-row mt-2">
          <span class="basis-1/4 text-left">{{ $translate('Identification') }}</span>
          <span class="basis-1/4 text-center">{{ $translate('Buy-in') }}</span>
          <span class="basis-1/4 text-center">{{ $translate('Production') }}</span>
          <span class="basis-1/4 text-center">{{ $translate('Total') }}</span>
        </span>

        <!-- summary -->
        <span class="flex flex-row justify-between" v-for="(node, index) in data.nodes" :key="index">
          <span v-if="node.description" class="basis-1/4 text-left">{{ node.description }}</span>
          <span v-else class="basis-1/4 text-left">{{ node.provider_identifier }}</span>
          <span class="basis-1/4 text-center">{{ useFilter.roundDynamic(node.usage[year]) }} {{ data.unit }}</span>
          <span class="basis-1/4 text-center">{{ useFilter.roundDynamic(node.production[year]) }} {{ data.unit }}</span>
          <span class="basis-1/4">
            <span class="flex flex-row justify-center">
              <span>{{ useFilter.roundDynamic(node.balanced_production[year]) }} {{ data.unit }}</span>
              <featherIcon v-if="node.size == 'Large'" class="w-4 h-4 ml-1" icon="AlertTriangleIcon" />
            </span>
          </span>
        </span>

        <!-- total -->
        <span class="flex flex-row justify-between font-bold">
          <span class="basis-1/4 text-left">{{ $translate('Total') }}</span>
          <span class="basis-1/4 text-center"></span>
          <span class="basis-1/4 text-center"></span>
          <span class="basis-1/4 text-center"
            >{{ useFilter.roundDynamic(data.balanced_production[year]) }} {{ data.unit }}</span
          >
        </span>
      </div>
    </div>

    <!-- {{ data }} -->
  </base-tooltip>
</template>

<script>
import featherIcon from '@/components/extended/feather/featherIcon.vue'
// import { ref } from 'vue'
import filterHandler from '@/use/filterHandler'

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: '',
    },
  },
  setup() {
    const useFilter = filterHandler()
    return {
      useFilter,
    }
  },
  components: {
    featherIcon,
  },
}
</script>
