<template>
  <base-tooltip :title="$translate('Balance calculation')" size="s">
    <!-- Peak -->
    <div class="flex flex-row justify-between">
      <span class="italic">{{ $translate('Peak') }}</span>
      <span></span>
    </div>
    <div class="flex flex-row justify-between">
      <div>{{ $translate('Usage') }}</div>
      <div>{{ useFilter.roundDynamic(data.usage_peak) }} {{ data.unit }}</div>
    </div>
    <div class="flex flex-row justify-between">
      <span>{{ $translate('Return') }}</span>
      <span>{{ useFilter.roundDynamic(data.production_peak) }} {{ data.unit }}</span>
    </div>
    <div class="flex flex-row justify-between">
      <span class="font-bold">{{ $translate('Balance') }}</span>
      <span class="font-bold">{{ useFilter.roundDynamic(data.usage_production_balance_peak) }} {{ data.unit }}</span>
    </div>
    <!-- Off peak -->
    <div class="flex flex-row justify-between mt-4">
      <span class="italic">{{ $translate('Off-peak') }}</span>
      <span></span>
    </div>
    <div class="flex flex-row justify-between">
      <span>{{ $translate('Usage') }}</span>
      <span>{{ useFilter.roundDynamic(data.usage_off_peak) }} {{ data.unit }}</span>
    </div>
    <div class="flex flex-row justify-between">
      <span>{{ $translate('Return') }}</span>
      <span>{{ useFilter.roundDynamic(data.production_off_peak) }} {{ data.unit }}</span>
    </div>
    <div class="flex flex-row justify-between">
      <span class="font-bold">{{ $translate('Balance') }}</span>
      <span class="font-bold"
        >{{ useFilter.roundDynamic(data.usage_production_balance_off_peak) }} {{ data.unit }}</span
      >
    </div>
  </base-tooltip>
</template>

<script>
// import featherIcon from '@/components/extended/feather/featherIcon.vue'
// import { ref } from 'vue'
import filterHandler from '@/use/filterHandler'

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const useFilter = filterHandler()
    return {
      useFilter,
    }
  },
  components: {
    // featherIcon,
  },
}
</script>
