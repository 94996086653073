function loadTranslation() {
  const locales = require.context('./translations', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

export default () => {
  const locale = 'nl'
  const translation = loadTranslation()

  function t(key) {
    if (key in translation[locale]) {
      return translation[locale][key]
    }
    console.warn(`${key} not found in translation file, could not translate`)
    return key
  }

  return {
    t,
  }
}
