import { ref } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import formHandler from '@/use/formHandler'
import selectValues from '@/use/selects/values'

export default () => {
  let costData = ref({})
  let taxData = ref({})
  let filter = ref({})
  let scenario = ref({})
  const message = ref('')
  const message_tax = ref('')
  const useApi = apiHandler()
  const loadHandler = loadingHandler()
  const filterForm = formHandler()
  const showFilterModal = ref(false)
  const dateStartYear = new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0).getTime()
  const dateEndYear = new Date(new Date().getFullYear(), 11, 31, 0, 0, 0, 0).getTime()

  const filterFields = ref([
    {
      label: 'Start date',
      key: 'start_date',
      type: 'date',
    },
    {
      label: 'End date',
      key: 'end_date',
      type: 'date',
    },
    {
      label: 'Tags',
      key: 'tags',
      type: 'tags',
      component: 'pillLabels',
      mode: 'Search',
    },
    {
      label: 'Label filtertype',
      key: 'filter_logic',
      type: 'select',
      options: selectValues.tagFilterLogic,
      dependent_show_field_key: 'tags',
      dependent_show_field_type: 'labels',
      translate_value: true,
    },
  ])

  function closeFilterModal() {
    filterForm.sync({
      start_date: dateStartYear,
      end_date: dateEndYear,
      filter_logic: 'subset',
      tags: [],
    })
    showFilterModal.value = false
  }

  function openFilterModal() {
    showFilterModal.value = true
  }

  function applyFilter() {
    updateScenario()
    summarizeCosts()
    summarizeTax()
    showFilterModal.value = false
  }

  const defaultFilter = {
    start_date: dateStartYear,
    end_date: dateEndYear,
    filter_logic: 'subset',
    tags: [],
  }

  filterForm.create({
    type: 'edit',
    fields: filterFields.value,
    data: defaultFilter,
  })

  function summarizeCosts() {
    loadHandler.setLoadingState('summarize_costs', true)
    useApi.request_api('get', 'v1', 'scenarios/costs/' + scenario.value.object_id + '/calculate').then((response) => {
      const data = response.data
      if (data && data.contracts) {
        // First sort the contracts by provider
        const sortedContracts = {}
        Object.keys(data.contracts)
          .sort((a, b) => {
            const providerA = data.contracts[a].contractinfo?.provider || ''
            const providerB = data.contracts[b].contractinfo?.provider || ''
            return providerA.localeCompare(providerB)
          })
          .forEach((key) => {
            const contract = data.contracts[key]
            // For each contract, sort its records array by description
            if (contract.cost_summary && contract.cost_summary.records) {
              contract.cost_summary.records.sort((a, b) => {
                // If both items have no description, maintain original order
                if (!a.description && !b.description) return 0
                // If only a has no description, put it after b
                if (!a.description) return 1
                // If only b has no description, put it after a
                if (!b.description) return -1
                // If both have descriptions, compare them
                return a.description.localeCompare(b.description)
              })
            }
            sortedContracts[key] = contract
          })

        data.contracts = sortedContracts
        costData.value = data
      } else {
        costData.value = {}
        message.value = response.data.message
      }
      loadHandler.setLoadingState('summarize_costs', false)
    })
  }

  function summarizeTax() {
    loadHandler.setLoadingState('summarize_tax', true)
    useApi.request_api('get', 'v1', 'scenarios/costs/' + scenario.value.object_id + '/tax').then((response) => {
      const data = response.data
      if (data) {
        // Sort clusters if they exist
        if (data.clusters) {
          const sortedClusters = {}
          Object.keys(data.clusters)
            .sort((a, b) => {
              const clusterA = data.clusters[a].cluster_name || ''
              const clusterB = data.clusters[b].cluster_name || ''
              return clusterA.localeCompare(clusterB)
            })
            .forEach((key) => {
              sortedClusters[key] = data.clusters[key]
            })
          data.clusters = sortedClusters
        }

        // Sort nodes if they exist
        if (data.nodes) {
          const sortedNodes = {}
          Object.keys(data.nodes)
            .sort((a, b) => {
              const descriptionA = data.nodes[a].description || ''
              const descriptionB = data.nodes[b].description || ''
              return descriptionA.localeCompare(descriptionB)
            })
            .forEach((key) => {
              sortedNodes[key] = data.nodes[key]
            })
          data.nodes = sortedNodes
        }

        taxData.value = data
      } else {
        taxData.value = {}
        message_tax.value = response.data.message
      }
      loadHandler.setLoadingState('summarize_tax', false)
    })
  }

  function getDetails(payload) {
    loadHandler.setLoadingState('get_details', true)
    useApi.request_api('get', 'v1', 'scenarios/' + payload.object_id).then((response) => {
      const data = response.data
      scenario.value = data

      // settings
      const settings = data.configuration

      if (Object.keys(settings).length > 0) {
        filterForm.data.value.start_date = settings.start_date
        filterForm.data.value.end_date = settings.end_date
        filterForm.data.value.tags = settings.tags
        filterForm.data.value.filter_logic = settings.filter_logic
      } else {
        // do nothing
      }

      // load summaries
      summarizeCosts()
      summarizeTax()
      loadHandler.setLoadingState('get_details', false)
    })
  }

  function updateScenario() {
    const payload = {
      object_id: scenario.value.object_id,
      configuration: {
        start_date: filterForm.data.value.start_date,
        end_date: filterForm.data.value.end_date,
        tags: filterForm.data.value.tags,
        filter_logic: filterForm.data.value.filter_logic,
      },
    }
    loadHandler.setLoadingState('edit_scenario', false)
    useApi.request_api('patch', 'v1', 'scenarios/' + scenario.value.object_id, payload).then(() => {
      loadHandler.setLoadingState('edit_scenario', false)
    })
  }

  return {
    loadHandler,
    costData,
    taxData,
    filter,
    filterForm,
    showFilterModal,
    closeFilterModal,
    openFilterModal,
    applyFilter,
    summarizeCosts,
    summarizeTax,
    message,
    message_tax,
    scenario,
    getDetails,
    updateScenario,
  }
}
