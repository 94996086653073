<template>
  <div>
    <nav
      :class="surfaceColour"
      class="flex flex-wrap items-center justify-between w-full py-4 md:py-0 px-4 h-16 font-montserrat text-gray-500"
    >
      <!-- start -->
      <div class="cursor-pointer" @click="sidebar.toggle()">
        <Bars3Icon class="w-6 h-6" />
      </div>

      <!-- end -->
      <div class="text-sm relative" ref="user_dropdown_ref">
        <div class="flex flex-row">
          <div class="mr-4 text-right">
            <p>{{ userName }}</p>
            <p>{{ userRole }}</p>
          </div>
          <div class="my-auto rounded-full bg-gray-200 cursor-pointer" @click="toggleDropDown()">
            <featherIcon class="w-5 h-5 m-2" icon="UserIcon" />
          </div>
        </div>

        <!-- dropper -->
        <div class="absolute bg-white w-full rounded shadow-lg z-50 border border-gray-100" v-if="showDropDown">
          <!-- organisations -->
          <div class="hover:bg-gray-50 p-2 cursor-pointer" @click="organisationclicker()">
            <span class="flex cursor-pointer">
              <featherIcon class="w-4 h-4 my-auto mr-2" icon="UsersIcon" />
              <p class="my-auto">{{ $translate('Organisations') }}</p>
            </span>
          </div>

          <!-- logout -->
          <div class="hover:bg-gray-50 p-2 cursor-pointer" @click="logOut()">
            <span class="flex cursor-pointer">
              <featherIcon class="w-4 h-4 my-auto mr-2" icon="LogOutIcon" />
              <p class="my-auto">{{ $translate('Log out') }}</p>
            </span>
          </div>

          <!-- version -->
          <div class="bg-gray-100 p-2 text-xs">
            <span class="flex">
              <p class="my-auto mx-auto text-center">{{ $translate('Version') }} {{ version }}</p>
            </span>
          </div>
        </div>
      </div>
    </nav>

    <!-- Organisation modal -->
    <base-modal v-if="useUser.showSelector.value" @close="useUser.closeSelector()" :title="`Kies een organisatie`">
      <baseForm :handler="useUser.switchForm" />
      <template v-slot:buttons>
        <baseButton @action="useUser.switchOrganisation()" class="mr-2" :loading="useUser.loadHandler.states.switch">{{
          $translate('Choose')
        }}</baseButton>
      </template>
    </base-modal>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { useAuth0 } from '@auth0/auth0-vue'
import { Bars3Icon } from '@heroicons/vue/24/outline'
import useTheme from '@/use/themeHandler'
import useSidebar from '@/use/sidebarHandler'
import featherIcon from '@/components/extended/feather/featherIcon.vue'
import contentGuardHandler from '@/use/contentGuardHandler'
import { version } from '../../package'
import userHandler from '@/use/userHandler'

export default {
  setup() {
    const auth = useAuth0()
    const store = useStore()
    const showMenu = ref(false)
    const navBarConfig = computed(() => store.state.general.navBar)
    const sidebar = useSidebar()
    const showDropDown = ref(false)
    const useContentGuard = contentGuardHandler()
    const useUser = userHandler()

    // colours
    const themeHandler = useTheme()
    const surfaceColour = themeHandler.getBackgroundColor('surface')

    function toggleMenu() {
      if (showMenu.value === false) {
        showMenu.value = true
      } else {
        showMenu.value = false
      }
    }

    function action(action) {
      if (action.type === 'storeMutation') {
        store.commit(action.target)
      } else if (action.type === 'logOut') {
        auth.logout({ returnTo: window.location.origin })
      }
    }

    function logOut() {
      auth.logout({ returnTo: window.location.origin })
    }

    function toggleDropDown() {
      showDropDown.value = !showDropDown.value
    }

    const user_dropdown_ref = ref(null)

    onMounted(() => window.addEventListener('click', clicker))
    onUnmounted(() => window.removeEventListener('click', clicker))

    function clicker(event) {
      if (!user_dropdown_ref.value.contains(event.target)) {
        if (showDropDown.value) {
          toggleDropDown()
        }
      }
    }

    function organisationclicker() {
      useUser.openSelector()
      toggleDropDown()
    }

    const userName = auth.user.value.name
    const userRole = auth.user.value['https://sw/roles'][0]

    return {
      showMenu,
      toggleMenu,
      navBarConfig,
      action,
      surfaceColour,
      sidebar,
      userName,
      userRole,
      toggleDropDown,
      showDropDown,
      logOut,
      user_dropdown_ref,
      useContentGuard,
      version,
      useUser,
      organisationclicker,
    }
  },
  components: {
    Bars3Icon,
    featherIcon,
  },
}
</script>
