export default () => {
  const utilities = {
    electricity: {
      unit: 'kWh',
      icon: 'iconElectricity',
      colour: '#ffcc00',
    },
    gas: {
      unit: 'm3',
      icon: 'iconGas',
      colour: '#aecc53',
    },
    water: {
      unit: 'm3',
      icon: 'iconWater',
      colour: '#94cef2',
    },
    heat: {
      unit: 'GJ',
      icon: 'iconHeat',
      colour: '#ed6a5a',
    },
    heatcost: {
      unit: 'eenheden',
      icon: 'iconHeatCost',
      colour: '#93748a',
    },
    melding: {
      unit: '',
      icon: 'iconExclamationMark',
    },
  }

  return {
    utilities,
  }
}
