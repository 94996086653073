/* eslint-disable no-unused-vars */
import { ref, computed } from 'vue'
import useValidate from '@/use/validationHandler'

export default () => {
  // data
  let fields = ref([])
  let data = ref({})
  let type = ref()
  let loading = ref(false)

  // handlers
  const validator = useValidate()

  function create(options) {
    fields.value = options.fields
    type.value = options.type
    if (type.value === 'edit') {
      if (options.data) {
        loadEditData(options.data)
      }
    } else {
      loadAddData()
    }
  }

  function setValue(key, value) {
    data.value[key] = value
  }

  const filteredFields = computed(() => {
    return fields.value.filter((field) => {
      if (field[type.value] === false) {
        return false
      }
      return true
    })
  })

  function loadAddData() {
    filteredFields.value.forEach((field) => {
      const key = field.key
      const type = field.type

      if (type === 'list') {
        data.value[key] = []
      } else {
        data.value[key] = ''
      }
    })
  }

  function loadEditData(payload, idField = null) {
    filteredFields.value.forEach((field) => {
      if (field.type !== 'subtitle') {
        const key = field.key
        const value = payload[field.key]

        if (value || value === 0) {
          data.value[key] = value
        } else {
          if (field.type === 'list') {
            data.value[key] = []
          } else if (field.type === 'toggle') {
            if (field.default === true) {
              data.value[key] = true
            } else {
              data.value[key] = false
            }
          } else {
            data.value[key] = ''
          }
        }
      }
    })

    // also add object_id
    if (idField) {
      data.value[idField] = payload[idField]
    } else if ('object_id' in payload) {
      data.value['object_id'] = payload['object_id']
    }
  }

  function close() {
    data.value = {}
    fields.value = []
    type.value = undefined
  }

  function validate(event, type, rules, key) {
    const input = event.target.value

    // validatorguard
    if (validator.validate(input, type)) {
      // data.value[key] = input

      // if input is file
      if (type === 'file') {
        data.value[key] = event.target.files[0]
      } else if (type === 'check') {
        data.value[key] = event.target.checked
      } else {
        data.value[key] = input
      }
    } else {
      if (data.value[key] != undefined) {
        event.target.value = data.value[key]
      } else {
        event.target.value = null
      }
    }
  }

  function sync(payload) {
    data.value = payload
  }

  function addListItem(key, payload) {
    if (key in data.value) {
      data.value[key].push(payload)
    } else {
      data.value[key] = [payload]
    }
  }

  function removeListItem(key, index) {
    data.value[key].splice(index, 1)
  }

  function editListItem(key, index, value) {
    data.value[key][index] = value
  }

  function getData() {
    let returnData = {}

    // parse number fields
    filteredFields.value.forEach((field) => {
      const fieldType = field.type
      const fieldKey = field.key

      if (fieldType === 'number') {
        returnData[fieldKey] = Number(data.value[fieldKey])
      } else if (fieldType === 'select' && field.select_value_type === 'number') {
        returnData[fieldKey] = Number(data.value[fieldKey])
      } else {
        if (data.value[fieldKey]) {
          returnData[fieldKey] = data.value[fieldKey]
        } else {
          if (data.value[fieldKey] === false) {
            returnData[fieldKey] = false
          } else {
            returnData[fieldKey] = null
          }
        }
      }
    })

    // check fo object_id
    if ('object_id' in data.value) {
      returnData['object_id'] = data.value['object_id']
    }
    return returnData
  }

  return {
    fields,
    open,
    filteredFields,
    type,
    loadAddData,
    data,
    close,
    validate,
    setValue,
    loading,
    loadEditData,
    create,
    addListItem,
    removeListItem,
    sync,
    editListItem,
    getData,
  }
}
