<template>
  <div :class="`flex flex-col h-screen ${backgroundColor}`">
    <navigationBar />
    <slot></slot>
  </div>
</template>

<script>
import navigationBar from '@/components/navigationBar.vue'
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  setup() {
    const store = useStore()
    const backgroundColor = computed(() => store.getters['general/getStyle']('background'))

    return { backgroundColor }
  },
  components: {
    navigationBar,
  },
}
</script>
