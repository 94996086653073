import { ref } from 'vue'

export default () => {
  let tabs = ref([])
  let selected = ref(0)

  // create
  function create(options, startid) {
    selected.value = startid

    options.tabs.forEach((tab) => {
      tabs.value.push(tab)
    })
  }

  function onSelect(index) {
    selected.value = index
  }

  return {
    create,
    tabs,
    selected,
    onSelect,
  }
}
