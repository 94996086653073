<template>
  <div class="p-6">
    <base-page-title :title="pageTitle" :suffix="pageSuffix" :icon="meta.titleIcon"></base-page-title>
    <div
      class="flex flex-row"
      v-if="
        !useviewHandlerAmounts.loadHandler.states.get_details &&
        Object.keys(useviewHandlerAmounts.filterForm.data.value).length > 0
      "
    >
      <span class="basis-9/12">
        <!-- electrcity card -->
        <base-card
          class="text-gray-400"
          :title="$translate('Electricity')"
          :loading="useviewHandlerAmounts.loadHandler.states.get_summary_electricity_usage"
        >
          <collapseElectricity v-if="useviewHandlerAmounts.dataElectricity.value" />
        </base-card>

        <!-- gas card -->
        <base-card
          class="text-gray-400 mt-4"
          :title="$translate('Gas')"
          :loading="useviewHandlerAmounts.loadHandler.states.get_summary_gas_usage"
        >
          <collapseGas v-if="useviewHandlerAmounts.dataGas.value" />
        </base-card>

        <!-- water card -->
        <base-card
          class="text-gray-400 mt-4"
          :title="$translate('Water')"
          :loading="useviewHandlerAmounts.loadHandler.states.get_summary_water_usage"
        >
          <collapseWater v-if="useviewHandlerAmounts.dataWater.value" />
        </base-card>

        <!-- heat card -->
        <base-card
          class="text-gray-400 mt-4"
          :title="$translate('Heat')"
          :loading="useviewHandlerAmounts.loadHandler.states.get_summary_heat_usage"
        >
          <collapseHeat v-if="useviewHandlerAmounts.dataHeat.value" />
        </base-card>

        <!-- heat cost card -->
        <base-card
          class="text-gray-400 mt-4"
          :title="$translate('HeatCost')"
          :loading="useviewHandlerAmounts.loadHandler.states.get_summary_heatcost_usage"
        >
          <collapseHeatCost v-if="useviewHandlerAmounts.dataHeatCost.value" />
        </base-card>
      </span>
      <span class="basis-3/12 ml-4">
        <!-- fiter -->
        <base-card class="text-gray-400 text-sm" title="Filter">
          <!-- start date -->
          <div class="flex flex-row items-center justify-between">
            <span>{{ $translate('Start date') }}</span>
            <span>{{ useFilter.dateFromEpochDay(useviewHandlerAmounts.filterForm.data.value.start_date) }}</span>
          </div>

          <!-- end date -->
          <div class="flex flex-row items-center justify-between">
            <span>{{ $translate('End date') }}</span>
            <span>{{ useFilter.dateFromEpochDay(useviewHandlerAmounts.filterForm.data.value.end_date) }}</span>
          </div>

          <div class="flex flex-row items-center justify-between">
            <span>{{ $translate('Interval') }}</span>
            <span>{{ $translate(useviewHandlerAmounts.filterForm.data.value.interval) }}</span>
          </div>

          <!-- labels -->
          <div class="flex flex-row items-center justify-between">
            <span>{{ $translate('Tags') }}</span>
            <span>
              <!-- if tags -->
              <div
                v-if="useviewHandlerAmounts.filterForm.data.value.tags.length > 0"
                class="flex flex-wrap flex-row-reverse gap-2 py-2"
              >
                <base-pill
                  class=""
                  variant="secondary"
                  v-for="(tag, index) in useviewHandlerAmounts.filterForm.data.value.tags"
                  :key="index"
                >
                  {{ tag.name }}
                </base-pill>
              </div>

              <!-- else -->
              <div v-else>-</div>
            </span>
          </div>

          <!-- modify -->
          <base-button class="w-full mt-2" @action="useviewHandlerAmounts.openFilterModal()">
            <span class="flex flex-row items-center justify-center">
              <featherIcon class="w-4 h-4 mr-2 my-auto" icon="FilterIcon" />
              <p class="my-auto">{{ $translate('Modify') }}</p>
            </span>
          </base-button>
        </base-card>
      </span>
    </div>

    <div v-else class="text-gray-500 ml-2">
      <baseLoader loading />
    </div>

    <!-- filter modal -->
    <baseModal
      v-if="useviewHandlerAmounts.showFilterModal.value"
      @close="useviewHandlerAmounts.closeFilterModal()"
      :title="`${$translate('Edit')}`"
      size="l"
    >
      <baseForm :handler="useviewHandlerAmounts.filterForm" />
      <template v-slot:buttons>
        <baseButton @action="useviewHandlerAmounts.applyFilter()" class="mr-2">{{ $translate('Edit') }}</baseButton>
      </template>
    </baseModal>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { computed, onMounted, onBeforeUnmount } from 'vue'
import viewHandlerAmounts from '@/use/viewHandlerAmounts'
import filterHandler from '@/use/filterHandler'
import featherIcon from '@/components/extended/feather/featherIcon.vue'
import collapseElectricity from './collapseElectricity.vue'
import collapseGas from './collapseGas.vue'
import collapseWater from './collapseWater.vue'
import collapseHeat from './collapseHeat.vue'
import collapseHeatCost from './collapseHeatCost.vue'

export default {
  setup() {
    const route = useRoute()
    const useviewHandlerAmounts = viewHandlerAmounts()
    const useFilter = filterHandler()
    const meta = computed(() => route.meta)

    const pageTitle = computed(() => {
      return meta.value.title
    })

    const pageSuffix = computed(() => {
      if (!useviewHandlerAmounts.loadHandler.states.get_details) {
        return `${useviewHandlerAmounts.scenario.value.description}`
      }
      return ''
    })

    onMounted(() => {
      useviewHandlerAmounts.getDetails({
        object_id: route.params.objectId,
      })
    })

    // Use the beforeUnmount hook to reset data when the user leaves the page
    onBeforeUnmount(() => {
      useviewHandlerAmounts.resetData()
    })

    return {
      meta,
      useviewHandlerAmounts,
      useFilter,
      pageTitle,
      pageSuffix,
    }
  },
  components: {
    featherIcon,
    collapseElectricity,
    collapseGas,
    collapseWater,
    collapseHeat,
    collapseHeatCost,
  },
}
</script>
