<template>
  <div class="flex flex-wrap py-2 gap-2">
    <base-pill class="" variant="secondary" v-for="(tag, index) in tags" :key="index">
      {{ tag.name }}
    </base-pill>
  </div>
</template>

<script>
export default {
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
  },
  setup() {},
}
</script>
