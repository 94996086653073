<template>
  <!-- levering -->
  <base-collapse class="mb-2" v-if="useviewHandlerAmounts.dataElectricity.value.Usage">
    <!-- header -->
    <template v-slot:header>
      <div class="flex flex-row">
        <span class="mr-2">{{ $translate('Supply') }}</span>
      </div>
    </template>
    <template v-slot:header-right>
      <div class="mr-2">
        {{ useFilter.filter(sumSummary(useviewHandlerAmounts.dataElectricity.value.Usage), 'kWh') }}
      </div>
    </template>

    <!-- body -->
    <template v-slot:body>
      <cardChart
        :options="useviewHandlerAmounts.chart_options_usage_electricity.value"
        :scatter="useviewHandlerAmounts.filterForm.data.value.interval === 'day'"
        :scatterOptions="useviewHandlerAmounts.chart_options_scatter_electricity.value"
        exportUnit="kWh"
        exportTitle="Simpleweg - Elektriciteit - Levering"
        :exportParserX="dutchDateParser"
      />

      <cardChart
        type="stockChart"
        v-if="useviewHandlerAmounts.filterForm.data.value.interval === 'hour'"
        :options="useviewHandlerAmounts.chart_options_usage_electricity_weekday.value"
        :scatter="false"
        :scatterOptions="{}"
        exportUnit="kWh"
        label="Gesommeerd dagprofiel"
        exportTitle="Simpleweg - Elektriciteit - Levering - Gesommeerd dagprofiel"
      />
    </template>
  </base-collapse>

  <!-- opwekking -->
  <base-collapse class="mb-2" v-if="useviewHandlerAmounts.dataElectricity.value.Generation">
    <!-- header -->
    <template v-slot:header>
      <div class="flex flex-row">
        <span class="mr-2">{{ $translate('Generation') }}</span>
      </div>
    </template>
    <template v-slot:header-right>
      <div class="mr-2">
        {{ useFilter.filter(sumSummary(useviewHandlerAmounts.dataElectricity.value.Generation), 'kWh') }}
      </div>
    </template>

    <!-- body -->
    <template v-slot:body>
      <cardChart
        :options="useviewHandlerAmounts.chart_options_generation_electricity.value"
        :scatter="useviewHandlerAmounts.filterForm.data.value.interval === 'day'"
        :scatterOptions="useviewHandlerAmounts.chart_options_scatter_electricity.value"
        exportUnit="kWh"
        exportTitle="Simpleweg - Elektriciteit - Opwekking"
        :exportParserX="dutchDateParser"
      />

      <cardChart
        type="stockChart"
        v-if="useviewHandlerAmounts.filterForm.data.value.interval === 'hour'"
        :options="useviewHandlerAmounts.chart_options_generation_electricity_weekday.value"
        :scatter="false"
        :scatterOptions="{}"
        exportUnit="kWh"
        label="Gesommeerd dagprofiel"
        exportTitle="Simpleweg - Elektriciteit - Opwekking - Gesommeerd dagprofiel"
      />
    </template>
  </base-collapse>

  <!-- teruglevering -->
  <base-collapse class="mb-2" v-if="useviewHandlerAmounts.dataElectricity.value.Return">
    <!-- header -->
    <template v-slot:header>
      <div class="flex flex-row">
        <span class="mr-2">{{ $translate('Return') }}</span>
      </div>
    </template>
    <template v-slot:header-right>
      <div class="mr-2">
        {{ useFilter.filter(sumSummary(useviewHandlerAmounts.dataElectricity.value.Return), 'kWh') }}
      </div>
    </template>

    <!-- body -->
    <template v-slot:body>
      <cardChart
        :options="useviewHandlerAmounts.chart_options_return_electricity.value"
        :scatter="useviewHandlerAmounts.filterForm.data.value.interval === 'day'"
        :scatterOptions="useviewHandlerAmounts.chart_options_scatter_electricity.value"
        exportUnit="kWh"
        exportTitle="Simpleweg - Elektriciteit - Teruglevering"
        :exportParserX="dutchDateParser"
      />

      <cardChart
        type="stockChart"
        v-if="useviewHandlerAmounts.filterForm.data.value.interval === 'hour'"
        :options="useviewHandlerAmounts.chart_options_return_electricity_weekday.value"
        :scatter="false"
        :scatterOptions="{}"
        exportUnit="kWh"
        label="Gesommeerd dagprofiel"
        exportTitle="Simpleweg - Elektriciteit - Teruglevering - Gesommeerd dagprofiel"
        :exportParserY="dutchNumberParser"
        :exportParserX="weekdayParser"
      />
    </template>
  </base-collapse>
</template>

<script>
import filterHandler from '@/use/filterHandler'
import cardChart from '@/views/components/cardChart.vue'
import viewHandlerAmounts from '@/use/viewHandlerAmounts'
import { dutchDateParser, weekdayParser, dutchNumberParser } from './parsers'

export default {
  props: {
    type: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const useFilter = filterHandler()
    const useviewHandlerAmounts = viewHandlerAmounts()

    function sumSummary(data) {
      if (data) {
        let sum = 0
        data.forEach((element) => {
          sum += element[1]
        })
        return sum
      }
      return 0
    }

    return {
      sumSummary,
      useFilter,
      useviewHandlerAmounts,
      dutchDateParser,
      weekdayParser,
      dutchNumberParser,
    }
  },
  components: {
    cardChart,
  },
}
</script>
