<template>
  <div class="p-6">
    <base-page-title :title="pageTitle" :suffix="pageSuffix" :icon="meta.titleIcon"></base-page-title>
    <div class="flex flex-row">
      <span class="basis-9/12">
        <!-- buy card -->
        <base-card
          class="text-gray-400"
          :title="$translate('Buy-in')"
          :loading="useviewHandlerCosts.loadHandler.states.summarize_costs"
        >
          <mainBuy
            v-if="Object.keys(useviewHandlerCosts.costData.value).length > 0"
            :data="useviewHandlerCosts.costData.value"
          />
          <span v-else>{{ $translate(useviewHandlerCosts.message.value) }}</span>
        </base-card>

        <!-- tax card -->
        <base-card
          class="text-gray-400 mt-4"
          :title="$translate('Taxes')"
          :loading="useviewHandlerCosts.loadHandler.states.summarize_tax"
        >
          <mainTax
            v-if="Object.keys(useviewHandlerCosts.taxData.value).length > 0"
            :data="useviewHandlerCosts.taxData.value"
          />
          <span v-else>{{ $translate(useviewHandlerCosts.message_tax.value) }}</span>
        </base-card>
      </span>
      <span class="basis-3/12 ml-4">
        <!-- fiter -->
        <base-card class="text-gray-400 text-sm" title="Filter">
          <!-- start date -->
          <div class="flex flex-row items-center justify-between">
            <span>{{ $translate('Start date') }}</span>
            <span>{{ useFilter.dateFromEpochDay(useviewHandlerCosts.filterForm.data.value.start_date) }}</span>
          </div>

          <!-- end date -->
          <div class="flex flex-row items-center justify-between">
            <span>{{ $translate('End date') }}</span>
            <span>{{ useFilter.dateFromEpochDay(useviewHandlerCosts.filterForm.data.value.end_date) }}</span>
          </div>

          <!-- labels -->
          <div class="flex flex-row items-center justify-between">
            <span>{{ $translate('Tags') }}</span>
            <span>
              <!-- if tags -->
              <div
                v-if="useviewHandlerCosts.filterForm.data.value.tags.length > 0"
                class="flex flex-wrap flex-row-reverse gap-2 py-2"
              >
                <base-pill
                  class=""
                  variant="secondary"
                  v-for="(tag, index) in useviewHandlerCosts.filterForm.data.value.tags"
                  :key="index"
                >
                  {{ tag.name }}
                </base-pill>
              </div>

              <!-- else -->
              <div v-else>-</div>
            </span>
          </div>

          <!-- modify -->
          <base-button class="w-full mt-2" @action="useviewHandlerCosts.openFilterModal()">
            <span class="flex flex-row items-center justify-center">
              <featherIcon class="w-4 h-4 mr-2 my-auto" icon="FilterIcon" />
              <p class="my-auto">{{ $translate('Modify') }}</p>
            </span>
          </base-button>

          <!-- Export -->
          <base-button class="w-full mt-2" @action="downloadExport()">
            <span class="flex flex-row items-center justify-center">
              <featherIcon class="w-4 h-4 mr-2 my-auto" icon="DownloadIcon" />
              <p class="my-auto">{{ $translate('Export') }}</p>
            </span>
          </base-button>
        </base-card>

        <!-- summary -->
        <base-card
          class="text-gray-400 mt-4"
          title="Samenvatting"
          :loading="useviewHandlerCosts.loadHandler.somethingLoading.value"
        >
          <box-summary v-if="!useviewHandlerCosts.loadHandler.states.summarize_costs" :handler="useviewHandlerCosts" />
        </base-card>
      </span>
    </div>

    <!-- filter modal -->
    <baseModal
      v-if="useviewHandlerCosts.showFilterModal.value"
      @close="useviewHandlerCosts.closeFilterModal()"
      :title="`${$translate('Edit')}`"
      size="l"
    >
      <baseForm :handler="useviewHandlerCosts.filterForm" />
      <template v-slot:buttons>
        <baseButton @action="useviewHandlerCosts.applyFilter()" class="mr-2">{{ $translate('Edit') }}</baseButton>
      </template>
    </baseModal>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { computed, onMounted } from 'vue'
import viewHandlerCosts from '@/use/viewHandlerCosts'
import filterHandler from '@/use/filterHandler'
import featherIcon from '@/components/extended/feather/featherIcon.vue'
import mainBuy from './buy/mainBuy.vue'
import mainTax from './tax/mainTax.vue'
import boxSummary from '@/views/costs/boxSummary.vue'
import exportCostsHandler from '@/use/exportCostsHandler'

export default {
  setup() {
    const route = useRoute()
    const useviewHandlerCosts = viewHandlerCosts()
    const useFilter = filterHandler()
    const meta = computed(() => route.meta)
    const useExportCosts = exportCostsHandler()

    const pageTitle = computed(() => {
      return meta.value.title
    })

    const pageSuffix = computed(() => {
      if (!useviewHandlerCosts.loadHandler.states.get_details) {
        return `${useviewHandlerCosts.scenario.value.description}`
      }
      return ''
    })

    onMounted(() => {
      useviewHandlerCosts.getDetails({
        object_id: route.params.objectId,
      })
    })

    function downloadExport() {
      useExportCosts.exporter(
        useviewHandlerCosts.filterForm.data.value,
        useviewHandlerCosts.costData.value,
        useviewHandlerCosts.taxData.value,
      )
    }

    return {
      meta,
      useviewHandlerCosts,
      useFilter,
      downloadExport,
      pageSuffix,
      pageTitle,
    }
  },
  components: {
    featherIcon,
    mainBuy,
    mainTax,
    boxSummary,
  },
}
</script>
