<template>
  <!-- split sidebar and rest -->
  <div class="flex">
    <!-- sidebar -->
    <transition
      enter-active-class="duration-200 ease-out"
      enter-from-class="transform opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="duration-200 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="transform opacity-0"
    >
      <div v-if="sidebar.show.value" :class="`w-72 h-screen ${surfaceColour} drop-shadow-xl`">
        <sidebar />
      </div>
    </transition>

    <!-- content -->
    <div :class="`flex flex-col flex-grow w-full h-screen ${backgroundColor}`">
      <navigationBar class="shadow-lg shadow-slate-100" />
      <span class="overflow-y-auto">
        <slot></slot>
      </span>
    </div>
  </div>
</template>

<script>
// import { computed } from 'vue'
import navigationBar from '@/components/navigationBar.vue'
import useTheme from '@/use/themeHandler'
import sidebar from './sidebar/sideBar.vue'
import useSidebar from '@/use/sidebarHandler'

export default {
  setup() {
    const themeHandler = useTheme()
    const surfaceColour = themeHandler.getBackgroundColor('surface')
    const backgroundColor = themeHandler.getBackgroundColor('background')
    const sidebar = useSidebar()

    // const showClass = computed(() => {
    //   return sidebar.show.value ? 'origin-left scale-x-72' : 'origin-left scale-x-0'
    // })

    return {
      backgroundColor,
      surfaceColour,
      sidebar,
      // showClass,
    }
  },
  components: {
    navigationBar,
    sidebar,
  },
}
</script>
