<template>
  <div class="p-6">
    <base-page-title :title="pageTitle" :suffix="pageSuffix" :icon="meta.titleIcon"></base-page-title>

    <div class="flex flex-row">
      <span class="basis-1/2">
        <base-card class="text-gray-400" :loading="useViewContractDetails.loadHandler.states.get_details">
          <!-- edit button -->
          <base-button
            v-if="useContentGuard.canSee('object:write')"
            class="mb-2"
            @action="useViewContractDetails.openEdit()"
            ><featherIcon class="w-4 h-4" icon="EditIcon"
          /></base-button>
          <base-list
            :data="useViewContractDetails.contract.value"
            :fields="useViewContractDetails.contractFields.value"
          />
        </base-card>
      </span>
      <span class="basis-1/2 ml-4">
        <!-- right panel -->
      </span>
    </div>

    <div class="flex flex-row mt-6">
      <base-page-title title="NodesContracts"></base-page-title>
    </div>

    <!-- nodes in contract -->
    <baseTable
      :fields="useViewContractDetails.nodeFields.value"
      :records="useViewContractDetails.contract.value.nodes"
      :records-per-page="25"
      :add-button="useContentGuard.canSee('object:write')"
      :edit-button="false"
      :delete-button="useContentGuard.canSee('object:write')"
      :clickAble="true"
      :clickCallback="goToNode"
      :loading="useViewContractDetails.loadHandler.states.get_details"
      :hover-effect="true"
      leadIcon="CircleIcon"
      :search="false"
      @add="useViewContractDetails.openAddNodes()"
      @delete="useViewContractDetails.unAssignNode"
      :sortable="true"
    />
  </div>

  <!-- edit form -->
  <base-modal
    v-if="useViewContractDetails.showEdit.value"
    @close="useViewContractDetails.closeEdit()"
    :title="`${$translate('Edit')}`"
  >
    <baseForm :handler="useViewContractDetails.editForm" />
    <template v-slot:buttons>
      <baseButton
        @action="useViewContractDetails.edit()"
        class="mr-2"
        :loading="useViewContractDetails.loadHandler.states.edit_contract"
        >{{ $translate('Save') }}</baseButton
      >
    </template>
  </base-modal>

  <!-- add nodes modal -->
  <base-modal
    v-if="useViewContractDetails.showAddNodes.value"
    @close="useViewContractDetails.closeAddNodes()"
    :title="`${$translate('Add')}`"
    size="l"
  >
    <baseTable
      :fields="useViewContractDetails.nodeFields.value"
      :records="useViewContractDetails.unassigned_nodes.value"
      :records-per-page="15"
      :add-button="false"
      :edit-button="false"
      :delete-button="false"
      :selectable="true"
      :loading="useViewContractDetails.loadHandler.states.get_unassigned_contract"
      :hover-effect="true"
      leadIcon="CircleIcon"
      :search="true"
      @add="useViewContractDetails.openAddNodes()"
      @selection="useViewContractDetails.setSelectedUnassignedNodes"
      :sortable="true"
    />

    <template v-slot:buttons>
      <baseButton
        @action="useViewContractDetails.assignNodes()"
        class="mr-2"
        :loading="useViewContractDetails.loadHandler.states.assign_to_cluster"
        :disabled="useViewContractDetails.unassigned_nodes_selected.value.length === 0"
        >{{ $translate('Save') }}</baseButton
      >
    </template>
  </base-modal>
</template>

<script>
import { useRoute } from 'vue-router'
import { computed, onMounted } from 'vue'
import viewHandlerContractDetails from '@/use/viewHandlerContractDetails'
import featherIcon from '@/components/extended/feather/featherIcon.vue'
import router from '@/router'
import contentGuardHandler from '@/use/contentGuardHandler'

export default {
  setup() {
    const route = useRoute()
    const meta = computed(() => route.meta)
    const useViewContractDetails = viewHandlerContractDetails()
    const useContentGuard = contentGuardHandler()

    onMounted(() => {
      useViewContractDetails.getDetails({
        object_id: route.params.objectId,
      })
    })

    const pageTitle = computed(() => {
      return meta.value.title
    })

    const pageSuffix = computed(() => {
      if (!useViewContractDetails.loadHandler.states.get_details) {
        return `${useViewContractDetails.contract.value.code}`
      }
      return ''
    })

    function goToNode(payload) {
      router.push({
        name: 'node_details',
        params: { objectId: payload.object_id },
      })
    }

    return {
      meta,
      pageTitle,
      useViewContractDetails,
      goToNode,
      pageSuffix,
      useContentGuard,
    }
  },
  components: {
    featherIcon,
  },
}
</script>
