<template>
  <base-tooltip :title="$translate('Tiers')" size="l">
    <div v-for="(tax_summary, year) in data.tax_summary" :key="year" class="mb-6">
      <!-- year -->
      <div class="text-lg font-semibold">{{ year }}</div>

      <base-table
        :fields="fields"
        :records="data.tax_summary[year]"
        :records-per-page="25"
        :loading="false"
        :hover-effect="false"
        :search="false"
        :pagination="false"
      >
        <template v-slot:footer>
          <tr class="text-xs text-gray-700 bg-gray-100 font-semibold">
            <td th scope="col" class="px-6 py-3"></td>
            <td th scope="col" class="px-6 py-3"></td>
            <td th scope="col" class="px-6 py-3">
              <span v-if="type === 'Electricity'">{{
                useFilter.filter(data.usage_production_balance[year], 'kWh')
              }}</span>
              <span v-else>{{ useFilter.filter(data.usage[year], 'm3') }}</span>
            </td>
            <td th scope="col" class="px-6 py-3"></td>
            <td th scope="col" class="px-6 py-3">
              <span v-if="type === 'Electricity'">{{
                useFilter.filter(summarizeTax(data.tax_summary, year, 'ode_commercial'), 'roundDynamicCurrency')
              }}</span>
              <span v-if="type === 'Gas'">{{
                useFilter.filter(summarizeTax(data.tax_summary, year, 'eb'), 'roundDynamicCurrency')
              }}</span>
              <span v-if="type === 'GasReduced'">{{
                useFilter.filter(summarizeTax(data.tax_summary, year, 'eb_reduced'), 'roundDynamicCurrency')
              }}</span>
              <span v-if="type === 'Water'">{{
                useFilter.filter(summarizeTax(data.tax_summary, year, 'bol'), 'roundDynamicCurrency')
              }}</span>
            </td>
            <td th scope="col" class="px-6 py-3"></td>
            <td th scope="col" class="px-6 py-3">
              <span v-if="type === 'Electricity'">{{
                useFilter.filter(summarizeTax(data.tax_summary, year, 'eb_commercial'), 'roundDynamicCurrency')
              }}</span>
              <span v-if="type === 'Gas'">{{
                useFilter.filter(summarizeTax(data.tax_summary, year, 'ode'), 'roundDynamicCurrency')
              }}</span>
              <span v-if="type === 'GasReduced'">{{
                useFilter.filter(summarizeTax(data.tax_summary, year, 'ode_reduced'), 'roundDynamicCurrency')
              }}</span>
            </td>
          </tr>
        </template>
      </base-table>
    </div>
  </base-tooltip>
</template>

<script>
// import featherIcon from '@/components/extended/feather/featherIcon.vue'
import { computed } from 'vue'
import filterHandler from '@/use/filterHandler'

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: 'Electricity',
    },
  },
  setup(props) {
    const useFilter = filterHandler()

    const fields = computed(() => {
      const selector = {
        Electricity: [
          {
            key: 'tier_start',
            type: 'string',
            filter: 'kWh',
            label: 'Tier start',
          },
          {
            key: 'tier_end',
            type: 'string',
            filter: 'kWh',
            label: 'Tier end',
          },
          {
            key: 'tier_usage',
            type: 'number',
            filter: 'kWh',
            label: 'Tier usage',
          },
          {
            key: 'ode_commercial_rate',
            type: 'number',
            filter: 'toCurrencyNoRound',
            label: 'ODE commercial rate',
          },
          {
            key: 'ode_commercial',
            type: 'number',
            filter: 'roundDynamicCurrency',
            label: 'ODE commercial',
          },
          {
            key: 'eb_commercial_rate',
            type: 'number',
            filter: 'toCurrencyNoRound',
            label: 'EB commercial rate',
          },
          {
            key: 'eb_commercial',
            type: 'number',
            filter: 'roundDynamicCurrency',
            label: 'EB commercial',
          },
        ],
        Gas: [
          {
            key: 'tier_start',
            type: 'string',
            filter: 'm3',
            label: 'Tier start',
          },
          {
            key: 'tier_end',
            type: 'string',
            filter: 'm3',
            label: 'Tier end',
          },
          {
            key: 'tier_usage',
            type: 'number',
            filter: 'm3',
            label: 'Tier usage',
          },
          {
            key: 'eb_rate',
            type: 'number',
            filter: 'toCurrencyNoRound',
            label: 'EB rate',
          },
          {
            key: 'eb',
            type: 'number',
            filter: 'roundDynamicCurrency',
            label: 'EB',
          },
          {
            key: 'ode_rate',
            type: 'number',
            filter: 'toCurrencyNoRound',
            label: 'ODE rate',
          },
          {
            key: 'ode',
            type: 'number',
            filter: 'roundDynamicCurrency',
            label: 'ODE',
          },
        ],
        GasReduced: [
          {
            key: 'tier_start',
            type: 'string',
            filter: 'm3',
            label: 'Tier start',
          },
          {
            key: 'tier_end',
            type: 'string',
            filter: 'm3',
            label: 'Tier end',
          },
          {
            key: 'tier_usage',
            type: 'number',
            filter: 'm3',
            label: 'Tier usage',
          },
          {
            key: 'eb_reduced_rate',
            type: 'number',
            filter: 'toCurrencyNoRound',
            label: 'EB reduced rate',
          },
          {
            key: 'eb_reduced',
            type: 'number',
            filter: 'roundDynamicCurrency',
            label: 'EB reduced',
          },
          {
            key: 'ode_reduced_rate',
            type: 'number',
            filter: 'toCurrencyNoRound',
            label: 'ODE reduced rate',
          },
          {
            key: 'ode_reduced',
            type: 'number',
            filter: 'roundDynamicCurrency',
            label: 'ODE reduced',
          },
        ],
        Water: [
          {
            key: 'tier_start',
            type: 'string',
            filter: 'm3',
            label: 'Tier start',
          },
          {
            key: 'tier_end',
            type: 'string',
            filter: 'm3',
            label: 'Tier end',
          },
          {
            key: 'tier_usage',
            type: 'number',
            filter: 'm3',
            label: 'Tier usage',
          },
          {
            key: 'bol_rate',
            type: 'number',
            filter: 'toCurrencyNoRound',
            label: 'BOL rate',
          },
          {
            key: 'bol',
            type: 'number',
            filter: 'roundDynamicCurrency',
            label: 'BOL',
          },
        ],
      }

      return selector[props.type]
    })

    function summarizeTax(summary, year, key) {
      let total = 0
      summary[year].forEach((obj) => {
        total += obj[key]
      })

      return total
    }

    return {
      useFilter,
      fields,
      summarizeTax,
    }
  },
  components: {
    // featherIcon,
  },
}
</script>
