<template>
  <span class="my-auto w-full">
    <span class="flex flex-row">
      <!-- input box -->
      <input
        disabled
        :value="useFilter.dateFromEpochDay(value)"
        class="bg-white appearance-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 text-sm disabled:bg-gray-100"
      />

      <!-- add -->
      <base-button-drop-down
        class="mr-2"
        variant="primary"
        :handler="selectorDropDown"
        :display-text="''"
        :translate="false"
        icon="CalendarIcon"
        size="small"
        :alignRight="true"
      >
        <div class="flex flex-row p-2">
          <!-- month -->
          <base-button-drop-down
            class="w-48"
            :handler="monthDropDown"
            :displayText="usebaseDateInputHandler.monthNames[usebaseDateInputHandler.selectedMonth.value]"
            variant="neutral"
            :alignRight="false"
          >
            <base-button-drop-down-item
              class="w-full"
              v-for="month in usebaseDateInputHandler.months.value"
              :key="month.number"
              @click.stop="selectMonth(month.number - 1)"
            >
              {{ $translate(month.name) }}
            </base-button-drop-down-item></base-button-drop-down
          >

          <!-- year -->
          <base-button-drop-down
            class=""
            :handler="yearDropDown"
            :display-text="String(usebaseDateInputHandler.selectedYear.value)"
            variant="neutral"
            :translate="false"
          >
            <base-button-drop-down-item
              v-for="year in usebaseDateInputHandler.years.value"
              :key="year"
              @click.stop="selectYear(year)"
            >
              {{ year }}
            </base-button-drop-down-item>
          </base-button-drop-down>
        </div>

        <div class="grid grid-cols-7 gap-1 text-xs text-gray-300 p-2">
          <div v-for="day in usebaseDateInputHandler.weekdays" :key="day" class="text-center p-2">{{ day }}</div>
        </div>
        <div class="grid grid-cols-7 gap-1 bg-white p-2">
          <!-- offset cells for day alignment -->
          <div
            v-for="n in usebaseDateInputHandler.dayOffset()"
            :key="`empty-${n}`"
            class="text-center rounded !p-2"
          ></div>

          <!-- Adjusts gap between grid cells -->
          <base-button-drop-down-item
            v-for="day in usebaseDateInputHandler.days.value"
            :key="day.number"
            class="text-center rounded !p-2"
            :class="{
              'font-bold': usebaseDateInputHandler.isWeekend(day.date),
              'border rounded': usebaseDateInputHandler.isToday(day.date),
              'bg-sky-700 text-white': usebaseDateInputHandler.isSelected(day.date),
            }"
            @click="selectDay(day.number)"
          >
            {{ day.number }}
          </base-button-drop-down-item>
        </div>
        <div class="flex flex-row-reverse pb-4 pr-4">
          <baseButton @action="closeSelector()" size="small">Kies</baseButton>
        </div>
      </base-button-drop-down>
    </span>
  </span>
</template>

<script>
import { computed } from 'vue'
import filterHandler from '@/use/filterHandler'
import baseButtonDropDownHandler from '@/use/baseButtonDropDownHandler'
import baseDateInputHandler from './baseDateInputHandler'

export default {
  props: {
    keyField: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    fieldConfig: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Number,
      default: 0,
    },
    displayTemplate: {
      type: Object,
      default: () => {},
    },
    modalSize: {
      type: String,
      default: 'm',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    alignRight: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['add', 'remove', 'itemClick', 'edit'],
  setup(props, { emit }) {
    // handler
    const useFilter = filterHandler()
    const selectorDropDown = baseButtonDropDownHandler()
    const monthDropDown = baseButtonDropDownHandler()
    const yearDropDown = baseButtonDropDownHandler()
    const usebaseDateInputHandler = baseDateInputHandler(props.value)

    function closeSelector() {
      // const payload = JSON.parse(JSON.stringify(usebaseDateInputHandler.getResult()))
      // emit('add', payload)
      selectorDropDown.open.value = false
    }

    function sync() {
      const payload = JSON.parse(JSON.stringify(usebaseDateInputHandler.getResult()))
      emit('add', payload)
    }

    const disabledStyle = computed(() => {
      if (props.disabled) {
        return 'bg-gray-100'
      }
      return ''
    })

    function selectDay(day) {
      usebaseDateInputHandler.selectedDay.value = day
      sync()
    }

    function selectMonth(month) {
      usebaseDateInputHandler.selectedMonth.value = month
      monthDropDown.open.value = false
      sync()
    }

    const selectYear = (year) => {
      usebaseDateInputHandler.selectedYear.value = year
      yearDropDown.open.value = false
      sync()
    }

    return {
      closeSelector,
      disabledStyle,
      useFilter,
      selectorDropDown,
      usebaseDateInputHandler,
      selectDay,
      monthDropDown,
      selectMonth,
      yearDropDown,
      selectYear,
    }
  },
}
</script>
