import ExcelJS from 'exceljs'
import translationHandler from '@/plugins/i18n/translationHandler'
import filterHandler from '@/use/filterHandler'
import { useAuth0 } from '@auth0/auth0-vue'

export default () => {
  const useTranslate = translationHandler()
  const useFilter = filterHandler()
  const auth = useAuth0()

  function valueOrDash(container, key) {
    if (container && key in container) {
      return container[key]
    }
    return '-'
  }

  function valueOrDashRate(container, key) {
    if (container && key in container) {
      return container[key] * 100
    }
    return '-'
  }

  function getDefaultTaxTotals() {
    return {
      tax_credit_total: '-',
      tax_total_electricity: '-',
      tax_total_gas: '-',
      tax_total_water: '-',
      tax_total_total: '-',
      tax_total_vat_electricity: '-',
      tax_total_vat_gas: '-',
      tax_total_vat_water: '-',
      tax_total_vat_electricity_rate: '-',
      tax_total_vat_gas_rate: '-',
      tax_total_vat_water_rate: '-',
      tax_total_total_vat: 0,
      tax_total_vat_heat: '-',
      tax_total_vat_heat_rate: '-',
      tax_total_vat_heatcost: '-',
      tax_total_vat_heatcost_rate: '-',
    }
  }

  function parseTags(tags) {
    let tagString = ''

    if (tags) {
      tags.forEach((tag, index) => {
        if (index === tags.length - 1) {
          tagString += `${tag.name}`
        } else {
          tagString += `${tag.name}, `
        }
      })
    }

    return tagString
  }

  function exporter(filterData, costsData, taxData) {
    console.log(filterData)
    console.log(costsData)
    console.log(taxData)

    // build xlsx
    const startDateReadable = useFilter.filter(filterData.start_date, 'dateFromEpochDay')
    const endDateReadable = useFilter.filter(filterData.end_date, 'dateFromEpochDay')
    const currentDateReadable = useFilter.filter(new Date(), 'dateFromEpochDay')
    const exportFilename = `SimpleWEG_kosten_${startDateReadable}_${endDateReadable}.xlsx`
    const userName = auth.user.value.name

    const options = {
      filename: exportFilename,
      useStyles: true,
      useSharedStrings: true,
    }

    // create workbook and sheets
    const workbook = new ExcelJS.Workbook(options)
    const worksheetInfo = workbook.addWorksheet(useTranslate.t('Info'))
    worksheetInfo.getColumn('B').width = 20
    worksheetInfo.getColumn('C').width = 25
    const worksheetSummary = workbook.addWorksheet(useTranslate.t('Summary'))
    const worksheetBuy = workbook.addWorksheet(useTranslate.t('Buy-in'))
    const worksheetTax = workbook.addWorksheet(useTranslate.t('Taxes'))

    // Fill infopage
    const tagString = filterData.tags.map((element) => element.name).join(', ')
    const tagSDtringDocument = tagString || 'Geen'
    worksheetInfo.getCell('B2').value = `Kostenoverzicht (export)`
    worksheetInfo.getCell('B2').font = { size: 16 }
    worksheetInfo.getCell('B3').value =
      'Dit document is automatisch gegenereerd via de exportfunctie op de kostenpagina van SimpleWEG. De onderstaande instellingen zijn gebruikt.'
    worksheetInfo.getCell('B5').value = useTranslate.t('Start date')
    worksheetInfo.getCell('C5').value = startDateReadable
    worksheetInfo.getCell('B6').value = useTranslate.t('End date')
    worksheetInfo.getCell('C6').value = endDateReadable
    worksheetInfo.getCell('B7').value = useTranslate.t('Tags')
    worksheetInfo.getCell('C7').value = tagSDtringDocument
    worksheetInfo.getCell('B8').value = useTranslate.t('Exported on')
    worksheetInfo.getCell('C8').value = currentDateReadable
    worksheetInfo.getCell('B9').value = useTranslate.t('Exported by')
    worksheetInfo.getCell('C9').value = userName

    // fill buy-in page
    worksheetBuy.getCell('B2').value = `${useTranslate.t('Buy-in')}`
    worksheetBuy.getCell('B2').font = { size: 16 }
    const tableAnchorRow = 4
    const tableAnchorColumn = 'B'
    const tableAnchor = tableAnchorColumn + tableAnchorRow
    const tableRowsBuy = []
    const tableColumnsBuy = [
      {
        name: useTranslate.t('Contract'),
        totalsRowLabel: useTranslate.t('Total'),
        filterButton: false,
        type: '',
      },
      {
        name: useTranslate.t('Identification'),
        totalsRowLabel: '',
        filterButton: false,
        type: '',
      },
      {
        name: useTranslate.t('Description'),
        totalsRowLabel: '',
        filterButton: false,
        type: '',
      },
      {
        name: useTranslate.t('Tags'),
        totalsRowLabel: '',
        filterButton: false,
        type: '',
      },
      {
        name: useTranslate.t('Medium'),
        totalsRowLabel: '',
        filterButton: false,
        type: '',
      },
      {
        name: useTranslate.t('Unit'),
        totalsRowLabel: '',
        filterButton: false,
        type: '',
      },
      {
        name: useTranslate.t('Usage'),
        totalsRowLabel: '',
        filterButton: false,
        type: 'usage',
      },
      {
        name: useTranslate.t('Rate'),
        totalsRowLabel: '',
        filterButton: false,
        type: 'rate',
      },
      {
        name: useTranslate.t('Peak buy-in'),
        totalsRowLabel: '',
        filterButton: false,
        type: 'usage',
      },
      {
        name: useTranslate.t('Peak return'),
        totalsRowLabel: '',
        filterButton: false,
        type: 'usage',
      },
      {
        name: useTranslate.t('Buy-in balance peak'),
        totalsRowLabel: '',
        filterButton: false,
        type: 'usage',
      },
      {
        name: useTranslate.t('Peak rate'),
        totalsRowLabel: '',
        filterButton: false,
        type: 'rate',
      },
      {
        name: useTranslate.t('Peak costs'),
        totalsRowLabel: '',
        filterButton: false,
        type: 'currency',
      },
      {
        name: useTranslate.t('Off-peak buy-in'),
        totalsRowLabel: '',
        filterButton: false,
        type: 'usage',
      },
      {
        name: useTranslate.t('Off-peak return'),
        totalsRowLabel: '',
        filterButton: false,
        type: 'usage',
      },
      {
        name: useTranslate.t('Buy-in balance off-peak'),
        totalsRowLabel: '',
        filterButton: false,
        type: 'usage',
      },
      {
        name: useTranslate.t('Off-peak rate'),
        totalsRowLabel: '',
        filterButton: false,
        type: 'rate',
      },
      {
        name: useTranslate.t('Off-peak costs'),
        totalsRowLabel: '',
        filterButton: false,
        type: 'currency',
      },
      {
        name: useTranslate.t('Total'),
        totalsRowLabel: '',
        filterButton: false,
        totalsRowFunction: 'sum',
        type: 'currency',
      },
    ]

    Object.entries(costsData.contracts).forEach(([, contract]) => {
      const contractInfo = contract.contractinfo
      const contractCostSummary = contract.cost_summary

      const contractCostSummaryRecords = contractCostSummary.records
      const contractType = contractInfo.type
      const contractTypei18n = useTranslate.t(contractInfo.type)
      const contractProvider = contractInfo.provider
      const contractCode = contractInfo.code
      const contractStartDate = useFilter.filter(contractInfo.start_date, 'dateFromEpochDay')
      const contractEndDate = useFilter.filter(contractInfo.end_date, 'dateFromEpochDay')

      const contractTitle = `${contractProvider} (${contractCode}) ${contractStartDate} - ${contractEndDate}`
      // loop contract nodes
      contractCostSummaryRecords.forEach((element) => {
        if (contractType === 'Electricity') {
          const {
            provider_identifier,
            unit,
            usage_peak,
            production_peak,
            usage_production_balance_peak,
            costs_peak,
            usage_off_peak,
            production_off_peak,
            usage_production_balance_off_peak,
            costs_off_peak,
            costs_total,
            description,
            tags,
          } = element

          let displayPeakRate = null
          let displayOffPeakRate = null
          // eslint-disable-next-line camelcase
          if (usage_production_balance_peak >= 0) {
            displayPeakRate = contractInfo.electricity_peak_rate
          } else {
            displayPeakRate = contractInfo.electricity_peak_return_rate
          }
          // eslint-disable-next-line camelcase
          if (usage_production_balance_off_peak >= 0) {
            displayOffPeakRate = contractInfo.electricity_offpeak_rate
          } else {
            displayOffPeakRate = contractInfo.electricity_offpeak_return_rate
          }

          const tagsParsed = parseTags(tags)

          tableRowsBuy.push([
            // eslint-disable-next-line camelcase
            contractTitle,
            provider_identifier,
            description,
            tagsParsed,
            contractTypei18n,
            unit,
            null,
            null,
            usage_peak,
            production_peak,
            usage_production_balance_peak,
            displayPeakRate,
            costs_peak,
            usage_off_peak,
            production_off_peak,
            usage_production_balance_off_peak,
            displayOffPeakRate,
            costs_off_peak,
            costs_total,
          ])
        } else if (contractType === 'Gas') {
          const { provider_identifier, usage, unit, costs, costs_total, description, tags } = element
          const tagsParsed = parseTags(tags)
          tableRowsBuy.push([
            // eslint-disable-next-line camelcase
            contractTitle,
            provider_identifier,
            description,
            tagsParsed,
            contractTypei18n,
            unit,
            usage,
            contractInfo.gas_rate,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            costs,
            costs_total,
          ])
        } else if (contractType === 'Water') {
          const { provider_identifier, usage, unit, costs_total, description, tags } = element
          const tagsParsed = parseTags(tags)
          tableRowsBuy.push([
            // eslint-disable-next-line camelcase
            contractTitle,
            provider_identifier,
            description,
            tagsParsed,
            contractTypei18n,
            unit,
            usage,
            contractInfo.water_rate,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            costs_total,
          ])
        } else if (contractType === 'Heat') {
          const { provider_identifier, usage, unit, costs_total, description, tags } = element
          const tagsParsed = parseTags(tags)
          tableRowsBuy.push([
            contractTitle,
            provider_identifier,
            description,
            tagsParsed,
            contractTypei18n,
            unit,
            usage,
            contractInfo.heat_rate,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            costs_total,
          ])
        } else if (contractType === 'HeatCost') {
          const { provider_identifier, usage, unit, costs_total, description, tags } = element
          const tagsParsed = parseTags(tags)
          tableRowsBuy.push([
            contractTitle,
            provider_identifier,
            description,
            tagsParsed,
            contractTypei18n,
            unit,
            usage,
            contractInfo.heatcost_rate,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            costs_total,
          ])
        }
      })
    })
    // ws.getColumn(3).numFmt = '$#,##0.00;[Red]-$#,##0.00' currency
    // Write table
    worksheetBuy.addTable({
      name: 'inkoop',
      ref: tableAnchor,
      headerRow: true,
      totalsRow: true,
      style: {
        theme: 'TableStyleLight1',
        showRowStripes: true,
      },
      columns: tableColumnsBuy,
      rows: tableRowsBuy,
    })

    // loop tablecolumns
    tableColumnsBuy.forEach((element, index) => {
      const dingen = worksheetBuy.getColumn(index + 2)
      if (index !== 0) {
        dingen.alignment = { vertical: 'middle', horizontal: 'center' }
      }
      if (element.type === 'currency') {
        dingen.numFmt = '€ #,##0.00;[Color10]-€ #,##0.00'
      } else if (element.type === 'rate') {
        dingen.numFmt = '€ #,##0.00############;[Color10]-€ #,##0.00############'
      } else if (element.type === 'usage') {
        dingen.numFmt = '#,#0;[Color10]-#,#0'
      }
    })

    // Loop sheetcolumns
    worksheetBuy.columns.forEach((column) => {
      const columnNumber = column.number

      if (columnNumber !== 5) {
        let dataMax = 0
        column.eachCell((cell) => {
          const width = cell.value ? cell.value.toString().length : 0
          if (width > dataMax) {
            dataMax = width
          }
        })
        if (dataMax) {
          // eslint-disable-next-line no-param-reassign
          column.width = dataMax
        } else {
          // eslint-disable-next-line no-param-reassign
          column.width = 10
        }
      }
    })

    // fill tax page
    worksheetTax.getCell('B2').value = `${useTranslate.t('Taxes')}`
    worksheetTax.getCell('B2').font = { size: 16 }
    // CategorieType ID Verbruik Verlaagd tarief Staffel Tarief EB EB Tarief ODE ODE Tarief BOL BOL Heffingskorting Totaal
    const tableColumnsTax = [
      {
        name: useTranslate.t('Identification'),
        totalsRowLabel: useTranslate.t('Total'),
        filterButton: false,
        type: '',
      },
      {
        name: useTranslate.t('Description'),
        totalsRowLabel: useTranslate.t('Total'),
        filterButton: false,
        type: '',
      },
      {
        name: useTranslate.t('Tags'),
        totalsRowLabel: '',
        filterButton: false,
        type: '',
      },
      {
        name: useTranslate.t('Category'),
        totalsRowLabel: '',
        filterButton: false,
        type: '',
      },
      {
        name: useTranslate.t('Medium'),
        totalsRowLabel: '',
        filterButton: false,
        type: '',
      },
      {
        name: useTranslate.t('Year'),
        totalsRowLabel: '',
        filterButton: false,
        type: '',
      },
      {
        name: useTranslate.t('Tier start'),
        totalsRowLabel: '',
        filterButton: false,
        type: '',
      },
      {
        name: useTranslate.t('Tier end'),
        totalsRowLabel: '',
        filterButton: false,
        type: '',
      },
      {
        name: useTranslate.t('Buy-in balance'),
        totalsRowLabel: '',
        filterButton: false,
        type: 'usage',
      },
      {
        name: useTranslate.t('EB rate'),
        totalsRowLabel: '',
        filterButton: false,
        type: 'rate',
      },
      {
        name: useTranslate.t('EB'),
        totalsRowLabel: '',
        filterButton: false,
        type: 'currency',
      },
      {
        name: useTranslate.t('EB reduced rate'),
        totalsRowLabel: '',
        filterButton: false,
        type: 'rate',
      },
      {
        name: useTranslate.t('EB reduced'),
        totalsRowLabel: '',
        filterButton: false,
        type: 'currency',
      },
      {
        name: useTranslate.t('ODE rate'),
        totalsRowLabel: '',
        filterButton: false,
        type: 'rate',
      },
      {
        name: useTranslate.t('ODE'),
        totalsRowLabel: '',
        filterButton: false,
        type: 'currency',
      },
      {
        name: useTranslate.t('ODE reduced rate'),
        totalsRowLabel: '',
        filterButton: false,
        type: 'rate',
      },
      {
        name: useTranslate.t('ODE reduced'),
        totalsRowLabel: '',
        filterButton: false,
        type: 'currency',
      },
      {
        name: useTranslate.t('BOL rate'),
        totalsRowLabel: '',
        filterButton: false,
        type: 'rate',
      },
      {
        name: useTranslate.t('BOL'),
        totalsRowLabel: '',
        filterButton: false,
        type: 'currency',
      },
      {
        name: useTranslate.t('Tax credit'),
        totalsRowLabel: '',
        filterButton: false,
        type: 'currency',
      },
      {
        name: useTranslate.t('Total'),
        totalsRowLabel: '',
        filterButton: false,
        totalsRowFunction: 'sum',
        type: 'currency',
      },
    ]
    const tableRowsTax = []
    const boldRowIndexes = []
    const taxSummaryNodes = taxData.nodes
    const taxSummaryClusters = taxData.clusters
    const summaries = {
      clusters: {
        mainObject: taxSummaryClusters,
        idKey: 'cluster_name',
        name: useTranslate.t('Cluster'),
      },
      nodes: {
        mainObject: taxSummaryNodes,
        idKey: 'provider_identifier',
        name: useTranslate.t('Node'),
      },
    }
    Object.entries(summaries).forEach(([, summary]) => {
      const summaryData = summary.mainObject
      const { idKey, name } = summary
      if (summaryData) {
        Object.entries(summaryData).forEach(([, element]) => {
          const tags = parseTags(element.tags)
          const taxSummary = element.tax_summary
          const taxDiscount = element.discount
          const taxTotals = element.tax_totals
          const mainType = useTranslate.t(element.medium)
          const identification = element[idKey]
          const description = element.description
          const taxCredit = taxTotals.tax_credit_total
          let superTotal = 0
          if (mainType === 'Elektriciteit') {
            superTotal = taxTotals.tax_total_electricity
          } else if (mainType === 'Gas') {
            superTotal = taxTotals.tax_total_gas
          } else if (mainType === 'Water') {
            superTotal = taxTotals.tax_total_water
          }
          // loop years
          Object.entries(taxSummary).forEach(([year, tiers]) => {
            // loop tiers
            tiers.forEach((tier) => {
              if (mainType === 'Elektriciteit') {
                const {
                  eb_commercial,
                  eb_commercial_rate,
                  ode_commercial,
                  ode_commercial_rate,
                  tier_end,
                  tier_start,
                  tier_usage,
                } = tier
                tableRowsTax.push([
                  // eslint-disable-next-line camelcase
                  identification,
                  description,
                  tags,
                  name,
                  mainType,
                  year,
                  tier_start,
                  tier_end,
                  tier_usage,
                  eb_commercial_rate,
                  eb_commercial,
                  null,
                  null,
                  ode_commercial_rate,
                  ode_commercial,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                ])
              } else if (mainType === 'Gas') {
                if (taxDiscount) {
                  const {
                    eb_reduced,
                    eb_reduced_rate,
                    ode_reduced,
                    ode_reduced_rate,
                    tier_end,
                    tier_start,
                    tier_usage,
                  } = tier
                  tableRowsTax.push([
                    // eslint-disable-next-line camelcase
                    identification,
                    description,
                    tags,
                    name,
                    mainType,
                    year,
                    tier_start,
                    tier_end,
                    tier_usage,
                    null,
                    null,
                    eb_reduced_rate,
                    eb_reduced,
                    null,
                    null,
                    ode_reduced_rate,
                    ode_reduced,
                    null,
                    null,
                    null,
                    null,
                    // identification, mainType, year, tier_start, tier_end, tier_usage, eb_rate, eb, eb_reduced_rate, eb_reduced, ode_rate, ode, ode_reduced, ode_reduced_rate, bolRate, bol, superTotal,
                  ])
                } else {
                  const { eb, eb_rate, ode, ode_rate, tier_end, tier_start, tier_usage } = tier
                  tableRowsTax.push([
                    // eslint-disable-next-line camelcase
                    identification,
                    description,
                    tags,
                    name,
                    mainType,
                    year,
                    tier_start,
                    tier_end,
                    tier_usage,
                    eb_rate,
                    eb,
                    null,
                    null,
                    ode_rate,
                    ode,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    // identification, mainType, year, tier_start, tier_end, tier_usage, eb_rate, eb, eb_reduced_rate, eb_reduced, ode_rate, ode, ode_reduced, ode_reduced_rate, bolRate, bol, superTotal,
                  ])
                }
              } else if (mainType === 'Water') {
                const { bol, bol_rate, tier_end, tier_start, tier_usage } = tier
                tableRowsTax.push([
                  // eslint-disable-next-line camelcase
                  identification,
                  description,
                  tags,
                  name,
                  mainType,
                  year,
                  tier_start,
                  tier_end,
                  tier_usage,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  bol_rate,
                  bol,
                  null,
                  null,
                  // identification, mainType, year, tier_start, tier_end, tier_usage, eb_rate, eb, eb_reduced_rate, eb_reduced, ode_rate, ode, ode_reduced, ode_reduced_rate, bolRate, bol, superTotal,
                ])
              }
            })
          })
          // subtotals
          let totalEbCommercial = null
          let totalOdeCommercail = null
          let totalEb = null
          let totalOde = null
          let totalEbReduced = null
          let totalOdeReduced = null
          let totalBol = null
          if (mainType === 'Elektriciteit') {
            totalEbCommercial = taxTotals.eb_commercial
            totalOdeCommercail = taxTotals.ode_commercial
            // push subtotal row
            tableRowsTax.push([
              // eslint-disable-next-line camelcase
              useTranslate.t('Subtotal'),
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              totalEbCommercial,
              null,
              null,
              null,
              totalOdeCommercail,
              null,
              null,
              null,
              null,
              taxCredit,
              superTotal,
            ])
          } else if (mainType === 'Gas') {
            if (taxDiscount) {
              totalEbReduced = taxTotals.eb_reduced
              totalOdeReduced = taxTotals.ode_reduced
            } else {
              totalEb = taxTotals.eb
              totalOde = taxTotals.ode
            }
            // push subtotal row
            tableRowsTax.push([
              // eslint-disable-next-line camelcase
              useTranslate.t('Subtotal'),
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              totalEb,
              null,
              totalEbReduced,
              null,
              totalOde,
              null,
              totalOdeReduced,
              null,
              null,
              taxCredit,
              superTotal,
            ])
          } else if (mainType === 'Water') {
            totalBol = taxTotals.bol
            // push subtotal row
            tableRowsTax.push([
              // eslint-disable-next-line camelcase
              useTranslate.t('Subtotal'),
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              totalBol,
              taxCredit,
              superTotal,
            ])
          }
          boldRowIndexes.push(tableRowsTax.length)
          // push empty row
          tableRowsTax.push([
            // eslint-disable-next-line camelcase
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
          ])
        })
      }
    })

    console.log(tableRowsTax)

    if (tableRowsTax.length === 0) {
      tableRowsTax.push([
        useTranslate.t('Geen data'),
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ])
    }

    // Write table
    worksheetTax.addTable({
      name: 'belasting',
      ref: tableAnchor,
      headerRow: true,
      totalsRow: true,
      style: {
        theme: 'TableStyleLight1',
        showRowStripes: true,
      },
      columns: tableColumnsTax,
      rows: tableRowsTax,
    })

    // loop tablecolumns
    tableColumnsTax.forEach((element, index) => {
      const dingen = worksheetTax.getColumn(index + 2)
      if (index !== 0) {
        dingen.alignment = { vertical: 'middle', horizontal: 'center' }
      }
      if (element.type === 'currency') {
        dingen.numFmt = '€ #,##0.00;[Color10]-€ #,##0.00'
      } else if (element.type === 'rate') {
        dingen.numFmt = '€ #,##0.00############;[Color10]-€ #,##0.00############'
      } else if (element.type === 'usage') {
        dingen.numFmt = '#,#0;[Color10]-#,#0'
      }
    })
    // Loop tablerows
    boldRowIndexes.forEach((rowIndexTable) => {
      const rowIndexSheet = tableAnchorRow + rowIndexTable
      worksheetTax.getRow(rowIndexSheet).font = { bold: true }
    })
    // Loop sheetcolumns
    worksheetTax.columns.forEach((column) => {
      const columnNumber = column.number

      if (columnNumber !== 4) {
        let dataMax = 0
        column.eachCell((cell) => {
          const width = cell.value ? cell.value.toString().length : 0
          if (width > dataMax) {
            dataMax = width
          }
        })
        if (dataMax) {
          // eslint-disable-next-line no-param-reassign
          column.width = dataMax
        } else {
          // eslint-disable-next-line no-param-reassign
          column.width = 10
        }
      }
    })

    // fill summary page
    worksheetSummary.getCell('B2').value = `${useTranslate.t('Summary')}`
    worksheetSummary.getCell('B2').font = { size: 16 }
    // buy
    worksheetSummary.getCell('B4').value = useTranslate.t('Buy-in')
    worksheetSummary.getCell('B4').font = { bold: true }
    worksheetSummary.getCell('B5').value = useTranslate.t('Electricity')
    worksheetSummary.getCell('C5').value = valueOrDash(costsData.contracts_total, 'Electricity')

    worksheetSummary.getCell('B6').value = useTranslate.t('Gas')
    worksheetSummary.getCell('C6').value = valueOrDash(costsData.contracts_total, 'Gas')

    worksheetSummary.getCell('B7').value = useTranslate.t('Water')
    worksheetSummary.getCell('C7').value = valueOrDash(costsData.contracts_total, 'Water')

    worksheetSummary.getCell('B8').value = useTranslate.t('Heat')
    worksheetSummary.getCell('C8').value = valueOrDash(costsData.contracts_total, 'Heat')

    worksheetSummary.getCell('B9').value = useTranslate.t('HeatCost')
    worksheetSummary.getCell('C9').value = valueOrDash(costsData.contracts_total, 'HeatCost')

    worksheetSummary.getCell('B10').value = useTranslate.t('Subtotal')
    worksheetSummary.getCell('C10').value = valueOrDash(costsData.contracts_total, 'total')

    worksheetSummary.getCell('B12').value = useTranslate.t('Taxes')
    worksheetSummary.getCell('B12').font = { bold: true }

    // Get tax totals, using default values if taxData is empty
    const bami = taxData?.totals?.total || getDefaultTaxTotals()

    // Tax credit section
    worksheetSummary.getCell('B13').value = useTranslate.t('Tax credit')
    if (bami.tax_credit_total !== '-') {
      worksheetSummary.getCell('C13').value = -Math.abs(bami.tax_credit_total)
    } else {
      worksheetSummary.getCell('C13').value = '-'
    }

    // Electricity section
    worksheetSummary.getCell('B14').value = useTranslate.t('Electricity')
    if ('tax_credit_total' in bami && bami.tax_credit_total !== '-') {
      worksheetSummary.getCell('C14').value = valueOrDash(taxData.totals.total, 'tax_total_electricity_pre_tax_credit')
    } else {
      worksheetSummary.getCell('C14').value = valueOrDash(bami, 'tax_total_electricity')
    }

    // Gas, Water, Heat, and HeatCost sections
    worksheetSummary.getCell('B15').value = useTranslate.t('Gas')
    worksheetSummary.getCell('C15').value = valueOrDash(bami, 'tax_total_gas')

    worksheetSummary.getCell('B16').value = useTranslate.t('Water')
    worksheetSummary.getCell('C16').value = valueOrDash(bami, 'tax_total_water')

    worksheetSummary.getCell('B17').value = useTranslate.t('Heat')
    worksheetSummary.getCell('C17').value = valueOrDash(bami, 'tax_total_heat')

    worksheetSummary.getCell('B18').value = useTranslate.t('HeatCost')
    worksheetSummary.getCell('C18').value = valueOrDash(bami, 'tax_total_heatcost')

    worksheetSummary.getCell('B19').value = useTranslate.t('Subtotal')
    worksheetSummary.getCell('C19').value = valueOrDash(bami, 'tax_total_total')

    // VAT section
    worksheetSummary.getCell('B21').value = useTranslate.t('VAT')
    worksheetSummary.getCell('B21').font = { bold: true }

    // Buy-in VAT - show percentages only when there's a value
    worksheetSummary.getCell('B22').value = `${useTranslate.t('Buy-in')} ${useTranslate.t('Electricity')}${
      costsData.contracts_total.Electricity_vat && costsData.contracts_total.Electricity_vat !== '-'
        ? ` (${valueOrDashRate(costsData.contracts_total, 'Electricity_vat_rate')}%)`
        : ''
    }`
    worksheetSummary.getCell('C22').value = valueOrDash(costsData.contracts_total, 'Electricity_vat')

    worksheetSummary.getCell('B23').value = `${useTranslate.t('Buy-in')} ${useTranslate.t('Gas')}${
      costsData.contracts_total.Gas_vat && costsData.contracts_total.Gas_vat !== '-'
        ? ` (${valueOrDashRate(costsData.contracts_total, 'Gas_vat_rate')}%)`
        : ''
    }`
    worksheetSummary.getCell('C23').value = valueOrDash(costsData.contracts_total, 'Gas_vat')

    worksheetSummary.getCell('B24').value = `${useTranslate.t('Buy-in')} ${useTranslate.t('Water')}${
      costsData.contracts_total.Water_vat && costsData.contracts_total.Water_vat !== '-'
        ? ` (${valueOrDashRate(costsData.contracts_total, 'Water_vat_rate')}%)`
        : ''
    }`
    worksheetSummary.getCell('C24').value = valueOrDash(costsData.contracts_total, 'Water_vat')

    worksheetSummary.getCell('B25').value = `${useTranslate.t('Buy-in')} ${useTranslate.t('Heat')}${
      costsData.contracts_total.Heat_vat && costsData.contracts_total.Heat_vat !== '-'
        ? ` (${valueOrDashRate(costsData.contracts_total, 'Heat_vat_rate')}%)`
        : ''
    }`
    worksheetSummary.getCell('C25').value = valueOrDash(costsData.contracts_total, 'Heat_vat')

    worksheetSummary.getCell('B26').value = `${useTranslate.t('Buy-in')} ${useTranslate.t('HeatCost')}${
      costsData.contracts_total.HeatCost_vat && costsData.contracts_total.HeatCost_vat !== '-'
        ? ` (${valueOrDashRate(costsData.contracts_total, 'HeatCost_vat_rate')}%)`
        : ''
    }`
    worksheetSummary.getCell('C26').value = valueOrDash(costsData.contracts_total, 'HeatCost_vat')

    // Tax VAT
    worksheetSummary.getCell('B27').value = `${useTranslate.t('Taxes')} ${useTranslate.t('Electricity')}${
      bami.tax_total_vat_electricity_rate !== '-'
        ? ` (${valueOrDashRate(bami, 'tax_total_vat_electricity_rate')}%)`
        : ''
    }`
    worksheetSummary.getCell('C27').value = valueOrDash(bami, 'tax_total_vat_electricity')

    worksheetSummary.getCell('B28').value = `${useTranslate.t('Taxes')} ${useTranslate.t('Gas')}${
      bami.tax_total_vat_gas_rate !== '-' ? ` (${valueOrDashRate(bami, 'tax_total_vat_gas_rate')}%)` : ''
    }`
    worksheetSummary.getCell('C28').value = valueOrDash(bami, 'tax_total_vat_gas')

    worksheetSummary.getCell('B29').value = `${useTranslate.t('Taxes')} ${useTranslate.t('Water')}${
      bami.tax_total_vat_water_rate !== '-' ? ` (${valueOrDashRate(bami, 'tax_total_vat_water_rate')}%)` : ''
    }`
    worksheetSummary.getCell('C29').value = valueOrDash(bami, 'tax_total_vat_water')

    worksheetSummary.getCell('B30').value = `${useTranslate.t('Taxes')} ${useTranslate.t('Heat')}${
      bami.tax_total_vat_heat_rate !== '-' ? ` (${valueOrDashRate(bami, 'tax_total_vat_heat_rate')}%)` : ''
    }`
    worksheetSummary.getCell('C30').value = valueOrDash(bami, 'tax_total_vat_heat')

    worksheetSummary.getCell('B31').value = `${useTranslate.t('Taxes')} ${useTranslate.t('HeatCost')}${
      bami.tax_total_vat_heatcost_rate !== '-' ? ` (${valueOrDashRate(bami, 'tax_total_vat_heatcost_rate')}%)` : ''
    }`
    worksheetSummary.getCell('C31').value = valueOrDash(bami, 'tax_total_vat_heatcost')

    worksheetSummary.getCell('B32').value = useTranslate.t('Subtotal')
    // Calculate VAT total, using 0 for tax VAT if not available
    const taxVAT = bami.tax_total_total_vat === '-' ? 0 : bami.tax_total_total_vat
    worksheetSummary.getCell('C32').value = costsData.contracts_total.total_vat + taxVAT

    // Totals section
    worksheetSummary.getCell('B34').value = useTranslate.t('Total')
    worksheetSummary.getCell('B34').font = { bold: true }

    const totalTotal = costsData.contracts_total.total + (bami.tax_total_total === '-' ? 0 : bami.tax_total_total)
    const totalVAT = costsData.contracts_total.total_vat + taxVAT

    worksheetSummary.getCell('B35').value = useTranslate.t('VAT excluded')
    worksheetSummary.getCell('C35').value = totalTotal
    worksheetSummary.getCell('B36').value = useTranslate.t('VAT included')
    worksheetSummary.getCell('B36').font = { bold: true }
    worksheetSummary.getCell('C36').value = totalTotal + totalVAT

    // formatting
    const costColumn = worksheetSummary.getColumn(3)
    costColumn.numFmt = '€ #,##0.00;[Color10]-€ #,##0.00'
    costColumn.alignment = { vertical: 'middle', horizontal: 'center' }
    // Loop sheetcolumns
    worksheetSummary.columns.forEach((column) => {
      let dataMax = 0
      column.eachCell((cell) => {
        const width = cell.value ? cell.value.toString().length : 0
        if (width > dataMax) {
          dataMax = width
        }
      })
      if (dataMax) {
        // eslint-disable-next-line no-param-reassign
        column.width = dataMax
      } else {
        // eslint-disable-next-line no-param-reassign
        column.width = 10
      }
    })

    // Write workbook
    workbook.xlsx
      .writeBuffer({
        base64: true,
      })
      .then((xls64) => {
        // build anchor tag and attach file (works in chrome)
        const a = document.createElement('a')
        const data = new Blob([xls64], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const url = URL.createObjectURL(data)
        a.href = url
        a.download = options.filename
        document.body.appendChild(a)
        a.click()
        setTimeout(() => {
          document.body.removeChild(a)
          window.URL.revokeObjectURL(url)
        }, 0)
      })
      .catch(() => {})
  }

  return {
    exporter,
  }
}
