<template>
  <base-tooltip :title="$translate('Tiers')" size="l">
    <div v-for="(tax_credit_years, year) in data.tax_totals.tax_credit_years" :key="year">
      <base-table
        :fields="fields"
        :records="[tax_credit_years]"
        :records-per-page="25"
        :loading="false"
        :hover-effect="false"
        :search="false"
        :pagination="false"
      />
    </div>
  </base-tooltip>
</template>

<script>
// import featherIcon from '@/components/extended/feather/featherIcon.vue'
// import { computed } from 'vue'
import filterHandler from '@/use/filterHandler'

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: 'Electricity',
    },
  },
  setup() {
    const useFilter = filterHandler()

    const fields = [
      {
        key: 'year',
        type: 'string',
        label: 'Year',
      },
      {
        key: 'start_date',
        type: 'date',
        filter: 'dateFromEpochDay',
        label: 'Start date',
      },
      {
        key: 'end_date',
        type: 'date',
        filter: 'dateFromEpochDay',
        label: 'End date',
      },
      {
        key: 'days',
        type: 'string',
        label: 'Days',
      },
      {
        key: 'tax_credit',
        type: 'number',
        filter: 'roundDynamicCurrency',
        label: 'Tax credit',
      },
      {
        key: 'tax_credit_total',
        type: 'number',
        filter: 'roundDynamicCurrency',
        label: 'Total',
      },
    ]

    return {
      useFilter,
      fields,
    }
  },
  components: {
    // featherIcon,
  },
}
</script>
