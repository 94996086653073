import { useAuth0 } from '@auth0/auth0-vue'
import axios from 'axios'
import useToast from '@/use/toastHandler'

let interceptorsSet = false

export default () => {
  const { getAccessTokenSilently, logout } = useAuth0()
  const toasthandler = useToast()
  const baseUrlV2 = process.env.VUE_APP_API_V2_BASEURL

  // Add interceptors only once
  if (!interceptorsSet) {
    // request interceptor
    axios.interceptors.request.use(async (config) => {
      const accessToken = await getAccessTokenSilently()
      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`
      }
      return config
    })

    // response interceptor
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response) {
          if (error.response.status === 401) {
            console.error('Access token not found or unauthorized')
            logout({ logoutParams: { returnTo: window.location.origin } })
          } else if (error.response.status === 409) {
            toasthandler.addToast('Object heeft afhankelijkheden', 'danger')
          } else if (error.response.status === 400) {
            toasthandler.addToast(`Error: ${error.response.data.detail}`, 'danger')
          } else {
            toasthandler.addToast('Server error', 'danger')
          }
        } else {
          toasthandler.addToast('Network or server error', 'danger')
        }
        return Promise.reject(error)
      },
    )

    interceptorsSet = true
  }

  async function request_api(type, object, endpoint, payload, headers) {
    const url = `${baseUrlV2}/${object}/${endpoint}`
    const config = { headers }
    let response

    switch (type) {
      case 'get':
        response = await axios.get(url, config)
        break
      case 'post':
        response = await axios.post(url, payload, config)
        break
      case 'patch':
        response = await axios.patch(url, payload, config)
        break
      case 'delete':
        response = await axios.delete(url, config)
        break
      default:
        throw new Error(`Unsupported request type: ${type}`)
    }

    return response
  }

  function toQueryString(params) {
    const queryString = new URLSearchParams()
    Object.keys(params).forEach((key) => {
      const value = params[key]
      if (Array.isArray(value)) {
        value.forEach((val) => queryString.append(key, val))
      } else {
        queryString.append(key, value)
      }
    })
    return queryString.toString()
  }

  function arrayToCommaSeparatedString(array) {
    if (!Array.isArray(array)) {
      throw new Error('Input must be an array')
    }
    return array.join(',')
  }

  return {
    request_api,
    toQueryString,
    arrayToCommaSeparatedString,
  }
}
